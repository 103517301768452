import { ObjectSchema, array, date, number, object, ref, string } from 'yup';
import { MESSAGES } from '../constants/validationMessages';
import EditTournamentFormValue from './../models/forms/editTournamentFormValue';

const EditTournamentValidationSchema: ObjectSchema<EditTournamentFormValue> =
  object({
    edition: string().trim().required(MESSAGES.REQUIRED.TOURNAMENT_EDITION),
    startDate: date().required(MESSAGES.REQUIRED.TOURNAMENT_START_DATE),
    endDate: date()
      .required(MESSAGES.REQUIRED.TOURNAMENT_END_DATE)
      .min(ref('startDate'), MESSAGES.VALID.TOURNAMENT_END_DATE),
    tournamentAdmins: array()
      .required(MESSAGES.VALID.TOURNAMENT_ADMINS)
      .min(1, MESSAGES.VALID.TOURNAMENT_ADMINS),
    description: string().trim().required(MESSAGES.REQUIRED.DESCRIPTION),
    tournamentFormatId: number()
      .required(MESSAGES.REQUIRED.TOURNAMENT_FORMAT)
      .moreThan(0, MESSAGES.REQUIRED.TOURNAMENT_FORMAT),
    standardGameDurationId: number()
      .required(MESSAGES.REQUIRED.STANDARD_GAME_DURATION)
      .moreThan(0, MESSAGES.REQUIRED.STANDARD_GAME_DURATION),
    linkToResults: string()
      .trim()
      .url(MESSAGES.VALID.LINK_TO_RESULTS)
      .optional()
  });

export default EditTournamentValidationSchema;
