const FORM_HELPER_MESSAGES = {
    CITY: `We use your location to calculate your time zone and
        in divisions algorythm. If your location is not in
        the list or you don't want to share it, just pick
        the nearest location in the same country and time
        zone`,
    CITY_NOTE: `We predefined a list of options based on your IP coordinates.
    If you are using VPN or not at home, try to type and search for your location.
    Our app doesn't know every city in the world - try to pick a nearest large city, if your city can not be found`,
    REGIONAL_FEDERATION: 'If you are located in the USA, you can play in states competitions',
    PHONE_NUMBER: 'Please leave your phone number if you want to join our WhatsApp group'
}

export default FORM_HELPER_MESSAGES;