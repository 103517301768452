import httpClient from './httpClient';

export async function processPlayerForfeit(
  tournamentId: number,
  playerId: number,
  replacementPlayerId?: number
) {
  const url = `/tournament/${tournamentId}/participants/${playerId}/forfeit`;

  await httpClient.post(url, { replacementPlayerId });
}

export async function replaceForfeitedPlayer(
  tournamentId: number,
  forfeitedPlayerId: number,
  replacementPlayerId: number
) {
  const url = `/tournament/${tournamentId}/participants/${forfeitedPlayerId}/forfeit`;

  await httpClient.put(url, { replacementPlayerId });
}
