import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import CompetitionsStatsTab from '../components/statsTabs/CompetitionsStatsTab';
import GeneralStatsTab from '../components/statsTabs/GeneralStatsTab';
import USAStatsTab from '../components/statsTabs/USAStatsTab';
import USSRStatsTab from '../components/statsTabs/USSRStatsTab';

const StatisticsView = () => {
  return (
    <>
      <Helmet>
        <title>Statistics</title>
      </Helmet>
      <Tabs
        isLazy
        borderWidth={1}
        borderRadius={8}
        boxShadow='lg'
        m='.5rem'
        p='0 0.5rem'
      >
        <TabList>
          <Tab>General</Tab>
          <Tab>Competitions</Tab>
          <Tab>USA</Tab>
          <Tab>USSR</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <GeneralStatsTab />
          </TabPanel>
          <TabPanel>
            <CompetitionsStatsTab />
          </TabPanel>
          <TabPanel>
            <USAStatsTab />
          </TabPanel>
          <TabPanel>
            <USSRStatsTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default StatisticsView;
