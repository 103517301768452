import { array, date, object, ObjectSchema, string } from 'yup';
import CreatePollFormValue, {
  PollQuestionOption
} from '../models/forms/createPollFormValue';
import { MESSAGES } from '../constants/validationMessages';

const optionSchema: ObjectSchema<PollQuestionOption> = object({
  value: string().required(MESSAGES.REQUIRED.OPTION)
});

export const CreatePollFormValueValidationSchema: ObjectSchema<CreatePollFormValue> =
  object({
    description: string().optional(),
    dueDate: date().required(MESSAGES.REQUIRED.DATE).min(new Date()),
    question: string().required(MESSAGES.REQUIRED.QUESTION),
    options: array().required().min(2).of(optionSchema)
  });
