import PollStatus from '../enums/pollStatus';
import CreatePoll from '../models/polls/createPoll';
import Poll from '../models/polls/poll';
import httpClient from './httpClient';

export async function createPoll(poll: CreatePoll) {
  const url = '/polls';

  await httpClient.post(url, poll);
}

export async function getPolls(status: PollStatus = PollStatus.Active) {
  const url = '/polls';

  const response = await httpClient.get<Poll[]>(url, { params: { status } });

  return response.data;
}

export async function submitVote(pollId: string, optionId: string) {
  const url = `/polls/${pollId}/votes`;

  await httpClient.post(url, { optionId });
}
