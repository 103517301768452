import {
  Link as ChakraLink,
  Flex,
  Grid,
  Text,
  GridItem
} from '@chakra-ui/react';
import TournamentInfo from '../../models/tournament/tournamentInfo';
import ComponentWithHeading from '../ComponentWithHeading';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { replaceUrlParams } from '../../utils/routerUtils';
import Medal from '../Medal';
import TournamentMedals from './TournamentMedals';

interface TournamentInfoGridProps {
  tournamentInfo: TournamentInfo | null;
}

const TournamentInfoGrid = ({ tournamentInfo }: TournamentInfoGridProps) => {
  return (
    tournamentInfo && (
      <>
        <Grid templateColumns={'repeat(3, 1fr)'} gap={2} width={{ sm: '50%' }}>
          <TournamentMedals
            tournamentWinners={tournamentInfo.tournamentWinners}
          />
        </Grid>
        <Grid templateRows='repeat(2, min-content)' gap={2}>
          <GridItem colSpan={2}>
            <ComponentWithHeading heading='Description'>
              <Text
                mt='-0.3rem'
                fontSize='0.8rem'
                whiteSpace='pre-line'
                lineHeight='4'
              >
                {tournamentInfo.description}
              </Text>
            </ComponentWithHeading>
          </GridItem>
          <ComponentWithHeading heading='Tournament type'>
            <Text mt='-0.3rem' fontSize='0.8rem'>
              {tournamentInfo.name}
            </Text>
          </ComponentWithHeading>
          <ComponentWithHeading heading='Edition'>
            <Text mt='-0.3rem' fontSize='0.8rem'>
              {tournamentInfo.edition}
            </Text>
          </ComponentWithHeading>
          <ComponentWithHeading heading='Start date'>
            <Text mt='-0.3rem' fontSize='0.8rem'>
              {new Date(tournamentInfo.startDate).toLocaleDateString()}
            </Text>
          </ComponentWithHeading>
          <ComponentWithHeading heading='End date'>
            <Text mt='-0.3rem' fontSize='0.8rem'>
              {new Date(tournamentInfo.endDate).toLocaleDateString()}
            </Text>
          </ComponentWithHeading>
          <ComponentWithHeading heading='Tournament format'>
            <Text mt='-0.3rem' fontSize='0.8rem'>
              {tournamentInfo.tournamentFormatName}
            </Text>
          </ComponentWithHeading>
          <ComponentWithHeading heading='Standard game duration'>
            <Text mt='-0.3rem' fontSize='0.8rem'>
              {tournamentInfo.standardGameDurationName}
            </Text>
          </ComponentWithHeading>
          <ComponentWithHeading heading='Registration'>
            <Text mt='-0.3rem' fontSize='0.8rem'>
              {tournamentInfo.registration}
            </Text>
          </ComponentWithHeading>
          <ComponentWithHeading heading='Status'>
            <Text mt='-0.3rem' fontSize='0.8rem'>
              {tournamentInfo.status}
            </Text>
          </ComponentWithHeading>
          <ComponentWithHeading heading='Link to results'>
            <ChakraLink
              mt='-0.3rem'
              href={tournamentInfo.linkToResults}
              isExternal
            >
              <ExternalLinkIcon ml='2px' mb='2px' />
            </ChakraLink>
          </ComponentWithHeading>

          <ComponentWithHeading heading='Admins'>
            <Flex flexDir='column'>
              {tournamentInfo.admins.map(a => (
                <ChakraLink
                  as={Link}
                  to={replaceUrlParams(ROUTES.PLAYER_PROFILE, { id: a.id })}
                  key={a.id}
                  mt='-0.3rem'
                  fontSize='0.8rem'
                >
                  {`${a.firstName} ${a.lastName}`}
                </ChakraLink>
              ))}
            </Flex>
          </ComponentWithHeading>
        </Grid>
      </>
    )
  );
};

export default TournamentInfoGrid;
