import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  RadioGroup,
  Stack,
  Radio,
  Skeleton
} from '@chakra-ui/react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import SelectOption from '../models/utils/selectOption';
import { EditGameValidationSchema as schema } from '../validation/editGameValidationSchema';
import Power from '../enums/power';
import DateInput from '../components/dateInput/DateInput';
import { formatDateString } from '../utils/dateUtils';
import EditGameFormValue from '../models/forms/submitEditGameFormValue';
import styleConstants from '../constants/styleConstants';
import PowerSelectionMethod from '../enums/powerSelectionMethod';
import { getGame, editGame } from '../services/gameService';
import useFullPageLoader from '../hooks/useFullPageLoader';
import SelectInput from '../components/selectInput/SelectInput';
import useErrorMessage from '../hooks/useErrorMessage';
import EditGame from '../models/game/editGame';
import { getTournamentParticipants } from '../services/lookupService';
import TournamentParticipantStatus from '../enums/tournamentParticipantStatus';
import { sendRequestWithToast } from '../utils/requestUtils';

const EditGameForm = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [errorMessage, setErrorMessage, clearErrorMessage] = useErrorMessage();
  const [playersSelectOptions, setPlayersSelectOptions] = useState<
    SelectOption[]
  >([]);
  const [loaded, setLoaded] = useState(false);
  const { tournamentId, id } = useParams();

  const {
    watch,
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
    control
  } = useForm<EditGameFormValue>({
    defaultValues: {
      powerSelectionMethod: PowerSelectionMethod.Fixed,
      firstPlayerPower: Power.USA,
      secondPlayerPower: Power.USSR
    },
    resolver: yupResolver(schema),
    mode: 'onTouched',
    shouldFocusError: false
  });

  useEffect(() => {
    const fetch = async () => {
      const game = await getGame(+id!);
      const players = await getTournamentParticipants(+tournamentId!, [
        TournamentParticipantStatus.Active
      ]);
      setPlayersSelectOptions(players);
      reset({ ...game, dueDate: new Date(game.dueDate) });
      setLoaded(true);
    };

    fetch();
  }, [id]);

  const onSubmit: SubmitHandler<EditGameFormValue> = async props => {
    clearErrorMessage();
    showLoader();
    try {
      const request: EditGame = {
        ...props,
        dueDate: formatDateString(props.dueDate)
      };

      if (id) {
        await sendRequestWithToast(
          editGame(+id, request),
          'Game has been successfully updated'
        );
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    }

    hideLoader();
  };

  return (
    <>
      <Helmet>
        <title>Edit Game</title>
      </Helmet>
      <Container
        maxW={'container.md'}
        my={{ base: styleConstants.viewMobileMY, sm: styleConstants.viewMY }}
        paddingX={{ base: 2, sm: 6 }}
        paddingY={{ base: 0, sm: 6 }}
        borderWidth={{ base: 0, sm: 1 }}
        borderRadius={8}
        boxShadow={{ base: 'none', sm: 'lg' }}
      >
        <Heading mb={4} size='lg' textAlign='center'>
          Edit game
        </Heading>
        <form
          onSubmit={handleSubmit(onSubmit, err => console.log(err, 'errors'))}
          noValidate
        >
          <Flex gap={6} flexDirection='column'>
            <FormControl isInvalid={!!errors.dueDate} isRequired>
              <FormLabel>Due date</FormLabel>
              <Skeleton isLoaded={loaded}>
                <Controller
                  control={control}
                  name='dueDate'
                  render={({ field: { onChange, value, onBlur } }) => (
                    <DateInput
                      value={value}
                      isInvalid={!!errors.dueDate}
                      minDate={new Date()}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </Skeleton>
              <FormErrorMessage>{errors['dueDate']?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.identifier}>
              <FormLabel>Identifier</FormLabel>
              <Skeleton isLoaded={loaded}>
                <Input
                  {...register('identifier')}
                  placeholder='Game identifier'
                  type='text'
                />
              </Skeleton>
              <FormErrorMessage>{errors.identifier?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.powerSelectionMethod}>
              <FormLabel>Power selection method</FormLabel>
              <Skeleton isLoaded={loaded}>
                <Controller
                  control={control}
                  name='powerSelectionMethod'
                  render={({ field: { onChange, name, value } }) => (
                    <RadioGroup
                      name={name}
                      onChange={val => {
                        onChange(val);
                        if (val !== PowerSelectionMethod.Fixed) {
                          setValue('firstPlayerPower', undefined);
                          setValue('secondPlayerPower', undefined);
                        } else {
                          setValue('firstPlayerPower', Power.USA);
                          setValue('secondPlayerPower', Power.USSR);
                        }
                      }}
                      value={value}
                    >
                      <Stack spacing={5} direction='row'>
                        <Radio
                          name={name}
                          colorScheme='gray'
                          value={PowerSelectionMethod.Fixed}
                        >
                          Fixed
                        </Radio>
                        <Radio
                          name={name}
                          colorScheme='gray'
                          value={PowerSelectionMethod.Random}
                        >
                          Random
                        </Radio>
                        <Radio
                          name={name}
                          colorScheme='gray'
                          value={PowerSelectionMethod.HigherSeed}
                        >
                          Higher Seed
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </Skeleton>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.firstPlayerId}>
              <FormLabel>First player:</FormLabel>
              <Controller
                control={control}
                name='firstPlayerId'
                render={({ field: { onChange, value } }) => (
                  <Skeleton isLoaded={loaded}>
                    <SelectInput
                      value={value}
                      onChange={onChange}
                      options={playersSelectOptions}
                      isInvalid={!!errors['firstPlayerId']}
                      isSearchable
                      placeholder='First player (type here to search)'
                    />
                  </Skeleton>
                )}
              />
              <FormErrorMessage>
                {errors.firstPlayerId?.message}
              </FormErrorMessage>
            </FormControl>

            {watch('powerSelectionMethod') === PowerSelectionMethod.Fixed && (
              <FormControl isRequired isInvalid={!!errors.firstPlayerPower}>
                <FormLabel>First player power</FormLabel>
                <Skeleton isLoaded={loaded}>
                  <Controller
                    control={control}
                    name='firstPlayerPower'
                    render={({ field: { onChange, name, value } }) => (
                      <RadioGroup
                        name={name}
                        onChange={val => {
                          onChange(val);
                          setValue(
                            'secondPlayerPower',
                            val === Power.USA ? Power.USSR : Power.USA
                          );
                        }}
                        value={value}
                      >
                        <Stack spacing={5} direction='row'>
                          <Radio
                            name={name}
                            colorScheme='blue'
                            value={Power.USA}
                          >
                            USA
                          </Radio>
                          <Radio
                            name={name}
                            colorScheme='red'
                            value={Power.USSR}
                          >
                            USSR
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                </Skeleton>
                <FormErrorMessage>
                  {errors.firstPlayerPower?.message}
                </FormErrorMessage>
              </FormControl>
            )}
            <FormControl isRequired isInvalid={!!errors.secondPlayerId}>
              <FormLabel>Second player:</FormLabel>
              <Skeleton isLoaded={loaded}>
                <Controller
                  control={control}
                  name='secondPlayerId'
                  render={({ field: { onChange, value } }) => (
                    <SelectInput
                      value={value}
                      onChange={onChange}
                      options={playersSelectOptions}
                      isInvalid={!!errors.secondPlayerId}
                      isSearchable
                      placeholder='Second player (type here to search)'
                    />
                  )}
                />
              </Skeleton>
              <FormErrorMessage>
                {errors.secondPlayerId?.message}
              </FormErrorMessage>
            </FormControl>
            {watch('powerSelectionMethod') === PowerSelectionMethod.Fixed && (
              <FormControl isRequired isInvalid={!!errors.secondPlayerPower}>
                <FormLabel>Second player power</FormLabel>
                <Skeleton isLoaded={loaded}>
                  <Controller
                    control={control}
                    name='secondPlayerPower'
                    render={({ field: { onChange, name, value } }) => (
                      <RadioGroup
                        onChange={val => {
                          onChange(val);
                          setValue(
                            'firstPlayerPower',
                            val === Power.USA ? Power.USSR : Power.USA
                          );
                        }}
                        value={value}
                      >
                        <Stack spacing={5} direction='row'>
                          <Radio
                            name={name}
                            colorScheme='blue'
                            value={Power.USA}
                          >
                            USA
                          </Radio>
                          <Radio
                            name={name}
                            colorScheme='red'
                            value={Power.USSR}
                          >
                            USSR
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                </Skeleton>
                <FormErrorMessage>
                  {errors.firstPlayerPower?.message}
                </FormErrorMessage>
              </FormControl>
            )}
          </Flex>
          {errorMessage}
          <Flex justifyContent='center'>
            <Button
              colorScheme='teal'
              variant='outline'
              width='36'
              textAlign={'center'}
              mt={6}
              type='submit'
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Container>
      <>{loader}</>
    </>
  );
};

export default EditGameForm;
