import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Textarea,
  useToast
} from '@chakra-ui/react';
import useErrorMessage from '../hooks/useErrorMessage';
import useFullPageLoader from '../hooks/useFullPageLoader';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { Helmet } from 'react-helmet';
import styleConstants from '../constants/styleConstants';
import CreatePollFormValue from '../models/forms/createPollFormValue';
import DateInput from '../components/dateInput/DateInput';
import { AddIcon } from '@chakra-ui/icons';
import { CreatePollFormValueValidationSchema } from '../validation/createPollFormValueValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { createPoll } from '../services/pollService';
import { formatDateString } from '../utils/dateUtils';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants';

const CreatePollForm = () => {
  const [errorMessage, setErrorMessage, clearErrorMessage] = useErrorMessage();
  const toast = useToast();

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<CreatePollFormValue>({
    resolver: yupResolver(CreatePollFormValueValidationSchema),
    mode: 'onTouched',
    shouldFocusError: false,
    defaultValues: {
      options: [{ value: '' }, { value: '' }]
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
    rules: { minLength: 2 }
  });

  const onSubmit: SubmitHandler<CreatePollFormValue> = async data => {
    showLoader();

    clearErrorMessage();

    try {
      await createPoll({
        ...data,
        dueDate: formatDateString(data.dueDate),
        options: data.options.map(o => o.value)
      });

      toast({
        title: 'Changes saved',
        description: 'New poll has been succesfully created',
        status: 'success'
      });

      hideLoader();
      navigate(ROUTES.HOME);
    } catch (error: any) {
      hideLoader();
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Create tournament</title>
      </Helmet>
      <Container
        maxW='container.md'
        my={styleConstants.viewMY}
        p={12}
        borderWidth={1}
        borderRadius={8}
        boxShadow='lg'
      >
        <Flex flexDir='column' justifyContent='center' gap={2}>
          <Heading size='lg' textAlign='center'>
            Create a new poll
          </Heading>

          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Flex flexDir='column' gap={3}>
              <FormControl isInvalid={!!errors?.description}>
                <FormLabel>Description:</FormLabel>
                <Textarea
                  placeholder='Enter description'
                  {...register('description')}
                />
                <FormErrorMessage>
                  {errors?.description?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.dueDate} isRequired>
                <FormLabel>Due date</FormLabel>
                <Controller
                  control={control}
                  name='dueDate'
                  render={({ field: { onChange, value, onBlur } }) => (
                    <DateInput
                      value={value}
                      isInvalid={!!errors.dueDate}
                      minDate={new Date()}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
                <FormErrorMessage>{errors?.dueDate?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors?.question}>
                <FormLabel>Question:</FormLabel>
                <Textarea
                  placeholder='Enter question'
                  {...register('question')}
                />
                <FormErrorMessage>{errors?.question?.message}</FormErrorMessage>
              </FormControl>
              {fields.map((field, index) => (
                <FormControl
                  key={field.id}
                  isRequired={index < 2}
                  isInvalid={!!errors?.options?.[index]}
                >
                  <Flex justifyContent='space-between'>
                    <FormLabel>Option {index + 1}:</FormLabel>
                    {index > 1 && (
                      <Button
                        size='xs'
                        variant='ghost'
                        onClick={() => remove(index)}
                      >
                        Remove
                      </Button>
                    )}
                  </Flex>
                  <Input
                    placeholder='Enter option'
                    {...register(`options.${index}.value` as const)}
                  />
                  <FormErrorMessage>
                    {errors?.options?.[index]?.value?.message}
                  </FormErrorMessage>
                </FormControl>
              ))}
              <Button
                variant='outline'
                leftIcon={<AddIcon />}
                width='max-content'
                onClick={() => append({ value: '' })}
              >
                Add option
              </Button>
              {errorMessage}
              <Box textAlign={'center'}>
                <Button
                  colorScheme='teal'
                  variant='outline'
                  width='36'
                  textAlign='center'
                  type='submit'
                >
                  Submit
                </Button>
              </Box>
            </Flex>
          </form>
        </Flex>
      </Container>
      <>{loader}</>
    </>
  );
};

export default CreatePollForm;
