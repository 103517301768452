import {
  Box,
  Container,
  Flex,
  Text,
  Badge,
  OrderedList,
  ListItem,
  Link
} from '@chakra-ui/react';

import { Helmet } from 'react-helmet';
import styleConstants from '../constants/styleConstants';
import PollsList from '../components/poll/PollsList';

const MainView = () => {
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Container maxW={'container.lg'} my={styleConstants.viewMY}>
        <Flex flexDir='column' gap='1rem'>
          <PollsList />
          <Flex
            width={'full'}
            align={'center'}
            justify={'center'}
            direction={'column'}
            borderWidth={{ base: 0, md: 1 }}
            borderRadius={8}
            boxShadow={{ base: 'none', md: 'lg' }}
            p='1rem'
            gap='1rem'
          >
            <Box>
              <Text textAlign='center'>
                <Badge fontSize='md' colorScheme='orange'>
                  Hall of fame
                </Badge>
              </Text>
              <Text>
                You may have noticed medals displayed on the tournament and
                player profile pages. If you find any discrepancies, such as too
                many or missing medals, please email me. Tournament admins: you
                can submit tournament winners by clicking the "Submit winners"
                button on the tournament management page. <br />
                UPDATE: Hall of Fame is ready Next planned feature is survey
                engine to allow me to ask you about different things concerning
                the future of this website
              </Text>
            </Box>
            <Box>
              <Box>
                <Text textAlign='center'>
                  <Badge fontSize='md' colorScheme='green'>
                    Welcome on gentle island
                  </Badge>
                </Text>
                <Text>
                  A new card tracker is now available - check it out{' '}
                  <Link
                    href='https://gentle-island-0bac64303.5.azurestaticapps.net/'
                    isExternal
                    fontWeight='bold'
                  >
                    here
                  </Link>
                </Text>
              </Box>
            </Box>
            <Box>
              {' '}
              <Text textAlign='center'>
                <Badge fontSize='md' variant='outline'>
                  How we calculate rating
                </Badge>
              </Text>
              <Text>
                Let's say, John with rating 6458 play against Mary with rating
                4591 and Mary wins
              </Text>
              <OrderedList>
                <ListItem>
                  We calculate the rating difference between them - in this case
                  6458 - 4591 = 1867. If it were a friendly game, we would
                  divide this number by 2: 1867 / 2 = 933.5
                </ListItem>
                <ListItem>
                  Then we take 5% of this difference and round it: 1867 * 0.05 =
                  93 points for regular game and 933 * 0.05 = 47 for a friendly
                  game
                </ListItem>
                <ListItem>
                  Next we take a baseline of 100 points (or 50 in case of a
                  friendly game) and if lower rated player wins, we add the
                  difference from step 2, if higher rated - subtract. So in our
                  case because Mary won, she will get and John will lose 100 +
                  93 = 193 points (50 + 47 = 97 in case of friendly game). In
                  case John would be victorious, he would gain and Mary lose 100
                  - 93 points = 7 points (50 - 47 = 3 in case of friendly game)
                </ListItem>
                <ListItem>
                  In case of a tie we skip step 3, higher rated player lose and
                  lower rated player gain rating amount calculated in step 2. So
                  in our case after a tie Mary would gain and John lose 93
                  points in regular game and 47 points in a friendly
                </ListItem>
                <ListItem>
                  You always get at least 1 point increase and never more than
                  200 in one game
                </ListItem>
              </OrderedList>
            </Box>
          </Flex>
        </Flex>
      </Container>
    </>
  );
};

export default MainView;
