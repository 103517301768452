import {
  Box,
  Button,
  Container,
  Divider,
  Heading,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import styleConstants from '../constants/styleConstants';
import GameImportGuide from '../components/GameImportGuide';
import GameImportForm from '../components/GameImportForm';

const steps = [
  { title: 'First', description: 'Guide', component: <GameImportGuide /> },
  { title: 'Second', description: 'Upload', component: <GameImportForm /> }
];

const GameImportView = () => {
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length
  });

  return (
    <>
      <Helmet>
        <title>Import games</title>
      </Helmet>
      <Container
        maxW={'container.md'}
        my={{ base: styleConstants.viewMobileMY, sm: styleConstants.viewMY }}
        paddingX={{ base: 2, sm: 6 }}
        paddingY={{ base: 0, sm: 6 }}
        borderWidth={{ base: 0, sm: 1 }}
        borderRadius={8}
        boxShadow={{ base: 'none', sm: 'lg' }}
      >
        <Heading size='lg' textAlign='center' mb='1rem'>
          Import games
        </Heading>
        <Stepper index={activeStep} colorScheme='orange'>
          {steps.map((step, index) => (
            <Step key={index} onClick={() => setActiveStep(index)}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink='0'>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
        <Divider my='1rem' borderWidth='1.5px' />
        {steps[activeStep].component}
        <Button
          onClick={() => setActiveStep(activeStep === 0 ? 1 : 0)}
          mt='1rem'
          variant='outline'
        >
          {activeStep === 0 ? 'Next' : 'Previous'} step
        </Button>
      </Container>
    </>
  );
};

export default GameImportView;
