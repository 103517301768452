import CreateGame from '../models/game/createGame';
import ForfeitedGame from '../models/game/forfeitedGame';
import Game from '../models/game/game';
import GameInfo from '../models/game/gameInfo';
import GameInfoShort from '../models/game/gameInfoShort';
import SubmittableGame from '../models/game/submittableGame';
import PagedResult from '../models/utils/pagedResult';
import ValidationResult from '../models/utils/validationResult';
import httpClient from './httpClient';

export async function getGameInfo(id: number): Promise<GameInfo> {
  const url = `/game/${id}/info`;

  const response = await httpClient.get(url);

  return response.data;
}

export async function getGame(id: number): Promise<Game> {
  const url = `/game/${id}`;

  const response = await httpClient.get(url);

  return response.data;
}

export async function editGame(id: number, payload: any) {
  const url = `/game/${id}`;

  await httpClient.patch(url, payload);
}

export async function getGames(
  params: URLSearchParams
): Promise<PagedResult<GameInfoShort>> {
  const url = `/game`;

  const response = await httpClient.get(url, { params });

  return response?.data;
}

export async function getSubmittableGames(tournamentId: number) {
  const url = `/tournament/${tournamentId}/game`;

  const response = await httpClient.get<SubmittableGame[]>(url);

  return response.data;
}

export async function getForfeitedGames(
  tournamentId: number,
  playerId: number
) {
  const url = `/tournament/${tournamentId}/game/${playerId}/forfeit`;

  const response = await httpClient.get<ForfeitedGame[]>(url);

  return response.data;
}

export async function importGames(
  tournamentId: number,
  file: File
): Promise<ValidationResult[] | undefined> {
  const url = `tournament/${tournamentId}/game/import`;

  const formData = new FormData();
  formData.append('file', file);

  const response = await httpClient.postForm(url, formData);
  return response.data;
}

export async function scheduleGameForTournament(
  tournamentId: number,
  payload: CreateGame
) {
  const url = `/tournament/${tournamentId}/game`;

  await httpClient.post(url, payload);
}

export async function deleteGame(id: number) {
  const url = `game/${id}`;

  await httpClient.delete(url);
}
