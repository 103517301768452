import React, { useEffect, useState } from 'react';
import { Box, Container } from '@chakra-ui/react';
import useFullPageLoader from '../hooks/useFullPageLoader';
import { Link } from 'react-router-dom';
import { ROUTES } from '../constants';
import { Helmet } from 'react-helmet';
import SignUpRequestCard from '../components/SignUpRequestCard';
import { getSignUpRequests } from '../services/signUpRequestService';
import SignUpRequestInfoShort from '../models/signUp/signUpRequestInfoShort';
import { replaceUrlParams } from '../utils/routerUtils';
import styleConstants from '../constants/styleConstants';

const SignUpRequestsView = () => {
  const [data, setData] = useState<SignUpRequestInfoShort[]>([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const loadSignUpRequests = async () => {
    showLoader();
    const requestsData = await getSignUpRequests();
    setData(requestsData);
    hideLoader();
  };

  useEffect(() => {
    loadSignUpRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign up requests</title>
      </Helmet>
      <Container maxW={'container.lg'} my={styleConstants.viewMY}>
        <Box p={5} borderWidth={1} borderRadius={8} boxShadow='lg'>
          {data.map(request => (
            <Link
              to={replaceUrlParams(ROUTES.SIGNUP_REQUEST_INFO, {
                id: request.id
              })}
              key={request.id}
            >
              <SignUpRequestCard request={request} />
            </Link>
          ))}
        </Box>
      </Container>
      <>{loader}</>
    </>
  );
};

export default SignUpRequestsView;
