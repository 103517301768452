import SubmitSignUpRequest from '../models/signUp/signUpRequest';
import SignUpRequestInfoShort from '../models/signUp/signUpRequestInfoShort';
import httpClient from './httpClient';
import SignUpRequestInfo from '../models/signUp/signUpRequestInfo';
import ChangeSignUpRequestStatus from '../models/signUp/changeSignUpRequestStatus';

export async function submitSignUpRequest(model: SubmitSignUpRequest) {
  const url = '/signuprequest';
  await httpClient.post(url, model);
}

export async function getSignUpRequests(): Promise<SignUpRequestInfoShort[]> {
  const url = '/signuprequest';
  const response = await httpClient.get(url);
  return response?.data;
}

export async function getSignUpRequestInfo(
  id: number
): Promise<SignUpRequestInfo> {
  const url = `/signuprequest/${id}`;
  const response = await httpClient.get(url);
  return response.data;
}

export async function updateSignUpRequestStatus(
  id: number,
  signUpRequestStatusBody: ChangeSignUpRequestStatus
) {
  const url = `/signuprequest/${id}`;
  await httpClient.patch(url, signUpRequestStatusBody);
}
