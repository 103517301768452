import httpClient from './httpClient';
import AdminPlayerEdit from '../models/player/adminPlayerEdit';
import PlayerProfile from '../models/player/playerProfile';
import PlayerEmailAddress from '../models/player/playerEmailAddress';
import PlayerRatingInfo from '../models/player/playerRatingInfo';
import PagedResult from '../models/utils/pagedResult';
import PlayerEdit from '../models/player/playerEdit';
import PlayerPhoneNumber from '../models/player/playerPhoneNumber';
import PlayerTimeDifference from './../models/player/playerTimeDifference';
import TournamentParticipantProfile from '../models/player/tournamentParticipantProfile';
import TournamentInfoShort from '../models/tournament/tournamentInfoShort';

export async function getPlayersRatingInfo(
  params: URLSearchParams
): Promise<PagedResult<PlayerRatingInfo>> {
  const url = `/player`;

  const response = await httpClient.get<PagedResult<PlayerRatingInfo>>(url, {
    params
  });

  return response?.data;
}

export async function getAdminPlayerEdit(id: number): Promise<AdminPlayerEdit> {
  const url = `player/${id}/edit/admin`;

  const response = await httpClient.get<AdminPlayerEdit>(url);

  return response.data;
}

export async function getPlayerEdit(id: number): Promise<PlayerEdit> {
  const url = `player/${id}/edit`;

  const response = await httpClient.get<PlayerEdit>(url);

  return response.data;
}

export async function editPlayerByAdmin(model: AdminPlayerEdit) {
  const url = '/player/edit/admin';

  await httpClient.patch(url, model);
}

export async function editPlayer(model: PlayerEdit) {
  const url = '/player/edit';

  await httpClient.patch(url, model);
}

export async function getPlayerProfile(id: number): Promise<PlayerProfile> {
  const url = `player/${id}/profile`;

  const response = await httpClient.get<PlayerProfile>(url);

  return response.data;
}

export async function getPlayerEmail(id: number): Promise<string> {
  const url = `player/${id}/profile/email`;

  const response = await httpClient.get<PlayerEmailAddress>(url);

  return response.data.emailAddress;
}

export async function getPlayerPhoneNumber(id: number): Promise<string> {
  const url = `player/${id}/profile/phone`;

  const response = await httpClient.get<PlayerPhoneNumber>(url);

  return response.data.phoneNumber;
}

export async function getPlayerTimeDifference(
  id: number
): Promise<PlayerTimeDifference> {
  const url = `player/${id}/time`;

  const response = await httpClient.post<PlayerTimeDifference>(url, {
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    culture: navigator.language
  });

  return response.data;
}

export async function getTournamentParticipantProfile(
  id: number
): Promise<TournamentParticipantProfile> {
  const url = `player/${id}/profile/tournamentRegistration`;

  const response = await httpClient.get<TournamentParticipantProfile>(url);

  return response.data;
}

export async function getPlayerTournaments(
  id: number
): Promise<TournamentInfoShort[]> {
  const url = `player/${id}/tournament`;

  const response = await httpClient.get<TournamentInfoShort[]>(url);

  return response.data;
}

export async function deletePlayer(id: number): Promise<void> {
  const url = `player/${id}`;

  await httpClient.delete(url);
}
