import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useDisclosure
} from '@chakra-ui/react';
import DateFilter from '../filters/DateFilter';
import CheckboxFilter from '../filters/CheckboxFilter';
import { SEARCH_PARAMS } from '../../constants';
import ToggleFilter from '../filters/ToggleFilter';
import SelectOption from '../../models/utils/selectOption';
import SearchFilter from '../filters/SearchFilter';
import { CiFilter } from 'react-icons/ci';

interface GameResultsFilterDrawerProps {
  players: SelectOption[];
  tournaments: SelectOption[];
}

const GameResultsFilterDrawer = ({
  players,
  tournaments
}: GameResultsFilterDrawerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} variant='ghost'>
        <CiFilter />
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose} placement='left'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader py={2}>Filter by</DrawerHeader>
          <DrawerBody pl={2} pt={1}>
            <Flex flexDir='column' gap='.5rem' mb='.5rem' w='100%'>
              <DateFilter
                showMonthDropdown
                showYearDropdown
                onFilterApplied={onClose}
                w='100%'
              />
              <CheckboxFilter
                paramName={SEARCH_PARAMS.PLAYER}
                options={players}
                onFilterApplied={onClose}
                w='100%'
              />
              <CheckboxFilter
                paramName={SEARCH_PARAMS.TOURNAMENT}
                options={tournaments}
                onFilterApplied={onClose}
                w='100%'
              />
              <ToggleFilter
                paramName={SEARCH_PARAMS.WITH}
                value='video'
                onFilterApplied={onClose}
                w='100%'
              />
              <SearchFilter
                paramName={SEARCH_PARAMS.IDENTIFIER}
                onFilterApplied={onClose}
              />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default GameResultsFilterDrawer;
