import {
  Text,
  Grid,
  Flex,
  Divider,
  Box,
  Link as ChakraLink,
  Badge
} from '@chakra-ui/react';
import TournamentPerformanceRecord from '../../models/player/tournamentPerformanceRecord';
import { Link } from 'react-router-dom';
import { replaceUrlParams } from './../../utils/routerUtils';
import { ROUTES } from './../../constants/constants';

type TournamentPerformanceRecordsProps = {
  records: TournamentPerformanceRecord[] | undefined;
};

const TournamentPerformanceRecords = ({
  records
}: TournamentPerformanceRecordsProps) => {
  return (
    <>
      <Text fontWeight='bold' textAlign='center'>
        <Badge>Tournament performance records</Badge>
      </Text>
      <Flex flexDir='column'>
        {records?.length === 0 ? (
          <Text textAlign='center'>No history available</Text>
        ) : (
          <>
            <Grid
              gridTemplateColumns='minmax(50%, max-content) repeat(3, 1fr)'
              fontWeight='bold'
            >
              <Text>Tournament</Text>
              <Text>Won</Text>
              <Text>Lost</Text>
              <Text>Tied</Text>
            </Grid>
            <Divider></Divider>
            {records?.map(r => (
              <Box key={r.tournamentId}>
                <Grid
                  gridTemplateColumns='minmax(50%, max-content) repeat(3, 1fr)'
                  fontSize='smaller'
                >
                  <ChakraLink
                    color='gray.500'
                    as={Link}
                    to={replaceUrlParams(ROUTES.TOURNAMENT_INFO, {
                      id: r.tournamentId
                    })}
                  >
                    {r.tournamentName}
                  </ChakraLink>
                  <Text>{r.won === 0 ? '-' : r.won}</Text>
                  <Text>{r.lost === 0 ? '-' : r.lost}</Text>
                  <Text>{r.tied === 0 ? '-' : r.tied}</Text>
                </Grid>
                <Divider></Divider>
              </Box>
            ))}
          </>
        )}
      </Flex>
    </>
  );
};

export default TournamentPerformanceRecords;
