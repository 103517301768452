import {
  AspectRatio,
  Badge,
  Box,
  Container,
  Flex,
  Skeleton,
  Text
} from '@chakra-ui/react';
import BackNavigationButton from '../components/BackNavigationButton';
import { useEffect, useState } from 'react';
import RatingChangesChart from '../components/playerProfile/RatingChangesChart';
import WinRateChart from '../components/playerProfile/WinRateChart';
import GameDistributionChart from '../components/playerProfile/GameDistributionChart';
import { useParams } from 'react-router-dom';
import { getPlayerProfile } from '../services/playerService';
import PlayerProfile from '../models/player/playerProfile';
import ProfileInfo from '../components/playerProfile/ProfileInfo';
import { Helmet } from 'react-helmet';
import styleConstants from '../constants/styleConstants';
import TournamentPerformanceRecords from '../components/playerProfile/TournamentPerformanceRecords';
import PlayerMedals from '../components/playerProfile/PlayerMedals';

const PlayerProfileView = () => {
  const [playerProfile, setPlayerProfile] = useState<PlayerProfile | null>(
    null
  );
  const { id } = useParams();

  useEffect(() => {
    (async () => loadPlayerProfile(+id!))();
  }, [id]);

  const loadPlayerProfile = async (id: number) => {
    const result = await getPlayerProfile(id);
    setPlayerProfile(result);
  };
  return (
    <>
      <Helmet>
        <title>Player Profile</title>
      </Helmet>
      <Container maxW={'container.lg'} my={styleConstants.viewMY}>
        <Box
          p={{ base: '.125rem', md: '1.5rem' }}
          borderWidth={{ base: 0, md: 1 }}
          borderRadius={8}
          boxShadow={{ base: 'none', md: 'lg' }}
        >
          <BackNavigationButton />
          <Skeleton
            borderWidth={1}
            borderRadius={8}
            boxShadow='lg'
            p={{ base: 2, md: 4 }}
            mb={{ base: '0.5rem', md: '1rem' }}
            minH='17rem'
            isLoaded={!!playerProfile}
          >
            <ProfileInfo playerProfile={playerProfile} />
          </Skeleton>
          <Skeleton
            borderWidth={1}
            borderRadius={8}
            boxShadow='lg'
            p={{ base: 2, md: 4 }}
            mb={{ base: '0.5rem', md: '1rem' }}
            minH='5rem'
            isLoaded={!!playerProfile}
          >
            <Text fontWeight='bold' textAlign='center'>
              <Badge>Medals</Badge>
            </Text>
            <PlayerMedals medals={playerProfile?.medals} />
          </Skeleton>
          <Skeleton
            borderWidth={1}
            borderRadius={8}
            boxShadow='lg'
            p={{ base: 2, md: 4 }}
            mb={{ base: '0.5rem', md: '1rem' }}
            minH='7rem'
            isLoaded={!!playerProfile}
          >
            <TournamentPerformanceRecords
              records={playerProfile?.tournamentPerformanceRecords}
            />
          </Skeleton>
          <Skeleton
            borderWidth={1}
            borderRadius={8}
            boxShadow='lg'
            p={{ base: 0.5, md: 4 }}
            mb={{ base: '0.5rem', md: '1rem' }}
            isLoaded={!!playerProfile}
          >
            <Text py={1} fontWeight='bold' textAlign='center'>
              <Badge>Rating changes</Badge>
            </Text>
            <AspectRatio ratio={{ base: 4 / 3, md: 21 / 9 }}>
              <RatingChangesChart
                ratingChangesChart={
                  playerProfile ? playerProfile.ratingChangesChart : null
                }
              />
            </AspectRatio>
          </Skeleton>
          <Flex
            direction={{ base: 'column', sm: 'row' }}
            justifyContent='space-between'
            gap={{ base: '0.5rem', md: 0 }}
          >
            <Skeleton
              w={{ base: '100%', md: '49%' }}
              borderWidth={1}
              borderRadius={8}
              boxShadow='lg'
              p={{ base: 0.5, md: 4 }}
              isLoaded={!!playerProfile}
            >
              <Text textAlign='center' fontWeight='bold'>
                <Badge>Player win rate</Badge>
              </Text>
              <AspectRatio ratio={{ base: 25 / 27, md: 20 / 21 }}>
                <WinRateChart
                  winRateChart={
                    playerProfile ? playerProfile.winRateChart : null
                  }
                />
              </AspectRatio>
            </Skeleton>
            <Skeleton
              w={{ base: '100%', md: '49%' }}
              borderWidth={1}
              borderRadius={8}
              boxShadow='lg'
              p={{ base: 0.5, md: 4 }}
              isLoaded={!!playerProfile}
            >
              <Text textAlign='center' fontWeight='bold'>
                <Badge>Distribution by side</Badge>
              </Text>
              <AspectRatio ratio={{ base: 25 / 27, md: 20 / 21 }}>
                <GameDistributionChart
                  gameDistributionBySideAndOutcomeChart={
                    playerProfile
                      ? playerProfile.gameDistributionBySideAndOutcomeChart
                      : null
                  }
                />
              </AspectRatio>
            </Skeleton>
          </Flex>
        </Box>
      </Container>
    </>
  );
};

export default PlayerProfileView;
