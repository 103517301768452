import { ObjectSchema, number, object, string } from 'yup';
import TournamentParticipantProfile from '../models/player/tournamentParticipantProfile';
import { MESSAGES } from '../constants/validationMessages';

export const tournamentParticipantProfileValidationSchema: ObjectSchema<TournamentParticipantProfile> =
  object({
    id: number().required(),
    nickname: string().required(),
    phoneNumber: string()
      .optional()
      .nullable()
      .matches(/^\+\d{5,15}$/, MESSAGES.VALID.PHONE_NUMBER),
    cityName: string().required(),
    preferredGamingPlatformName: string().required(),
    preferredGameDurationName: string().required()
  });
