import {
  Badge,
  Grid,
  HStack,
  Text,
  Link as ChakraLink,
  IconButton
} from '@chakra-ui/react';
import Flag from '../Flag';
import ComponentWithHeading from '../ComponentWithHeading';
import MissingGameInfoShort from '../../models/tournament/missingGameInfoShort';
import { Link } from 'react-router-dom';
import { replaceUrlParams } from '../../utils/routerUtils';
import { ROUTES } from '../../constants';
import { DeleteIcon } from '@chakra-ui/icons';
import PermissionGuard from '../guards/PermissionGuard';
import { PERMISSIONS } from '../../constants/permissions';
import ScopeLevel from '../../models/authentication/scopeLevel';

type MissingGameCardProps = {
  game: MissingGameInfoShort;
  onDelete: (id: number) => void;
};

const MissingGameCard = ({ game, onDelete }: MissingGameCardProps) => {
  return (
    <Grid
      templateColumns={{
        base: '1fr 1fr',
        md: 'repeat(7, 1fr)'
      }}
      px={2}
      py={1}
      borderWidth={1}
      borderRadius={6}
      boxShadow='lg'
      gap={{ base: '0.25rem', sm: '1rem' }}
    >
      <ComponentWithHeading heading='Unplayed player:'>
        <HStack>
          <Flag code={game.unplayedPlayerCountry} size='sm' />
          <ChakraLink
            as={Link}
            to={replaceUrlParams(ROUTES.PLAYER_PROFILE, {
              id: game.unplayedPlayerId
            })}
            fontSize='0.9rem'
            lineHeight='5'
          >
            {game.unplayedPlayerName}
          </ChakraLink>
        </HStack>
      </ComponentWithHeading>

      <ComponentWithHeading heading='Side:'>
        <Text fontSize='0.9rem'>{game.unplayedPlayerPower ?? '-'}</Text>
      </ComponentWithHeading>

      <ComponentWithHeading heading='Previous opponent:'>
        <HStack>
          {game.previousOpponentCountry && (
            <Flag code={game.previousOpponentCountry} size='sm' />
          )}
          {game.previousOpponentName ? (
            <ChakraLink
              as={Link}
              to={replaceUrlParams(ROUTES.PLAYER_PROFILE, {
                id: game.previousOpponentId
              })}
              fontSize='0.9rem'
              lineHeight='5'
            >
              {game.previousOpponentName}
            </ChakraLink>
          ) : (
            <Text fontSize='0.9rem' lineHeight='5'>
              -
            </Text>
          )}
        </HStack>
      </ComponentWithHeading>

      <ComponentWithHeading heading='Sides:'>
        <Text fontSize='0.9rem'>{game.powerSelectionMethod}</Text>
      </ComponentWithHeading>

      <ComponentWithHeading heading='Previous due date:'>
        {game.previousDueDate ? (
          <Badge variant='outline' width='fit-content'>
            {new Date(game.previousDueDate).toLocaleDateString()}
          </Badge>
        ) : (
          <Text>-</Text>
        )}
      </ComponentWithHeading>

      <ComponentWithHeading heading='Identifier'>
        <Text fontSize='0.9rem'>{game.identifier}</Text>
      </ComponentWithHeading>

      <PermissionGuard
        requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
        requiredScope={ScopeLevel.Tournament}
        requiredResourceId={game.tournamentId}
      >
        <IconButton
          icon={<DeleteIcon />}
          aria-label='delete missing game entry'
          variant='ghost'
          width='fit-content'
          onClick={() => onDelete(game.id)}
        ></IconButton>
      </PermissionGuard>
    </Grid>
  );
};

export default MissingGameCard;
