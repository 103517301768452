import { ObjectSchema, object, ref, string } from 'yup';
import { MESSAGES } from '../constants/validationMessages';
import SetPasswordFormValue from '../models/forms/setPasswordFormValue';

export const SetPasswordValidationSchema: ObjectSchema<SetPasswordFormValue> =
  object({
    password: string()
      .trim()
      .min(8, MESSAGES.VALID.PASSWORD)
      .required(MESSAGES.REQUIRED.PASSWORD),
    confirmPassword: string()
      .oneOf([ref('password')], MESSAGES.VALID.CONFIRM_PASSWORD)
      .required(MESSAGES.REQUIRED.CONFIRM_PASSWORD)
  });
