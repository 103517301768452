import { Flex, Heading, Text, FlexProps } from '@chakra-ui/react';
import CalendarPage from './CalendarPage';
interface TimeTransitionProps extends FlexProps {
  transitionDate?: string;
  transitionMessage?: string;
  orientation: 'left' | 'right';
}

const TimeTransitionCard = (props: TimeTransitionProps) => {
  const { transitionDate, transitionMessage, orientation, ...cssProps } = props;

  if (!transitionDate) return <></>;

  return (
    <Flex
      gap='0.5rem'
      {...cssProps}
      flexDir={orientation === 'left' ? 'row-reverse' : 'row'}
    >
      <CalendarPage date={new Date(transitionDate)} />
      <Flex
        flexDir='column'
        alignItems={orientation === 'left' ? 'flex-end' : 'flex-start'}
      >
        <Heading size='lg'>Next change:</Heading>
        <Text textAlign={orientation === 'left' ? 'right' : 'left'}>
          {transitionMessage}
        </Text>
      </Flex>
    </Flex>
  );
};

export default TimeTransitionCard;
