import { Button, Skeleton, Link as ChakraLink, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import TournamentInfo from '../../models/tournament/tournamentInfo';
import { Link, useParams } from 'react-router-dom';
import {
  getTournamentInfo,
  updateTournamentRegistration,
  updateTournamentStatus
} from '../../services/tournamentService';
import TournamentStatus from '../../enums/tournamentStatus';
import PermissionGuard from '../../components/guards/PermissionGuard';
import { PERMISSIONS } from '../../constants/permissions';
import ScopeLevel from '../../models/authentication/scopeLevel';
import { replaceUrlParams } from '../../utils/routerUtils';
import RegistrationStatus from '../../enums/registrationStatus';
import { ROUTES } from '../../constants';
import TournamentInfoGrid from '../../components/tournament/TournamentInfoGrid';
import { EditIcon } from '@chakra-ui/icons';
import useFullPageLoader from '../../hooks/useFullPageLoader';
import { MdOutlineFileDownload } from 'react-icons/md';
import useFeedbackRequest from '../../hooks/useFeedbackRequest';

const TournamentInfoView = () => {
  const [tournamentInfo, setTournamentInfo] = useState<
    TournamentInfo | undefined
  >();

  const { id } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  useEffect(() => {
    (async () => {
      const tournament = await getTournamentInfo(+id!);
      setTournamentInfo(tournament);
    })();
  }, [id]);

  let registrationButtonText = '';

  switch (tournamentInfo?.registration) {
    case RegistrationStatus.Open:
      registrationButtonText = 'Close registration';
      break;

    case RegistrationStatus.Closed:
      registrationButtonText = 'Open waiting list';
      break;

    case RegistrationStatus.WaitingList:
      registrationButtonText = 'Close waiting list';
      break;
  }

  const statusButtonText =
    tournamentInfo?.status === TournamentStatus.Upcoming
      ? 'Start tournament'
      : 'End tournament';

  const getNextTournamentStatus = () =>
    tournamentInfo?.status === TournamentStatus.Upcoming
      ? TournamentStatus.Ongoing
      : TournamentStatus.Ended;

  const getNextRegistrationStatus = () => {
    switch (tournamentInfo?.registration) {
      case RegistrationStatus.Closed:
        return RegistrationStatus.WaitingList;

      case RegistrationStatus.WaitingList:
        return RegistrationStatus.Closed;

      case RegistrationStatus.Open:
      default:
        return RegistrationStatus.Closed;
    }
  };

  const changeTournamentStatusQuery = useFeedbackRequest(
    () =>
      updateTournamentStatus(tournamentInfo?.id!, getNextTournamentStatus()),
    showLoader,
    hideLoader,
    'Tournament status has been successfully updated',
    () =>
      setTournamentInfo(state => ({
        ...state!,
        status: getNextTournamentStatus()
      }))
  );

  const handleStatusChange = async () => {
    await changeTournamentStatusQuery();
  };

  const changeRegistrationStatusQuery = useFeedbackRequest(
    () => updateTournamentRegistration(tournamentInfo?.id!),
    showLoader,
    hideLoader,
    'Registration status has been successfully updated',
    () => {
      setTournamentInfo(state => ({
        ...state!,
        registration: getNextRegistrationStatus()
      }));
    }
  );

  const handleRegistrationChange = async () => {
    await changeRegistrationStatusQuery();
  };

  return (
    <>
      <Helmet>
        <title>Tournament info</title>
      </Helmet>
      {tournamentInfo?.status !== TournamentStatus.Ended && (
        <PermissionGuard
          requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
          requiredScope={ScopeLevel.Tournament}
          requiredResourceId={+id!}
        >
          <Skeleton
            marginBottom={{ base: '1rem', sm: 2 }}
            isLoaded={!!tournamentInfo}
          >
            <Flex
              gap='0.5rem'
              flexDir={{ base: 'column', sm: 'row' }}
              flexWrap='wrap'
            >
              <Button onClick={handleRegistrationChange}>
                {registrationButtonText}
              </Button>
              <Button onClick={handleStatusChange}>{statusButtonText}</Button>
              <Button
                as={Link}
                to={replaceUrlParams(ROUTES.TOURNAMENT_EDIT, {
                  id
                })}
                leftIcon={<EditIcon />}
                aria-label='Edit Button'
              >
                Edit tournament
              </Button>
              <Button
                as={ChakraLink}
                href={replaceUrlParams(ROUTES.TOURNAMENT_PARTICIPANT_EXPORT, {
                  id
                })}
                colorScheme='orange'
                leftIcon={<MdOutlineFileDownload />}
                variant='outline'
              >
                Export participants
              </Button>
            </Flex>
          </Skeleton>
        </PermissionGuard>
      )}
      <Skeleton
        minHeight={{ base: '8rem', sm: '10rem' }}
        isLoaded={!!tournamentInfo}
      >
        <TournamentInfoGrid tournamentInfo={tournamentInfo!} />
      </Skeleton>
      {loader}
    </>
  );
};

export default TournamentInfoView;
