import { useEffect, useState } from 'react';
import Poll from '../../models/polls/poll';
import { getPolls, submitVote } from '../../services/pollService';
import PollCard from './PollCard';
import Vote from '../../models/polls/vote';
import { useToast } from '@chakra-ui/react';
import PollStatus from '../../enums/pollStatus';

type PollsListProps = {
  status?: PollStatus;
};

const PollsList = ({ status }: PollsListProps) => {
  const [polls, setPolls] = useState<Poll[]>([]);

  const toast = useToast();

  const handleSubmitVote = async (pollId: string, optionId: string) => {
    try {
      await submitVote(pollId, optionId);

      const updatedPolls = [...polls];
      const pollIndex = polls.findIndex(p => p.id === pollId)!;
      const poll = updatedPolls[pollIndex];

      const updatedOptions = [...poll.options];
      const index = updatedOptions.findIndex(o => o.id === optionId);
      updatedOptions[index] = {
        ...updatedOptions[index],
        votesCount: updatedOptions[index].votesCount + 1
      };

      const currentUserVote: Vote = { optionId };

      updatedPolls[pollIndex] = {
        ...poll,
        options: updatedOptions,
        votesCount: poll.votesCount + 1,
        currentUserVote
      };

      setPolls(updatedPolls);

      toast({
        title: 'Success',
        description: 'Your vote was successfully submitted',
        status: 'success'
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Something went wrong with your vote',
        status: 'error'
      });
    }
  };

  useEffect(() => {
    const fetchPolls = async () => {
      const polls = await getPolls(status);
      setPolls(polls);
    };

    fetchPolls();
  }, [status]);

  return (
    <>
      {polls.map(p => (
        <PollCard key={p.id} poll={p} onSubmitVote={handleSubmitVote} />
      ))}
    </>
  );
};

export default PollsList;
