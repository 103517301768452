import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { logout } from '../services/accountService';
import { RootState } from './store';
import Scope from '../models/authentication/scope';
import AuthenticationInfo from '../models/authentication/authenticationInfo';
import ScopeLevel from '../models/authentication/scopeLevel';

export interface UserState {
  playerId?: number;
  name?: string;
  permissions?: string[];
  scopes?: Scope[];
}

const initialState: UserState = {};

export const logUserOut = createAsyncThunk('user/logout', async () => {
  await logout();
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoggedIn(state, action) {
      const authInfo = action.payload as AuthenticationInfo;

      return { ...authInfo };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logUserOut.fulfilled, (state, action) => {
        return initialState;
      })
      .addCase(logUserOut.rejected, (state, action) => {
        // display toast here
        console.log('error by logout');
      });
  }
});

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const { userLoggedIn } = userSlice.actions;

export const selectUserLogged = (state: RootState): boolean =>
  !!state.user.playerId;

export const selectUserName = (state: RootState): string | undefined =>
  state.user.name;

export const selectUserId = (state: RootState): number | undefined =>
  state.user.playerId;

export const selectUserHasPermission = (
  state: RootState,
  requiredPermission: string
): boolean | undefined =>
  state.user.permissions?.some(p => p === requiredPermission);

export const selectUserHasScope = (
  state: RootState,
  scopeLevel: ScopeLevel,
  resourceId?: number
): boolean | undefined =>
  state.user.scopes?.some(
    s => s.level === scopeLevel && (!resourceId || s.resourceId === resourceId)
  );

// Export the slice reducer as the default export
export default userSlice.reducer;
