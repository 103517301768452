import { Button, Flex } from '@chakra-ui/react';
import SelectInput from '../selectInput/SelectInput';
import { useEffect, useState } from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import SelectOption from '../../models/utils/selectOption';
import { getTournamentParticipants } from '../../services/lookupService';
import TournamentParticipantStatus from '../../enums/tournamentParticipantStatus';
import useFeedbackRequest from '../../hooks/useFeedbackRequest';
import { replaceForfeitedPlayer } from '../../services/participantsService';
import useFullPageLoader from '../../hooks/useFullPageLoader';

type MissingGamesPanelProps = {
  tournamentId: number;
  onForfeitedPlayerReplaced: () => void;
};

const MissingGamesPanel = ({
  tournamentId,
  onForfeitedPlayerReplaced
}: MissingGamesPanelProps) => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [forfeitedPlayerId, setForfeitedPlayerId] = useState<
    number | undefined
  >();
  const [replacementPlayerId, setReplacementPlayerId] = useState<
    number | undefined
  >();

  const [replacementPlayers, setReplacementPlayers] = useState(
    [] as SelectOption[]
  );

  const [forfeitedPlayers, setForfeitedPlayers] = useState(
    [] as SelectOption[]
  );

  useEffect(() => {
    (async () => {
      const replacementPlayers = await getTournamentParticipants(tournamentId, [
        TournamentParticipantStatus.Waiting
      ]);

      setReplacementPlayers(replacementPlayers);

      const forfeitedPlayers = await getTournamentParticipants(tournamentId, [
        TournamentParticipantStatus.Forfeited
      ]);

      setForfeitedPlayers(forfeitedPlayers);
    })();
  }, [tournamentId]);

  const processReplacement = useFeedbackRequest(
    () =>
      replaceForfeitedPlayer(
        tournamentId,
        forfeitedPlayerId!,
        replacementPlayerId!
      ),
    showLoader,
    hideLoader,
    'Players has been successfully replaced',
    () => {
      onForfeitedPlayerReplaced();
      setForfeitedPlayerId(undefined);
      setReplacementPlayerId(undefined);
    }
  );

  return (
    <Flex gap='0.5rem' alignItems='center'>
      <SelectInput
        value={forfeitedPlayerId}
        onChange={value => setForfeitedPlayerId(value as number)}
        options={forfeitedPlayers}
        placeholder='Forfeited player'
      />
      <ArrowBackIcon />
      <SelectInput
        value={replacementPlayerId}
        onChange={value => setReplacementPlayerId(value as number)}
        options={replacementPlayers}
        placeholder='Replacement player'
      />
      <Button
        variant='outline'
        isDisabled={!forfeitedPlayerId || !replacementPlayerId}
        onClick={() => processReplacement()}
      >
        Replace
      </Button>
      {loader}
    </Flex>
  );
};

export default MissingGamesPanel;
