import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast
} from '@chakra-ui/react';
import SubmitTournamentWinnersFormValue from '../models/forms/submitTournamentWinnersFormValue';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitTournamentWinnersValidationSchema } from '../validation/submitTournamentWinnersValidationSchema';
import { useParams } from 'react-router-dom';
import SelectOption from '../models/utils/selectOption';
import { useEffect, useState } from 'react';
import { getTournamentParticipants } from '../services/lookupService';
import TournamentParticipantStatus from '../enums/tournamentParticipantStatus';
import SelectInput from './selectInput/SelectInput';
import useFullPageLoader from '../hooks/useFullPageLoader';
import useErrorMessage from '../hooks/useErrorMessage';
import SubmitTournamentWinners from '../models/tournamentWinners/submitTournamentWinners';
import { submitTournamentWinners } from '../services/tournamentWinnerService';

type SubmitTournamentWinnersPopupProps = {
  onClose: () => void;
};

const SubmitTournamentWinnersPopup = ({
  onClose
}: SubmitTournamentWinnersPopupProps) => {
  const { id } = useParams();

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<SubmitTournamentWinnersFormValue>({
    resolver: yupResolver(SubmitTournamentWinnersValidationSchema),
    mode: 'onTouched',
    shouldFocusError: false
  });

  const [playersSelectOptions, setPlayersSelectOptions] = useState(
    [] as SelectOption[]
  );

  useEffect(() => {
    const fetch = async () => {
      const players = await getTournamentParticipants(+id!!, [
        TournamentParticipantStatus.Active
      ]);
      setPlayersSelectOptions(players);
    };
    fetch();
  }, [id]);

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [errorMessage, setErrorMessage, clearErrorMessage] = useErrorMessage();
  const toast = useToast();

  const onSubmit: SubmitHandler<
    SubmitTournamentWinnersFormValue
  > = async props => {
    clearErrorMessage();
    showLoader();
    try {
      const request: SubmitTournamentWinners = {
        ...props
      };
      await submitTournamentWinners(+id!!, request);

      toast({
        title: 'Changes saved',
        description: 'Tournament winners were successfully submitted',
        status: 'success'
      });

      onClose();
    } catch (error: any) {
      setErrorMessage(error.message);
    }

    hideLoader();
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Tournament winners</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex gap={6} flexDirection='column'>
              <FormControl isRequired isInvalid={!!errors.firstPlacePlayerId}>
                <FormLabel>First place:</FormLabel>
                <Controller
                  control={control}
                  name='firstPlacePlayerId'
                  render={({ field: { onChange, value } }) => (
                    <SelectInput
                      value={value}
                      onChange={onChange}
                      options={playersSelectOptions}
                      isInvalid={!!errors.firstPlacePlayerId}
                      isSearchable
                      placeholder='First place (type here to search)'
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.firstPlacePlayerId?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.secondPlacePlayerId}>
                <FormLabel>Second place:</FormLabel>
                <Controller
                  control={control}
                  name='secondPlacePlayerId'
                  render={({ field: { onChange, value } }) => (
                    <SelectInput
                      value={value}
                      onChange={onChange}
                      options={playersSelectOptions}
                      isInvalid={!!errors.secondPlacePlayerId}
                      isSearchable
                      placeholder='Second place (type here to search)'
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.secondPlacePlayerId?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!errors.thirdPlacePlayerId}>
                <FormLabel>Third place:</FormLabel>
                <Controller
                  control={control}
                  name='thirdPlacePlayerId'
                  render={({ field: { onChange, value } }) => (
                    <SelectInput
                      value={value}
                      onChange={onChange}
                      options={playersSelectOptions}
                      isInvalid={!!errors.thirdPlacePlayerId}
                      isSearchable
                      placeholder='Third place (type here to search)'
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.thirdPlacePlayerId?.message}
                </FormErrorMessage>
              </FormControl>
              {errorMessage}
              <Flex justifyContent='center'>
                <Button
                  colorScheme='teal'
                  variant='outline'
                  width='36'
                  type='submit'
                >
                  Submit
                </Button>
              </Flex>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
      {loader}
    </Modal>
  );
};

export default SubmitTournamentWinnersPopup;
