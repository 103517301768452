import { Container, Flex, Skeleton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import TournamentInfoShort from '../../models/tournament/tournamentInfoShort';
import { getTournaments } from '../../services/tournamentService';
import { ROUTES } from '../../constants';
import TournamentCard from '../../components/tournament/TournamentCard';
import { replaceUrlParams } from '../../utils/routerUtils';
import styleConstants from '../../constants/styleConstants';
import { Link, useSearchParams } from 'react-router-dom';
import Pagination from '../../components/pagination/Pagination';

const TournamentsView = () => {
  const [searchParams] = useSearchParams();
  const [tournaments, setTournaments] = useState<TournamentInfoShort[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    (async () => {
      const tournaments = await getTournaments(searchParams);
      setTournaments(tournaments.items);
      setTotalPages(tournaments.totalPages);
    })();
  }, [searchParams]);
  
  return (
    <>
      <Helmet>
        <title>Tournaments</title>
      </Helmet>
      <Container
        maxW={'container.lg'}
        p={{ base: 1, sm: 5 }}
        borderWidth={{ base: 0, sm: 1 }}
        borderRadius={8}
        my={styleConstants.viewMY}
      >
        <Flex flexDir='column' gap='0.5rem'>
          {tournaments.length > 0 ? (
            tournaments.map(tournament => (
              <Link
                to={replaceUrlParams(ROUTES.TOURNAMENT_INFO, {
                  id: tournament.id
                })}
                key={tournament.id}
              >
                <TournamentCard tournament={tournament}></TournamentCard>
              </Link>
            ))
          ) : (
            <>
              {[...Array(15)].map((_value, index) => (
                <Skeleton
                  h={{ base: '5rem', md: '3.5rem' }}
                  key={index}
                  borderRadius={6}
                />
              ))}
            </>
          )}
        </Flex>
      </Container>
      <Pagination totalPages={totalPages} />
    </>
  );
};

export default TournamentsView;
