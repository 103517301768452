import { Helmet } from 'react-helmet';
import { useAppSelector } from '../../hooks/hooks';
import { selectUserId } from '../../store/userSlice';
import { Button, Card, CardBody, Text, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  getRegistrationInfo,
  registerUserInTournament,
  unregisterUserInTournament
} from '../../services/tournamentService';
import TournamentRegistrationInfo from '../../models/tournament/tournamentRegistrationInfo';
import RegistrationStatus from '../../enums/registrationStatus';
import TournamentParticipantProfile from '../../models/player/tournamentParticipantProfile';
import { getTournamentParticipantProfile } from '../../services/playerService';
import PersonalDataVerificationPopup from '../../components/PersonalDataVerificationPopup';
import useFullPageLoader from '../../hooks/useFullPageLoader';
import TournamentStatus from '../../enums/tournamentStatus';
import TournamentParticipantStatus from '../../enums/tournamentParticipantStatus';

const TournamentRegistrationView = () => {
  const { id } = useParams();
  const userId = useAppSelector(selectUserId);

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const toast = useToast();

  const [registrationInfo, setRegistrationInfo] = useState<
    TournamentRegistrationInfo | undefined
  >();

  const [openVerificationPopup, setOpenVerificationPopup] = useState(false);
  const [tournamentParticipantProfile, setTournamentParticipantProfile] =
    useState<TournamentParticipantProfile>();

  const openVerificationModal = async () => {
    const tournamentParticipantProfile = await getTournamentParticipantProfile(
      userId!
    );

    setTournamentParticipantProfile(tournamentParticipantProfile);
    setOpenVerificationPopup(true);
  };

  const handleContinue = () => {
    setOpenVerificationPopup(false);
    handleRegister();
  };

  const handleRegister = async () => {
    showLoader();

    try {
      await registerUserInTournament(+id!);

      setRegistrationInfo({
        ...registrationInfo!!,
        isRegistered: true
      });

      toast({
        title: 'Registration successful',
        description: 'You have successfully registered for the tournament',
        status: 'success'
      });
    } catch (error: any) {
      toast({
        title: 'Registration failed',
        description: 'Sorry, there was an issue with your registration',
        status: 'error'
      });
    }

    hideLoader();
  };

  const handleUnregister = async () => {
    showLoader();
    try {
      await unregisterUserInTournament(+id!);

      setRegistrationInfo({
        ...registrationInfo!!,
        isRegistered: false
      });

      toast({
        title: 'Unregistration successful',
        description: 'You have successfully unregistered from the tournament',
        status: 'success'
      });
    } catch (error: any) {
      toast({
        title: 'Unregistration failed',
        description: 'Sorry, there was an issue with your unregistration',
        status: 'error'
      });
    }
    hideLoader();
  };

  useEffect(() => {
    (async () => {
      const res = await getRegistrationInfo(+id!);
      setRegistrationInfo(res);
    })();
  }, [id]);

  const displayText = () => {
    if (registrationInfo?.tournamentStatus === TournamentStatus.Ended)
      return 'Tournament has already ended';

    if (
      !registrationInfo?.isRegistered &&
      registrationInfo?.registration === RegistrationStatus.Closed
    )
      return 'Registration is closed';

    if (
      !registrationInfo?.isRegistered &&
      registrationInfo?.registration === RegistrationStatus.WaitingList
    )
      return 'Registration is currently closed, but you may join the waiting list. If a spot becomes available due to a forfeit, you will be given the opportunity to take it';

    if (
      registrationInfo?.isRegistered &&
      registrationInfo.participantStatus ===
        TournamentParticipantStatus.Forfeited
    )
      return 'Once you have forfeited, you cannot register again';

    if (
      registrationInfo?.isRegistered &&
      registrationInfo.tournamentStatus !== TournamentStatus.Upcoming &&
      registrationInfo.participantStatus === TournamentParticipantStatus.Active
    )
      return 'You are unable to unregister as the tournament is already underway. If you wish to forfeit, please reach out to the administrator';
  };

  const registerButton = () => {
    if (
      registrationInfo?.tournamentStatus === TournamentStatus.Ended ||
      registrationInfo?.participantStatus ===
        TournamentParticipantStatus.Forfeited
    )
      return <></>;

    if (
      !registrationInfo?.isRegistered &&
      (registrationInfo?.registration === RegistrationStatus.Open ||
        registrationInfo?.registration === RegistrationStatus.WaitingList)
    )
      return (
        <Button
          size='sm'
          colorScheme='orange'
          mt={2}
          onClick={() => openVerificationModal()}
        >
          Register
        </Button>
      );

    if (
      registrationInfo?.isRegistered &&
      (registrationInfo.tournamentStatus === TournamentStatus.Upcoming ||
        registrationInfo.participantStatus ===
          TournamentParticipantStatus.Waiting)
    )
      return (
        <Button
          size='sm'
          colorScheme='orange'
          mt={2}
          onClick={() => handleUnregister()}
        >
          Unregister
        </Button>
      );
  };
  return (
    <>
      <Helmet>
        <title>Tournament registration</title>
      </Helmet>

      <Card variant='filled'>
        <CardBody>
          <Text>{displayText()}</Text>
          {registerButton()}
        </CardBody>
      </Card>

      {loader}
      {tournamentParticipantProfile && (
        <PersonalDataVerificationPopup
          open={openVerificationPopup}
          onClose={() => setOpenVerificationPopup(false)}
          tournamentParticipantProfile={tournamentParticipantProfile}
          onContinue={handleContinue}
        />
      )}
    </>
  );
};

export default TournamentRegistrationView;
