import { Flex, Link as ChakraLink } from '@chakra-ui/react';
import TournamentWinnerInfo from '../../models/tournamentWinners/tournamentWinnerInfo';
import Medal from '../Medal';
import { Link } from 'react-router-dom';
import { replaceUrlParams } from '../../utils/routerUtils';
import { ROUTES } from '../../constants';

type TournamentMedalProps = {
  tournamentWinners?: TournamentWinnerInfo[];
};

const TournamentMedals = ({ tournamentWinners }: TournamentMedalProps) => {
  if (!tournamentWinners?.length) return <></>;

  return (
    <>
      {tournamentWinners.map(w => (
        <Flex
          alignItems={'center'}
          borderRadius={8}
          borderWidth={1}
          p='0.25rem'
        >
          <Medal place={w.place} size='md' />
          <ChakraLink
            as={Link}
            to={replaceUrlParams(ROUTES.PLAYER_PROFILE, {
              id: w.playerId
            })}
            key={w.playerId}
            fontSize='0.8rem'
          >
            {w.playerName}
          </ChakraLink>
        </Flex>
      ))}
    </>
  );
};

export default TournamentMedals;
