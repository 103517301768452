import { Box, Button, Grid, Flex } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import BackNavigationButton from '../../components/BackNavigationButton';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { replaceUrlParams } from '../../utils/routerUtils';
import MenuItem from '../../models/utils/menuItem';
import DrawerMenu from '../../components/DrawerMenu';
import PermissionGuard from '../../components/guards/PermissionGuard';
import { PERMISSIONS } from '../../constants/permissions';
import ScopeLevel from '../../models/authentication/scopeLevel';

const TournamentView = () => {
  const { id } = useParams();

  const menuItems: MenuItem[] = [
    {
      title: 'Info',
      link: replaceUrlParams(ROUTES.TOURNAMENT_INFO, { id: +id! }),
      private: false
    },
    {
      title: 'Participants',
      link: replaceUrlParams(ROUTES.TOURNAMENT_PARTICIPANTS, {
        id: +id!
      }),
      private: false
    },
    {
      title: 'Schedule',
      link: replaceUrlParams(ROUTES.TOURNAMENT_SCHEDULE, { id: +id! }),
      private: false
    },
    {
      title: 'Standings',
      link: replaceUrlParams(ROUTES.TOURNAMENT_STANDINGS, {
        id: +id!
      }),
      private: false
    },
    {
      title: 'Registration',
      link: replaceUrlParams(ROUTES.TOURNAMENT_REGISTRATION, {
        id: +id!
      }),
      private: false
    },
    {
      title: 'Waiting list',
      link: replaceUrlParams(ROUTES.TOURNAMENT_WAITING_LIST, {
        id: +id!
      }),
      private: false
    },
    {
      title: 'Missing games',
      link: replaceUrlParams(ROUTES.TOURNAMENT_MISSING_GAMES, {
        id: +id!
      }),
      private: false
    },
    {
      title: 'Management',
      link: replaceUrlParams(ROUTES.TOURNAMENT_MANAGEMENT, {
        id: +id!
      }),
      private: true
    }
  ];

  return (
    <>
      <Helmet>
        <title>Tournament</title>
      </Helmet>

      <Grid
        gridTemplateColumns={{ base: '1fr', sm: '1fr 4fr' }}
        gap='1rem'
        my={6}
        mx={{ base: '.5rem', sm: 6 }}
      >
        <Flex
          flexDir='column'
          gap='0.5rem'
          display={{ base: 'none', sm: 'flex' }}
        >
          {menuItems.map((mi, index) => (
            <NavLink to={mi.link} key={index} end>
              {({ isActive }) =>
                mi.private ? (
                  <PermissionGuard
                    requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
                    requiredScope={ScopeLevel.Tournament}
                    requiredResourceId={+id!}
                  >
                    <Button
                      variant={isActive ? 'solid' : 'outline'}
                      width='100%'
                    >
                      {mi.title}
                    </Button>
                  </PermissionGuard>
                ) : (
                  <Button variant={isActive ? 'solid' : 'outline'} width='100%'>
                    {mi.title}
                  </Button>
                )
              }
            </NavLink>
          ))}
        </Flex>

        <Box>
          <Flex flexDir='column' alignItems='flex-start' gap='0.5rem'>
            <BackNavigationButton />
            <DrawerMenu menuItems={menuItems} />
          </Flex>

          <Box marginTop={{ base: 1, sm: 2 }}>
            <Outlet />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default TournamentView;
