import { ResponsiveLine } from '@nivo/line';
import React from 'react';
import LinearChart from '../../models/chart/linearChart';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import LinearChartTooltip from '../tooltips/LinearChartTooltip';
import { Flex, Text } from '@chakra-ui/react';

interface RatingChangesLineProps {
  ratingChangesChart: LinearChart | null;
}

const RatingChangesChart = ({ ratingChangesChart }: RatingChangesLineProps) => {
  const navigate = useNavigate();
  return ratingChangesChart ? (
    <ResponsiveLine
      data={ratingChangesChart.data}
      margin={{ top: 10, right: 10, bottom: 10, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: ratingChangesChart.yScaleMin,
        max: ratingChangesChart.yScaleMax,
        stacked: true,
        reverse: false
      }}
      yFormat=' >-.2f'
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      onClick={point => {
        navigate(`${ROUTES.GAME_RESULTS}/${point.data.x}`);
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      enableGridX={false}
      colors={{ scheme: 'category10' }}
      lineWidth={2}
      pointSize={8}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      enableArea={true}
      areaBaselineValue={ratingChangesChart.yScaleMin}
      areaOpacity={0.6}
      useMesh={true}
      legends={[]}
      crosshairType='y'
      tooltip={props => <LinearChartTooltip id={props.point.data.x} />}
      theme={{
        textColor: '#A0AEC0'
      }}
    />
  ) : (
    <Flex justifyContent='center' alignItems='center'>
      <Text>
        Zero games played. You need to play at least one game to see rating
        changes chart
      </Text>
    </Flex>
  );
};

export default RatingChangesChart;
