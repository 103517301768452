import {
  Box,
  ListItem,
  Text,
  UnorderedList,
  useColorMode
} from '@chakra-ui/react';
import ValidationResult from '../models/utils/validationResult';

type ErrorsListProps = {
  validationErrors: ValidationResult[];
};

const ErrorsList = ({ validationErrors }: ErrorsListProps) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      mt='1rem'
      p={2}
      borderRadius={5}
      borderWidth={1}
      borderColor={colorMode === 'light' ? 'red.600' : 'whiteAlpha.400'}
      background={colorMode === 'light' ? 'red.100' : 'red.900'}
    >
      <UnorderedList spacing={2}>
        {validationErrors.map((g, index: number) => (
          <ListItem key={index}>
            <Text>{g.key}</Text>
            <UnorderedList fontWeight='light'>
              {g.errors.map((e: string, i: number) => (
                <ListItem key={i}>{e}</ListItem>
              ))}
            </UnorderedList>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

export default ErrorsList;
