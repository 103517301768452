import { Badge, BadgeProps, Text } from '@chakra-ui/react';
import HoursDifference from '../../models/utils/hoursDifference';

interface HoursDifferenceMessageProps extends BadgeProps {
  hoursDifference?: HoursDifference;
}

const HoursDifferenceMessage = (props: HoursDifferenceMessageProps) => {
  const { hoursDifference, ...cssProps } = props;

  if (!hoursDifference)
    return (
      <Text fontSize='lg' fontWeight='semibold'>
        We couldn't determine time difference
      </Text>
    );

  let message = '';

  if (hoursDifference.hours === 0) {
    message = 'Your times match';
  } else {
    const hours = `${Math.abs(hoursDifference.hours)} hour${
      Math.abs(hoursDifference.hours) === 1 ? '' : 's'
    }`;

    const amount =
      hoursDifference.minutes === 0
        ? hours
        : `${hours} and ${hoursDifference.minutes} minutes`;

    const direction = hoursDifference.hours > 0 ? 'ahead' : 'behind';

    message = `You are ${amount} ${direction}`;
  }

  return (
    <Badge {...cssProps} fontSize='lg' colorScheme='orange'>
      {message}
    </Badge>
  );
};

export default HoursDifferenceMessage;
