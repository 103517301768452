import PlayerMedalsInfo from '../models/tournamentWinners/playerMedalsInfo';
import SubmitTournamentWinners from '../models/tournamentWinners/submitTournamentWinners';
import TournamentMedalsInfo from '../models/tournamentWinners/tournamentMedalsInfo';
import httpClient from './httpClient';

export async function submitTournamentWinners(
  tournamentId: number,
  model: SubmitTournamentWinners
) {
  const url = `/tournament/${tournamentId}/winners`;
  const response = await httpClient.post(url, model);
  return response?.data;
}

export async function getTournamentWinnersByPlayer() {
  const url = '/tournamentWinners/players';
  const response = await httpClient.get<PlayerMedalsInfo[]>(url);
  return response.data;
}

export async function getTournamentWinnersByTournament() {
  const url = '/tournamentWinners/tournaments';
  const response = await httpClient.get<TournamentMedalsInfo[]>(url);
  return response.data;
}
