import PlayerRatingInfo from '../models/player/playerRatingInfo';
import { Flex, HStack, Text, Grid, Badge } from '@chakra-ui/react';
import Flag from './Flag';

interface PlayerRatingCardProps {
  rating: PlayerRatingInfo;
}

const PlayerRatingCard = (props: PlayerRatingCardProps) => {
  const { rating, firstName, rank, lastName, countryId, lastActivityDate } =
    props.rating;

  return (
    <Grid
      templateColumns='min-content 3fr 2fr min-content'
      px={2}
      py={1}
      borderWidth={1}
      borderRadius={6}
      boxShadow='lg'
      gap='1rem'
    >
      <Flex flexDir='column'>
        <Text fontSize='0.6rem' color='gray.400'>
          Rank:
        </Text>
        <Text fontWeight='bold'>{rank ?? '-'}</Text>
      </Flex>
      <Flex flexDir='column'>
        <Text fontSize='0.6rem' color='gray.400'>
          Player:
        </Text>
        <HStack height='100%'>
          <Flag code={countryId} size='sm' />
          <Text lineHeight='4'>
            {firstName} {lastName}
          </Text>
        </HStack>
      </Flex>
      <Flex flexDir='column'>
        <Text fontSize='0.6rem' color='gray.400'>
          Last activity date:
        </Text>
        <Text>
          <Badge variant='outline'>
            {lastActivityDate
              ? new Date(lastActivityDate).toLocaleDateString()
              : '-'}
          </Badge>
        </Text>
      </Flex>
      <Flex flexDir='column' alignItems='flex-end'>
        <Text fontSize='0.6rem' color='gray.400'>
          Rating:
        </Text>
        <Text fontWeight='bold'>{rating}</Text>
      </Flex>
    </Grid>
  );
};

export default PlayerRatingCard;
