import { Flex, Skeleton } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import MissingGameInfoShort from '../../models/tournament/missingGameInfoShort';
import { getMissingGames } from '../../services/tournamentService';
import MissingGameCard from '../../components/missingGames/MissingGameCard';
import { Helmet } from 'react-helmet';
import styleConstants from '../../constants/styleConstants';
import MissingGamesPanel from '../../components/missingGames/MissingGamesPanel';
import PermissionGuard from '../../components/guards/PermissionGuard';
import { PERMISSIONS } from '../../constants/permissions';
import ScopeLevel from '../../models/authentication/scopeLevel';
import { deleteMissingGame } from '../../services/missingGamesService';

const TournamentMissingGamesView = () => {
  const { id } = useParams();

  const [missingGames, setMissingGames] = useState(
    [] as MissingGameInfoShort[]
  );

  const [loaded, setLoaded] = useState(false);

  const fetchMissingGames = async () => {
    setLoaded(false);
    const games = await getMissingGames(+id!);
    setMissingGames(games);
    setLoaded(true);
  };

  useEffect(() => {
    fetchMissingGames();
  }, [id]);

  const handleForfeitedPlayerReplaced = async () => {
    await fetchMissingGames();
  };

  const handleDelete = async (id: number) => {
    await deleteMissingGame(id);

    const newMissingGames = missingGames.filter(g => g.id !== id);
    setMissingGames(newMissingGames);
  };

  const skeleton = useMemo(
    () =>
      [...Array(15)].map((_value, index) => (
        <Skeleton
          h={{ base: '5rem', md: '3.5rem' }}
          key={index}
          borderRadius={8}
          boxShadow='lg'
        />
      )),
    []
  );

  const content = missingGames.map(g => (
    <MissingGameCard key={g.id} game={g} onDelete={handleDelete} />
  ));

  return (
    <>
      <Helmet>
        <title>Tournament missing games</title>
      </Helmet>
      <PermissionGuard
        requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
        requiredScope={ScopeLevel.Tournament}
        requiredResourceId={+id!}
      >
        <MissingGamesPanel
          tournamentId={+id!}
          onForfeitedPlayerReplaced={handleForfeitedPlayerReplaced}
        />
      </PermissionGuard>
      <Flex
        flexDir='column'
        gap={{ base: 1, sm: 2 }}
        mt='0.5rem'
        mb={styleConstants.viewMY}
      >
        {loaded ? content : skeleton}
      </Flex>
    </>
  );
};

export default TournamentMissingGamesView;
