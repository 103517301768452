import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  HStack,
  SkeletonText,
  Text,
  useToast
} from '@chakra-ui/react';
import BackNavigationButton from '../components/BackNavigationButton';
import SignUpRequestInfo from '../models/signUp/signUpRequestInfo';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getSignUpRequestInfo,
  updateSignUpRequestStatus
} from '../services/signUpRequestService';
import useFullPageLoader from '../hooks/useFullPageLoader';
import { ROUTES } from '../constants';
import SignUpRequestStatus from '../enums/signUpRequestStatus';
import ComponentWithHeading from '../components/ComponentWithHeading';
import Flag from '../components/Flag';
import styleConstants from '../constants/styleConstants';

const SignUpRequestInfoView = () => {
  const [signUpRequestInfo, setSignUpRequestInfo] =
    useState<SignUpRequestInfo | null>(null);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const toast = useToast();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const result = await getSignUpRequestInfo(+id!);
      setSignUpRequestInfo(result);
    })();
  }, [id]);

  const showSignUpRequestStatus = (
    isSuccess: boolean,
    status: SignUpRequestStatus
  ) => {
    if (isSuccess) {
      toast({
        title: 'Sign up request status has been updated',
        description: `Sign up request of ${signUpRequestInfo?.firstName} ${signUpRequestInfo?.lastName} was successfully ${status}`,
        status: 'success'
      });
    } else {
      toast({
        title: 'Error',
        description: `Something went wrong. Sign up request of ${signUpRequestInfo?.firstName} ${signUpRequestInfo?.lastName} was not ${status}`,
        status: 'error'
      });
    }
  };

  const handleSignUpRequest = async (status: SignUpRequestStatus) => {
    showLoader();
    try {
      await updateSignUpRequestStatus(+id!, { status });
      showSignUpRequestStatus(true, status);
      navigate(ROUTES.SIGNUP_REQUESTS);
    } catch {
      showSignUpRequestStatus(false, status);
    }
    hideLoader();
  };

  return (
    <>
      <Helmet>
        <title>Sign up request</title>
      </Helmet>
      <Container maxW={'container.lg'} my={styleConstants.viewMY}>
        <Box
          p={{ base: '.125rem', md: '1.5rem' }}
          borderWidth={{ base: 0, md: 1 }}
          borderRadius={8}
          boxShadow={{ base: 'none', md: 'lg' }}
        >
          <BackNavigationButton />
          <Box
            borderWidth={1}
            borderRadius={8}
            boxShadow='lg'
            p={{ base: 2, md: 4 }}
            mb={{ base: '0.25rem', md: '1rem' }}
          >
            <Grid templateColumns={{ base: '1fr 1fr', sm: '1fr 2fr' }} gap={1}>
              {signUpRequestInfo ? (
                <>
                  <ComponentWithHeading heading='First name'>
                    <Text mt='-0.3rem' fontSize='0.8rem'>
                      {signUpRequestInfo.firstName}
                    </Text>
                  </ComponentWithHeading>
                  <ComponentWithHeading heading='Last name'>
                    <Text mt='-0.3rem' fontSize='0.8rem'>
                      {signUpRequestInfo.lastName}
                    </Text>
                  </ComponentWithHeading>
                  <ComponentWithHeading heading='Country'>
                    <HStack>
                      <Flag code={signUpRequestInfo.countryId} size='sm' />
                      <Text fontSize='0.8rem' lineHeight='3'>
                        {signUpRequestInfo.countryName}
                      </Text>
                    </HStack>
                  </ComponentWithHeading>
                  <ComponentWithHeading heading='Regional federation'>
                    {signUpRequestInfo.regionalFederationId ? (
                      <HStack>
                        <Flag
                          code={signUpRequestInfo.regionalFederationId}
                          isRegional
                          size='sm'
                        />
                        <Text fontSize='0.8rem'>
                          {signUpRequestInfo.regionalFederationName}
                        </Text>
                      </HStack>
                    ) : (
                      <Text mt='-0.3rem' fontSize='0.8rem'>
                        -
                      </Text>
                    )}
                  </ComponentWithHeading>
                  <ComponentWithHeading heading='Email'>
                    <Text mt='-0.3rem' fontSize='0.8rem'>
                      {signUpRequestInfo.email}
                    </Text>
                  </ComponentWithHeading>
                  <ComponentWithHeading heading='Nickname'>
                    <Text mt='-0.3rem' fontSize='0.8rem'>
                      {signUpRequestInfo.nickname ?? '-'}
                    </Text>
                  </ComponentWithHeading>
                  <ComponentWithHeading heading='Phone number'>
                    <Text mt='-0.3rem' fontSize='0.8rem'>
                      {signUpRequestInfo.phoneNumber ?? '-'}
                    </Text>
                  </ComponentWithHeading>
                  <ComponentWithHeading heading='City name'>
                    <Text mt='-0.3rem' fontSize='0.8rem'>
                      {signUpRequestInfo.cityName}
                    </Text>
                  </ComponentWithHeading>
                  <ComponentWithHeading heading='Preferred gaming platform name'>
                    <Text mt='-0.3rem' fontSize='0.8rem'>
                      {signUpRequestInfo.preferredGamingPlatformName}
                    </Text>
                  </ComponentWithHeading>
                  <ComponentWithHeading heading='Preferred gaming duration name'>
                    <Text mt='-0.3rem' fontSize='0.8rem'>
                      {signUpRequestInfo.preferredGameDurationName}
                    </Text>
                  </ComponentWithHeading>
                  <ComponentWithHeading heading='Intended tournament name'>
                    <Text mt='-0.3rem' fontSize='0.8rem'>
                      {signUpRequestInfo.intendedTournamentName}
                    </Text>
                  </ComponentWithHeading>
                  <ComponentWithHeading heading='Comment'>
                    <Text mt='-0.3rem' fontSize='0.8rem'>
                      {signUpRequestInfo.comment ?? '-'}
                    </Text>
                  </ComponentWithHeading>
                  <ComponentWithHeading heading='Submitted on'>
                    <Text mt='-0.3rem' fontSize='0.8rem'>
                      {new Date(
                        signUpRequestInfo.submittedOn
                      ).toLocaleDateString()}
                    </Text>
                  </ComponentWithHeading>
                </>
              ) : (
                <>
                  {[...Array(13)].map((_value, index) => (
                    <SkeletonText
                      noOfLines={2}
                      w='20'
                      mb={2}
                      skeletonHeight='0.7rem'
                      key={index}
                    />
                  ))}
                </>
              )}
            </Grid>
            {signUpRequestInfo &&
              signUpRequestInfo.status === SignUpRequestStatus.Pending && (
                <ButtonGroup mt='2rem'>
                  <Button
                    size='sm'
                    colorScheme='green'
                    onClick={() =>
                      handleSignUpRequest(SignUpRequestStatus.Accepted)
                    }
                  >
                    Accept
                  </Button>
                  <Button
                    size='sm'
                    colorScheme='red'
                    onClick={() =>
                      handleSignUpRequest(SignUpRequestStatus.Rejected)
                    }
                  >
                    Reject
                  </Button>
                </ButtonGroup>
              )}
          </Box>
        </Box>
      </Container>
      <>{loader}</>
    </>
  );
};

export default SignUpRequestInfoView;
