import { UnlockIcon } from '@chakra-ui/icons';
import { Button, Text } from '@chakra-ui/react';
import { useState } from 'react';

type MaskProps = {
  name: string;
  getValue: () => Promise<string>;
};

const Mask = ({ name, getValue }: MaskProps) => {
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  const showValue = async () => {
    try {
      setLoading(true);
      const val = await getValue();
      setValue(val ?? '-');
    } catch (error) {
      setValue('-');
    } finally {
      setLoading(false);
    }
  };

  return value ? (
    <Text mt='-0.3rem' fontSize='0.8rem'>
      {value}
    </Text>
  ) : (
    <Button
      isLoading={isLoading}
      loadingText='Loading...'
      size='xs'
      leftIcon={<UnlockIcon />}
      width='fit-content'
      variant='outline'
      onClick={() => showValue()}
    >
      Show {name}
    </Button>
  );
};

export default Mask;
