import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks/hooks';
import { selectUserLogged } from '../../store/userSlice';
import { ROUTES } from '../../constants/constants';

interface RouteGuardProps {
  element: JSX.Element;
}

const UnauthorizedRouteGuard = ({ element }: RouteGuardProps) => {
  const userLoggedIn = useAppSelector(selectUserLogged);
  const { state } = useLocation();

  if (userLoggedIn) {
    return (
      <Navigate to={state ? state.redirectTo : ROUTES.HOME} replace></Navigate>
    );
  }

  return element;
};

export default UnauthorizedRouteGuard;
