import { useEffect, useState } from 'react';
import GameInfoShort from '../models/game/gameInfoShort';
import GamesList from '../components/GamesList';
import { useParams, useSearchParams } from 'react-router-dom';
import { getGames } from '../services/gameService';
import { SEARCH_PARAMS } from '../constants';
import Pagination from '../components/pagination/Pagination';
import { Helmet } from 'react-helmet';
import { Container } from '@chakra-ui/react';
import styleConstants from '../constants/styleConstants';
import GameStatus from '../enums/gameStatus';

const PlayerScheduleView = () => {
  const [games, setGames] = useState<GameInfoShort[] | null>(null);
  const [totalPages, setTotalPages] = useState(0);

  const { id } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      searchParams.set(SEARCH_PARAMS.PLAYER, id!);
      searchParams.set(SEARCH_PARAMS.STATUS, GameStatus.Unplayed);

      const result = await getGames(searchParams);
      setGames(result.items);
      setTotalPages(result.totalPages);
    })();
  }, [id, searchParams]);

  return (
    <>
      <Helmet>
        <title>Schedule</title>
      </Helmet>
      <Container
        maxW='container.lg'
        my={{ base: styleConstants.viewMobileMY, sm: styleConstants.viewMY }}
        paddingX={{ base: 2, lg: 4 }}
        paddingY={{ base: 0, lg: 4 }}
        borderWidth={{ base: 0, lg: 1 }}
        borderRadius={8}
        boxShadow={{ base: 'none', lg: 'lg' }}
      >
        <GamesList games={games} />
      </Container>
      <Pagination totalPages={totalPages} />
    </>
  );
};

export default PlayerScheduleView;
