import {
  Box,
  Button,
  ButtonGroup, ChakraProps,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from '@chakra-ui/icons';
import './style.css';
import DateInput from '../dateInput/DateInput';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../../constants';
import { formatDateString, isDateValid } from '../../utils/dateUtils';

interface DateFilterProperties extends ChakraProps {
  title?: string;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  onFilterApplied?: () => void;
}

const DateFilter = ({
  title,
  showMonthDropdown,
  showYearDropdown,
  onFilterApplied,
  ...props
  }: DateFilterProperties) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const dateFromInitialValue = useMemo(() => {
    const searchParamsValue = searchParams.get(SEARCH_PARAMS.DATE_FROM);
    return isDateValid(searchParamsValue);
  }, [searchParams]);

  const dateToInitialValue = useMemo(() => {
    const searchParamsValue = searchParams.get(SEARCH_PARAMS.DATE_TO);
    return isDateValid(searchParamsValue);
  }, [searchParams]);
  const dateFromSelected = !!dateFromInitialValue;
  const dateToSelected = !!dateToInitialValue;
  const [dateFrom, setDateFrom] = useState<Date | null>(dateFromInitialValue);
  const [dateTo, setDateTo] = useState<Date | null>(dateToInitialValue);
  const toggleMenu = () => {
    setOpen(!open);
  };

  const resetFilter = () => {
    if (onFilterApplied) {
      onFilterApplied();
    }
    setOpen(false);
    setDateFrom(null);
    setDateTo(null);
    searchParams.delete(SEARCH_PARAMS.DATE_FROM);
    searchParams.delete(SEARCH_PARAMS.DATE_TO);
    setSearchParams(searchParams);
  };

  const getIcon = () => {
    if (dateFromSelected || dateToSelected) {
      return <CloseIcon height='0.8rem' />;
    }

    return open ? <ChevronUpIcon /> : <ChevronDownIcon />;
  };

  const handleIconClick = () => {
    if (dateFromSelected || dateToSelected) {
      resetFilter();
    } else {
      toggleMenu();
    }
  };

  const applyFilter = () => {
    if (onFilterApplied) {
      onFilterApplied();
    }

    if (dateFrom) {
      searchParams.set(
        SEARCH_PARAMS.DATE_FROM,
        formatDateString(dateFrom.toString()),
      );
    } else {
      searchParams.delete(SEARCH_PARAMS.DATE_FROM);
    }

    if (dateTo) {
      searchParams.set(
        SEARCH_PARAMS.DATE_TO,
        formatDateString(dateTo.toString()),
      );
    } else {
      searchParams.delete(SEARCH_PARAMS.DATE_TO);
    }

    setSearchParams(searchParams);
    toggleMenu();
  };

  return (
    <Menu isOpen={open}>
      <ButtonGroup isAttached>
        <MenuButton
          as={Button}
          variant={dateFromSelected || dateToSelected ? 'solid' : 'outline'}
          onClick={toggleMenu}
          {...props}
        >
          <HStack>
            <span>By {title ?? 'date'}</span>
            {dateFromSelected && (
              <Box bg='orange.300' p='0.25rem' borderRadius='0.25rem'>
                From
              </Box>
            )}
            {dateToSelected && (
              <Box bg='orange.300' p='0.25rem' borderRadius='0.25rem'>
                To
              </Box>
            )}
          </HStack>
        </MenuButton>
        <IconButton
          onClick={() => handleIconClick()}
          icon={getIcon()}
          variant='outline'
          aria-label='menu toggle/reset'
        />
      </ButtonGroup>
      <MenuList
        maxW={'container.lg'}
        p='1rem'
        width={{ base: '22.5rem', md: '25rem' }}
      >
        <Flex justify='space-between' align='center' gap='0.5rem'>
          <Box>
            <Text textAlign='center' fontSize='1rem'>
              From
            </Text>
            <DateInput
              onChange={setDateFrom}
              isClearable
              value={dateFrom}
              maxDate={dateTo ? dateTo : new Date()}
              showMonthDropdown={showMonthDropdown}
              showYearDropdown={showYearDropdown}
              placeholder='Date from'
            />
          </Box>
          <Box>
            <Text textAlign='center' fontSize='1rem'>
              To
            </Text>
            <DateInput
              onChange={setDateTo}
              isClearable
              maxDate={new Date()}
              minDate={dateFrom ? dateFrom : undefined}
              value={dateTo}
              showMonthDropdown
              showYearDropdown
              placeholder='Date to'
            />
          </Box>
        </Flex>
        <MenuDivider />
        <Box>
          <Button width='100%' onClick={applyFilter}>
            Apply
          </Button>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default DateFilter;
