import { FC, useState } from 'react';
import {
  Flex,
  Link,
  Button,
  useColorMode,
  IconButton,
  Box,
  Divider
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Link as ReachLink } from 'react-router-dom';

import { ROUTES } from '../constants';
import { useAppSelector } from '../hooks/hooks';
import { selectUserLogged } from '../store/userSlice';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import UserMenu from './UserMenu';

type NavItemProperties = {
  children: any;
  to: string;
  queryString?: string;
  onClick?: () => void;
};

const NavItem: FC<NavItemProperties> = ({
  children,
  to = '/',
  queryString,
  onClick
}): JSX.Element => {
  return (
    <Link
      as={ReachLink}
      to={{ pathname: to, search: queryString }}
      style={{ textDecoration: 'none' }}
    >
      <Button variant='ghost' onClick={onClick}>
        {children}
      </Button>
    </Link>
  );
};

export const Header = () => {
  const { colorMode } = useColorMode();

  const isUserLoggedIn = useAppSelector(selectUserLogged);

  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <Flex
      bg={colorMode === 'dark' ? 'gray.600' : 'gray.200'}
      justifyContent='space-between'
      alignItems='center'
      wrap='wrap'
      p='.5rem 10%'
    >
      <ColorModeSwitcher display={{ base: 'none', lg: 'flex' }} />
      <IconButton
        aria-label='hamburger'
        size='lg'
        icon={navbarOpen ? <CloseIcon height='0.8rem' /> : <HamburgerIcon />}
        display={{ lg: 'none' }}
        variant='ghost'
        onClick={() => setNavbarOpen(!navbarOpen)}
      />
      {isUserLoggedIn && <UserMenu display={{ base: 'inline', lg: 'none' }} />}
      <Box
        flexBasis={{ base: '100%', lg: 'auto' }}
        display={{ base: navbarOpen ? 'block' : 'none', lg: 'block' }}
      >
        <Divider
          display={{ base: navbarOpen ? 'block' : 'none', lg: 'none' }}
          m='0.3rem 0'
          borderColor='gray.400'
        ></Divider>
        <Flex
          gap={{ base: '0.2rem', lg: '0.5rem', xl: '1rem' }}
          direction={{ base: 'column', lg: 'row' }}
        >
          <Box display={{ base: 'flex', lg: 'none' }}>
            <ColorModeSwitcher />
          </Box>
          <NavItem to={ROUTES.HOME} onClick={() => setNavbarOpen(false)}>
            Home
          </NavItem>
          <NavItem to={ROUTES.TOURNAMENTS} onClick={() => setNavbarOpen(false)}>
            Tournaments
          </NavItem>
          <NavItem
            to={ROUTES.RATING}
            queryString='?page=1'
            onClick={() => setNavbarOpen(false)}
          >
            Rating
          </NavItem>
          <NavItem
            to={ROUTES.GAME_RESULTS}
            queryString='?page=1'
            onClick={() => setNavbarOpen(false)}
          >
            Game results
          </NavItem>
          <NavItem
            to={ROUTES.HALL_OF_FAME_BY_PLAYER}
            onClick={() => setNavbarOpen(false)}
          >
            Hall of Fame
          </NavItem>
          <NavItem to={ROUTES.STATISTICS} onClick={() => setNavbarOpen(false)}>
            Stats
          </NavItem>
          <NavItem to={ROUTES.POLL_VIEW} onClick={() => setNavbarOpen(false)}>
            Polls
          </NavItem>
          <NavItem
            to={ROUTES.SUBMIT_GAME_RESULT}
            onClick={() => setNavbarOpen(false)}
          >
            Submit form
          </NavItem>
          <Link
            isExternal
            href='https://buymeacoffee.com/jurigolomako'
            textDecoration='none'
          >
            <Button variant='ghost'>Support dev</Button>
          </Link>
          {isUserLoggedIn ? (
            <UserMenu display={{ base: 'none', lg: 'inline' }} />
          ) : (
            <>
              <NavItem to={ROUTES.LOGIN} onClick={() => setNavbarOpen(false)}>
                Log in
              </NavItem>
            </>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
