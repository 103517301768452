import GameResultInfo from '../models/gameResult/gameResultInfo';
import GameResultInfoShort from '../models/gameResult/gameResultInfoShort';
import PagedResult from '../models/utils/pagedResult';
import GameResult from '../models/gameResult/gameResult';
import httpClient from './httpClient';
import EditGameResult from '../models/gameResult/editGameResult';
import SubmitGameResult from '../models/gameResult/submitGameResult';

export async function getGameResultInfo(id: number): Promise<GameResultInfo> {
  const url = `/gameResult/${id}/info`;

  const response = await httpClient.get(url);

  return response.data;
}

export async function getGameResult(id: number): Promise<GameResult> {
  const url = `/gameResult/${id}`;

  const response = await httpClient.get(url);

  return response.data;
}

export async function submitGameResult(
  request: SubmitGameResult
): Promise<void> {
  const url = '/gameResult';

  await httpClient.post(url, request);
}

export async function getGameResults(
  params: URLSearchParams
): Promise<PagedResult<GameResultInfoShort>> {
  const url = `/gameResult`;

  const response = await httpClient.get(url, { params });

  return response?.data;
}

export async function deleteGameResult(id: number): Promise<void> {
  const url = `/gameResult/${id}`;

  await httpClient.delete(url);
}

export async function editGameResult(request: EditGameResult): Promise<void> {
  const url = '/gameResult';

  await httpClient.patch(url, request);
}
