import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Standings from '../../models/stage/standings';
import { getStandings } from '../../services/tournamentService';
import Bracket from '../../components/brackets/Bracket';

const TournamentStageStandingsView = () => {
  const { id, stageId } = useParams();

  const [standings, setStandings] = useState<Standings>();

  useEffect(() => {
    const fetchStandings = async () => {
      const standings = await getStandings(+id!, +stageId!);
      setStandings(standings);
    };

    fetchStandings();
  }, [id, stageId]);

  return <Bracket standings={standings} />;
};

export default TournamentStageStandingsView;
