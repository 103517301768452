import {
  Flex,
  Grid,
  HStack,
  IconButton,
  Text,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react';
import TournamentParticipant from '../../models/tournament/tournamentParticipant';
import Flag from '../Flag';
import { VscKebabVertical } from 'react-icons/vsc';
import { replaceUrlParams } from '../../utils/routerUtils';
import { ROUTES } from '../../constants';
import { Link } from 'react-router-dom';
import PermissionGuard from '../guards/PermissionGuard';
import { PERMISSIONS } from '../../constants/permissions';
import ScopeLevel from '../../models/authentication/scopeLevel';
import { DeleteIcon } from '@chakra-ui/icons';
import ComponentWithHeading from '../ComponentWithHeading';

interface WaitingPlayerCardProps {
  tournamentId: number;
  tournamentParticipant: TournamentParticipant;
  onParticipantRemoved: (id: number) => void;
}

const WaitingPlayerCard = ({
  tournamentId,
  tournamentParticipant,
  onParticipantRemoved
}: WaitingPlayerCardProps) => {
  const { id, rating, firstName, lastName, countryId, registeredAt } =
    tournamentParticipant;

  return (
    tournamentParticipant && (
      <Grid
        templateColumns='1fr min-content 1fr min-content'
        gap='1rem'
        py={1}
        px={2}
        borderWidth={1}
        borderRadius={8}
        boxShadow='lg'
      >
        <ComponentWithHeading heading='Player:'>
          <HStack height='100%'>
            <Flag code={countryId} size='sm' />
            <ChakraLink
              as={Link}
              to={replaceUrlParams(ROUTES.PLAYER_PROFILE, {
                id: tournamentParticipant.id
              })}
            >
              <Text lineHeight='4'>
                {firstName} {lastName}
              </Text>
            </ChakraLink>
          </HStack>
        </ComponentWithHeading>

        <ComponentWithHeading heading='Rating:'>
          <Text fontWeight='bold'>{rating}</Text>
        </ComponentWithHeading>

        <ComponentWithHeading heading='Registered at:'>
          <Text
            fontSize='0.9rem'
            lineHeight='4'
            height='100%'
            alignContent='center'
          >
            {new Date(registeredAt).toLocaleDateString()}{' '}
            {new Date(registeredAt).toLocaleTimeString()}
          </Text>
        </ComponentWithHeading>

        <PermissionGuard
          requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
          requiredScope={ScopeLevel.Tournament}
          requiredResourceId={tournamentId}
        >
          <Menu>
            <Flex alignItems='center'>
              <MenuButton
                as={IconButton}
                aria-label='admin menu'
                variant='ghost'
                icon={<VscKebabVertical />}
              ></MenuButton>
              <MenuList>
                <MenuItem
                  icon={<DeleteIcon />}
                  onClick={() => onParticipantRemoved(id)}
                >
                  Remove from tournament
                </MenuItem>
              </MenuList>
            </Flex>
          </Menu>
        </PermissionGuard>
      </Grid>
    )
  );
};

export default WaitingPlayerCard;
