import TournamentDefinitionSelectType from '../enums/tournamentDefinitionSelectType';
import TournamentParticipantStatus from '../enums/tournamentParticipantStatus';
import TournamentSelectTypeName from '../enums/tournamentSelectTypeName';
import TournamentSelectType from '../enums/tournamentsSelectType';
import SelectOption from '../models/utils/selectOption';
import httpClient from './httpClient';

export async function getSelectOptions(lookupType: string) {
  const url = `/lookup`;
  const response = await httpClient.get<SelectOption[]>(url, {
    params: { lookupType }
  });
  return response.data;
}

export async function getTournamentParticipants(
  tournamentId: number,
  withStatus: TournamentParticipantStatus[]
): Promise<SelectOption[]> {
  const url = `/lookup/tournamentParticipants`;

  const params = new URLSearchParams();
  params.append('tournamentId', tournamentId.toString());
  withStatus.forEach(s => params.append('withStatus', s));

  const response = await httpClient.get(url, { params });
  return response.data;
}

export async function getTournaments(
  selectType: TournamentSelectType,
  name?: TournamentSelectTypeName
): Promise<SelectOption[]> {
  const url = '/lookup/tournaments';
  const response = await httpClient.get(url, { params: { selectType, name } });

  return response.data;
}

export async function getCities(
  search: string = '',
  forPlayer: number | null = null
): Promise<SelectOption[]> {
  const url = '/lookup/city';
  const response = await httpClient.get(url, { params: { search, forPlayer } });

  return response.data;
}

export async function getTournamentDefinitions(
  selectType: TournamentDefinitionSelectType
): Promise<SelectOption[]> {
  const url = '/lookup/tournamentDefinition';
  const response = await httpClient.get(url, { params: { selectType } });
  return response.data;
}
