import {
  components,
  CSSObjectWithLabel,
  GroupBase,
  LoadingIndicatorProps,
  MenuListProps,
  OptionProps,
  Props,
  PropsValue
} from 'react-select';
import AsyncSelect from 'react-select/async';
import SelectOption from '../../models/utils/selectOption';
import { Spinner, useColorMode } from '@chakra-ui/react';
import { useCallback, useRef } from 'react';
import { ViewportList } from 'react-viewport-list';
import { AsyncAdditionalProps } from 'react-select/dist/declarations/src/useAsync';
import _ from 'lodash';

type AsyncSelectInputProps<IsMulti extends boolean> = {
  isInvalid?: boolean;
  onChange: (value: string | number | (string | number)[]) => void;
  value: IsMulti extends false ? string | number : (string | number)[];
  getOptions: (inputValue: string) => Promise<SelectOption[]>;
} & Omit<
  Props<SelectOption, IsMulti, GroupBase<SelectOption>>,
  'onChange' | 'value' | 'styles' | 'getOptionLabel' | 'getOptionValue'
> &
  Omit<
    AsyncAdditionalProps<SelectOption, GroupBase<SelectOption>>,
    'loadOptions'
  >;

const MenuList = (props: MenuListProps<SelectOption>) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <components.MenuList {...props}>
      <ViewportList viewportRef={ref} items={[props.children]}>
        {item => item}
      </ViewportList>
    </components.MenuList>
  );
};

const Option = (props: OptionProps<SelectOption>) => {
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;
  return <components.Option {...props}></components.Option>;
};

const LoadingIndicator = (props: LoadingIndicatorProps<SelectOption>) => {
  return <Spinner size='sm' m='0.5rem' />;
};

const AsyncSelectInput = <IsMulti extends boolean = false>(
  props: AsyncSelectInputProps<IsMulti>
) => {
  const { colorMode } = useColorMode();

  const value = props.isMulti
    ? (props?.defaultOptions as SelectOption[])?.filter(o =>
        ((props?.value as (string | number)[]) || []).includes(
          (o as SelectOption).id
        )
      )
    : (props?.defaultOptions as SelectOption[])?.find(
        o => (o as SelectOption).id === props.value
      );

  const getDebouncedOptions = useCallback(
    _.debounce(
      (search: string, callback: (options: SelectOption[]) => void) => {
        props.getOptions(search).then(o => {
          callback(o);
        });
      },
      500
    ),
    []
  );

  return (
    <AsyncSelect
      {...props}
      loadOptions={getDebouncedOptions}
      getOptionLabel={o => o.value}
      getOptionValue={o => o.id.toString()}
      value={value as PropsValue<SelectOption>}
      onChange={value => {
        const newValue = props.isMulti
          ? (value as SelectOption[]).map(o => o.id)
          : (value as SelectOption).id;
        props.onChange(newValue);
      }}
      components={{ MenuList, Option, LoadingIndicator }}
      styles={{
        control: base =>
          ({
            ...base,
            borderColor: props.isInvalid
              ? colorMode === 'light'
                ? '#E53E3E'
                : '#FC8181'
              : 'var(--chakra-colors-chakra-border-color)',
            boxShadow: props.isInvalid
              ? colorMode === 'light'
                ? '0 0 0 1px #E53E3E'
                : '0 0 0 1px #FC8181'
              : '',
            marginBottom: '3px',
            height: 'var(--chakra-sizes-10)',
            fontFamily: 'var(--chakra-fonts-body)',
            fontSize: 'var(--chakra-fontSizes-md)',
            color: 'var(--chakra-colors-chakra-body-text)',
            background: 'var(--chakra-colors-chakra-body-bg)',
            borderRadius: 'var(--chakra-radii-md)',
            ':hover': props.isInvalid
              ? {
                  borderColor: colorMode === 'light' ? '#E53E3E' : '#FC8181',
                  boxShadow:
                    colorMode === 'light'
                      ? '0 0 0 1px #E53E3E'
                      : '0 0 0 1px #FC8181'
                }
              : {
                  borderColor:
                    colorMode === 'light'
                      ? 'var(--chakra-colors-gray-300)'
                      : 'var(--chakra-colors-whiteAlpha-400)'
                },
            paddingInlineStart: 'var(--chakra-space-2)'
          } as CSSObjectWithLabel),
        indicatorSeparator: base =>
          ({
            ...base,
            color: 'currentcolor'
          } as CSSObjectWithLabel),
        dropdownIndicator: base =>
          ({
            ...base,
            color: 'currentcolor'
          } as CSSObjectWithLabel),
        clearIndicator: base =>
          ({
            ...base,
            color: 'currentcolor'
          } as CSSObjectWithLabel),
        menu: base =>
          ({
            ...base,
            zIndex: 3,
            marginTop: '0'
          } as CSSObjectWithLabel),
        menuList: base =>
          ({
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
            background:
              colorMode === 'light' ? '' : 'var(--chakra-colors-gray-700)'
          } as CSSObjectWithLabel),
        option: (base, state) =>
          ({
            ...base,
            paddingLeft: 'var(--chakra-space-5)',
            fontSize: 'var(--chakra-fontSizes-md)',
            paddingTop: 0,
            paddingBottom: 0,

            background:
              state.isFocused && !state.isSelected ? 'transparent' : '',

            ':hover': {
              background: !state.isSelected
                ? 'var(--chakra-colors-messenger-200)'
                : ''
            }
          } as CSSObjectWithLabel),
        placeholder: base =>
          ({
            ...base,
            color: 'inherit',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          } as CSSObjectWithLabel),
        singleValue: base =>
          ({
            ...base,
            color: 'inherit'
          } as CSSObjectWithLabel),
        input: base =>
          ({
            ...base,
            color: 'inherit'
          } as CSSObjectWithLabel),
        noOptionsMessage: base =>
          ({
            ...base,
            color: 'inherit'
          } as CSSObjectWithLabel)
      }}
    />
  );
};

export default AsyncSelectInput;
