import {
  Grid,
  GridItem,
  Badge,
  AspectRatio,
  Skeleton,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import { ResponsivePie } from '@nivo/pie';
import { useState, useEffect } from 'react';
import USAStatisticsData from '../../models/stats/usaStatisticsData';
import { getUSAStatisticsData } from '../../services/statsService';

const USAStatsTab = () => {
  const [statsData, setStatsData] = useState({} as USAStatisticsData);
  const [loaded, setLoaded] = useState(false);
  const { colorMode } = useColorMode();

  useEffect(() => {
    (async () => {
      const response = await getUSAStatisticsData();
      setStatsData(response);
      setLoaded(true);
    })();
  }, []);

  return (
    <Grid
      templateColumns={{
        base: '1fr',
        sm: 'repeat(2, 1fr)',
        lg: 'repeat(3, 1fr)',
      }}
      gap='0.2rem'
    >
      {loaded ? (
        <>
          <GridItem>
            <Text textAlign='center'>
              <Badge>Distribution by end game turn</Badge>
            </Text>
            <AspectRatio ratio={1}>
              <ResponsivePie
                data={statsData.distributionByEndGameTurn.data}
                margin={{ top: 10, bottom: 10, left: 10, right: 10 }}
                valueFormat=' >-.1%'
                innerRadius={0.4}
                padAngle={1}
                cornerRadius={5}
                colors={{ scheme: 'pastel2' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.5]] }}
                arcLabel='label'
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]],
                }}
                arcLabelsSkipAngle={15}
                enableArcLinkLabels={false}
                activeOuterRadiusOffset={5}
                theme={{
                  tooltip: {
                    container: {
                      backgroundColor:
                        colorMode === 'light' ? 'white' : 'black',
                    },
                  },
                }}
              />
            </AspectRatio>
          </GridItem>
          <GridItem>
            <Text textAlign='center'>
              <Badge>Distribution by end game type</Badge>
            </Text>
            <AspectRatio ratio={1}>
              <ResponsivePie
                data={statsData.distributionByEndGameType.data}
                margin={{ top: 10, bottom: 10, left: 10, right: 10 }}
                valueFormat=' >-.1%'
                innerRadius={0.4}
                padAngle={1}
                cornerRadius={5}
                colors={{ scheme: 'pastel2' }}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 0.5]] }}
                arcLabel='label'
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]],
                }}
                arcLabelsSkipAngle={15}
                enableArcLinkLabels={false}
                activeOuterRadiusOffset={5}
                theme={{
                  tooltip: {
                    container: {
                      backgroundColor:
                        colorMode === 'light' ? 'white' : 'black',
                    },
                  },
                }}
              />
            </AspectRatio>
          </GridItem>
        </>
      ) : (
        <>
          <AspectRatio ratio={1}>
            <Skeleton borderRadius={16}></Skeleton>
          </AspectRatio>
          <AspectRatio ratio={1}>
            <Skeleton borderRadius={16}></Skeleton>
          </AspectRatio>
        </>
      )}
    </Grid>
  );
};

export default USAStatsTab;
