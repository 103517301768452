import { Button, Card, CardBody, Flex, Text } from '@chakra-ui/react';
import StageInfoShort from '../../models/stage/stageInfoShort';
import { PiTreeStructureLight } from 'react-icons/pi';
import { PiCheckLight } from 'react-icons/pi';

type StageCardProps = {
  stage: StageInfoShort;
  handleGenerateBracket: (stageId: number) => void;
};
export const StageCard = ({ stage, handleGenerateBracket }: StageCardProps) => {
  return (
    <Card>
      <CardBody>
        <Flex flexDir='column' alignItems='center' gap='0.5rem'>
          <Text>{stage.name}</Text>
          <Button
            onClick={() => handleGenerateBracket(stage.id)}
            isDisabled={stage.isBracketGenerated}
            variant='outline'
            color={stage.isBracketGenerated ? 'green' : ''}
            leftIcon={
              stage.isBracketGenerated ? (
                <PiCheckLight />
              ) : (
                <PiTreeStructureLight />
              )
            }
          >
            {stage.isBracketGenerated
              ? 'Bracket generated'
              : 'Generate bracket'}
          </Button>
        </Flex>
      </CardBody>
    </Card>
  );
};
