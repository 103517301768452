import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useDisclosure
} from '@chakra-ui/react';
import { TbLayoutSidebarRightCollapseFilled } from 'react-icons/tb';
import { NavLink } from 'react-router-dom';
import MenuItem from '../models/utils/menuItem';

type DrawerMenuProps = {
  menuItems: MenuItem[];
};

const DrawerMenu = ({ menuItems }: DrawerMenuProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        leftIcon={<TbLayoutSidebarRightCollapseFilled />}
        variant='link'
        onClick={() => onOpen()}
        display={{ base: 'initial', sm: 'none' }}
      >
        Menu
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose} placement='left'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader py={2}>Menu</DrawerHeader>
          <DrawerBody pl={2} pt={1}>
            <Flex flexDir='column' gap='0.5rem'>
              {menuItems.map((mi, index) => (
                <NavLink key={index} to={mi.link} end>
                  {({ isActive }) => (
                    <Button
                      variant={isActive ? 'solid' : 'outline'}
                      width='100%'
                      onClick={onClose}
                    >
                      {mi.title}
                    </Button>
                  )}
                </NavLink>
              ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DrawerMenu;
