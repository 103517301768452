import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  deleteGameResult,
  getGameResultInfo
} from '../services/gameResultService';
import GameResultInfo from '../models/gameResult/gameResultInfo';
import {
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Text,
  Textarea,
  Link as ChakraLink,
  useToast
} from '@chakra-ui/react';
import Flag from '../components/Flag';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  EditIcon,
  ExternalLinkIcon
} from '@chakra-ui/icons';
import ConfirmationAlert from '../components/ConfirmationAlert';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants';
import useFullPageLoader from '../hooks/useFullPageLoader';
import BackNavigationButton from '../components/BackNavigationButton';
import { replaceUrlParams } from '../utils/routerUtils';
import { Helmet } from 'react-helmet';
import styleConstants from '../constants/styleConstants';
import PermissionGuard from '../components/guards/PermissionGuard';
import { PERMISSIONS } from '../constants/permissions';
import ScopeLevel from '../models/authentication/scopeLevel';

const GameResultInfoView = () => {
  let { id } = useParams();

  const [result, setResult] = useState({} as GameResultInfo);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const toast = useToast();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      loadGameResult(+id!);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadGameResult = async (id: number) => {
    showLoader();
    const result = await getGameResultInfo(+id!);
    hideLoader();
    setResult(result);
  };

  const handleDelete = async (triggerAction: boolean) => {
    setDeleteAlert(false);

    if (!triggerAction) return;

    try {
      showLoader();
      await deleteGameResult(+id!);

      toast({
        title: 'Changes saved',
        description: 'The game was successfully deleted',
        status: 'success'
      });

      navigate(ROUTES.GAME_RESULTS);
    } catch (error: any) {
      toast({
        title: 'Game delete failed',
        description: `${error.message}`,
        status: 'error'
      });
    }

    hideLoader();
  };

  const handleEdit = (id: number) => {
    navigate(replaceUrlParams(ROUTES.GAME_RESULT_EDIT, { id: id }));
  };

  return (
    <>
      <Helmet>
        <title>Game Result</title>
      </Helmet>
      <Container maxW='3xl' my={styleConstants.viewMY}>
        <Box p={6} borderWidth={1} borderRadius={8} boxShadow='lg'>
          <HStack justifyContent='space-between'>
            <BackNavigationButton />
            <HStack justifyContent='flex-end'>
              <PermissionGuard
                requiredPermission={PERMISSIONS.GAME_RESULT.EDIT}
                requiredScope={ScopeLevel.Tournament}
                requiredResourceId={result.tournamentId}
              >
                {result.canBeModified && (
                  <IconButton
                    bg='transparent'
                    onClick={() => handleEdit(result.id)}
                    size={'1rem'}
                    icon={<EditIcon />}
                    aria-label='Edit Button'
                  />
                )}
              </PermissionGuard>
              <PermissionGuard
                requiredPermission={PERMISSIONS.GAME_RESULT.DELETE}
                requiredScope={ScopeLevel.Tournament}
                requiredResourceId={result.tournamentId}
              >
                {result.canBeModified && (
                  <IconButton
                    bg='transparent'
                    onClick={() => setDeleteAlert(true)}
                    size={'1rem'}
                    icon={<DeleteIcon />}
                    aria-label='Delete Button'
                  />
                )}
              </PermissionGuard>
            </HStack>
          </HStack>

          <Text fontSize='0.8em' textColor={'gray.400'} textAlign='center'>
            Game #{result.id}
          </Text>
          <Grid mt={2} templateColumns='5fr 0.1fr 5fr' gap={4}>
            <GridItem
              w='100%'
              display='flex'
              flexDir={'column'}
              alignItems='flex-end'
              lineHeight='1.125rem'
            >
              <HStack>
                <Link
                  to={replaceUrlParams(ROUTES.PLAYER_PROFILE, {
                    id: result.playerBlueId
                  })}
                >
                  <Text borderBottom='2px' borderBottomColor='blue.500'>
                    {result.playerBlueName}
                  </Text>
                </Link>
                {result.id && (
                  <Flag code={result.playerBlueCountry} size='md' />
                )}
              </HStack>
              <HStack>
                <Flex alignItems={'center'}>
                  <Text color={'gray.400'} fontSize='0.8em'>
                    {result.playerBlueOldRating}
                  </Text>
                  {result.playerBlueNewRating > result.playerBlueOldRating ? (
                    <ChevronUpIcon color={'green.400'} />
                  ) : (
                    <ChevronDownIcon color={'red.400'} />
                  )}
                </Flex>
                <Text fontSize='0.8em'>{result.playerBlueNewRating}</Text>
              </HStack>
            </GridItem>
            <GridItem
              w='100%'
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <Text textAlign='center'>vs</Text>
            </GridItem>
            <GridItem w='100%' lineHeight='1.125rem'>
              <HStack>
                {result.id && <Flag code={result.playerRedCountry} size='md' />}
                <Link
                  to={replaceUrlParams(ROUTES.PLAYER_PROFILE, {
                    id: result.playerRedId
                  })}
                >
                  <Text borderBottom='2px' borderBottomColor='red.500'>
                    {result.playerRedName}
                  </Text>
                </Link>
              </HStack>
              <HStack>
                <Text fontSize='0.8em'>{result.playerRedNewRating}</Text>
                <Flex alignItems={'center'}>
                  {result.playerRedNewRating > result.playerRedOldRating ? (
                    <ChevronUpIcon color={'green.400'} />
                  ) : (
                    <ChevronDownIcon color={'red.400'} />
                  )}
                  <Text color={'gray.400'} fontSize='0.8em'>
                    {result.playerRedOldRating}
                  </Text>
                </Flex>
              </HStack>
            </GridItem>
          </Grid>
          <Grid mt={5} templateColumns='5fr 0.1fr 5fr' gap={4}>
            <GridItem>
              <Text textAlign={'right'}>Tournament:</Text>
              <Text mt={2} textAlign={'right'}>
                Identifier:
              </Text>
              <Text mt={2} textAlign={'right'}>
                Won by:
              </Text>
              <Text mt={2} textAlign={'right'}>
                In:
              </Text>
              <Text mt={2} textAlign={'right'}>
                Via:
              </Text>
              <Text mt={2} textAlign={'right'}>
                On:
              </Text>
              {result.linkToVideo && (
                <Text mt={2} textAlign={'right'}>
                  Video:
                </Text>
              )}
            </GridItem>
            <GridItem display='flex' justifyContent={'center'}>
              <Divider borderColor='gray.400' orientation='vertical'></Divider>
            </GridItem>
            <GridItem>
              <Text>{result.tournamentName}</Text>
              <Text mt={2}>{result.identifier}</Text>
              <Flex mt={2} direction='column' justifyItems={'center'}>
                <Text>{result.winningPower ? result.winningPower : 'Tie'}</Text>
              </Flex>
              <Text mt={2}>{result.gameEndTurnName ?? 'N/A'}</Text>
              <Text mt={2}>{result.gameEndTypeName ?? 'N/A'}</Text>
              <Text mt={2}>{new Date(result.date).toLocaleDateString()}</Text>
              {result.linkToVideo && (
                <Text mt={2}>
                  <ChakraLink href={result.linkToVideo} isExternal>
                    <ExternalLinkIcon mx='2px' />
                  </ChakraLink>
                </Text>
              )}
            </GridItem>
          </Grid>
        </Box>
        <PermissionGuard
          requiredPermission={PERMISSIONS.GAME_RESULT.EDIT}
          requiredScope={ScopeLevel.Tournament}
          requiredResourceId={result.tournamentId}
        >
          <Textarea
            mt={3}
            p={3}
            borderWidth={1}
            borderRadius={8}
            boxShadow='lg'
            readOnly
            value={result.whatsAppMessage}
          ></Textarea>
        </PermissionGuard>
      </Container>
      <ConfirmationAlert
        isOpen={deleteAlert}
        onClose={handleDelete}
        title='Delete game result'
        action='Delete'
        description="Are you sure, you want to delete this result? You can't undo this action afterwards and it will trigger history recalculation, which can be long and resource consuming operation"
      />
      <>{loader}</>
    </>
  );
};

export default GameResultInfoView;
