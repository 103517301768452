import { ObjectSchema, number, object, ref, string } from 'yup';
import SignUpFormValue from '../models/forms/signUpFormValue';
import {
  MESSAGES,
  getErrorMessageForLength
} from '../constants/validationMessages';

export const SignUpValidationSchema: ObjectSchema<SignUpFormValue> = object({
  firstName: string()
    .trim()
    .max(50, getErrorMessageForLength(50))
    .required(MESSAGES.REQUIRED.FIRST_NAME),
  lastName: string()
    .trim()
    .max(50, getErrorMessageForLength(50))
    .required(MESSAGES.REQUIRED.LAST_NAME),
  email: string()
    .trim()
    .email(MESSAGES.VALID.EMAIL)
    .required(MESSAGES.REQUIRED.EMAIL),
  confirmEmail: string()
    .email(MESSAGES.VALID.CONFIRM_EMAIL)
    .oneOf([ref('email')], MESSAGES.VALID.CONFIRM_EMAIL_MATCH)
    .required(MESSAGES.REQUIRED.CONFIRM_EMAIL),
  nickname: string().trim().optional().max(50, getErrorMessageForLength(50)),
  countryId: string().required(MESSAGES.REQUIRED.COUNTRY_ID),
  phoneNumber: string()
    .trim()
    .optional()
    .matches(/^\+\d{5,15}$/, MESSAGES.VALID.PHONE_NUMBER),
  cityId: number()
    .moreThan(0, MESSAGES.REQUIRED.CITY_ID)
    .required(MESSAGES.REQUIRED.CITY_ID),
  preferredGamingPlatformId: number()
    .moreThan(0, MESSAGES.REQUIRED.PREFERRED_GAMING_PLATFORM_ID)
    .required(MESSAGES.REQUIRED.PREFERRED_GAMING_PLATFORM_ID),
  preferredGameDurationId: number()
    .moreThan(0, MESSAGES.REQUIRED.PREFERRED_GAME_DURATION_ID)
    .required(MESSAGES.REQUIRED.PREFERRED_GAME_DURATION_ID),
  intendedTournamentId: number()
    .moreThan(0, MESSAGES.REQUIRED.INTENDED_TOURNAMENT_ID)
    .required(MESSAGES.REQUIRED.INTENDED_TOURNAMENT_ID),
  comment: string().optional().max(255, getErrorMessageForLength(255)),
  regionalFederationId: string().optional()
});
