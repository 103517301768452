import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import TournamentParticipantProfile from '../models/player/tournamentParticipantProfile';
import ComponentWithHeading from './ComponentWithHeading';
import { tournamentParticipantProfileValidationSchema } from '../validation/tournamentParticipantProfileValidationSchema';
import { useNavigate } from 'react-router-dom';
import { replaceUrlParams } from '../utils/routerUtils';
import { ROUTES } from '../constants';

interface PersonalDataVerificationPopupProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
  tournamentParticipantProfile: TournamentParticipantProfile;
}

const PersonalDataVerificationPopup = ({
  open,
  onClose,
  onContinue,
  tournamentParticipantProfile
}: PersonalDataVerificationPopupProps) => {
  const navigate = useNavigate();
  const canRegister = tournamentParticipantProfileValidationSchema.isValidSync(
    tournamentParticipantProfile
  );

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Please verify your data</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir='column' gap={2}>
            <ComponentWithHeading heading='Playdek'>
              <Text
                mt='-0.3rem'
                fontSize='0.8rem'
                color={tournamentParticipantProfile.nickname ? '' : 'red.300'}
              >
                {tournamentParticipantProfile.nickname ?? 'missing'}
              </Text>
            </ComponentWithHeading>
            <ComponentWithHeading heading='Phone Number'>
              <Text mt='-0.3rem' fontSize='0.8rem'>
                {tournamentParticipantProfile.phoneNumber ??
                  '(optional) Please add if you register for Grand Slam'}
              </Text>
            </ComponentWithHeading>
            <ComponentWithHeading heading='Location'>
              <Text
                mt='-0.3rem'
                fontSize='0.8rem'
                color={tournamentParticipantProfile.cityName ? '' : 'red.300'}
              >
                {tournamentParticipantProfile.cityName ?? 'missing'}
              </Text>
            </ComponentWithHeading>
            <ComponentWithHeading heading='Preferred game platform'>
              <Text
                mt='-0.3rem'
                fontSize='0.8rem'
                color={
                  tournamentParticipantProfile.preferredGamingPlatformName
                    ? ''
                    : 'red.300'
                }
              >
                {tournamentParticipantProfile.preferredGamingPlatformName ??
                  'missing'}
              </Text>
            </ComponentWithHeading>
            <ComponentWithHeading heading='Preferred game duration'>
              <Text
                mt='-0.3rem'
                fontSize='0.8rem'
                color={
                  tournamentParticipantProfile.preferredGamingPlatformName
                    ? ''
                    : 'red.300'
                }
              >
                {tournamentParticipantProfile.preferredGameDurationName ??
                  'missing'}
              </Text>
            </ComponentWithHeading>
          </Flex>
          <Divider />
          <Text lineHeight={1} mt='0.8rem'>
            {canRegister
              ? `If your data is up to date, just click 'Continue', otherwise click 'Go to Edit Profile'`
              : `Please click 'Go to Edit Profile' and add missing data`}
          </Text>
        </ModalBody>

        <ModalFooter>
          {canRegister && (
            <Button colorScheme='teal' mr={1} onClick={onContinue}>
              Continue
            </Button>
          )}
          <Button
            variant='outline'
            onClick={() =>
              navigate(
                replaceUrlParams(ROUTES.EDIT_PLAYER, {
                  id: tournamentParticipantProfile.id
                })
              )
            }
          >
            Go to Edit Profile
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PersonalDataVerificationPopup;
