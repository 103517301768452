import { object, number, string, date, ref, ObjectSchema, mixed } from 'yup';
import { MESSAGES } from '../constants/validationMessages';
import PowerSelectionMethod from '../enums/powerSelectionMethod';
import Power from '../enums/power';
import EditGameFormValue from '../models/forms/submitEditGameFormValue';

export const EditGameValidationSchema: ObjectSchema<EditGameFormValue> = object(
  {
    dueDate: date().required(MESSAGES.REQUIRED.DATE),
    identifier: string()
      .trim()
      .required(MESSAGES.REQUIRED.IDENTIFIER)
      .matches(/^(0000|[A-Z]\d{3}|[A-Z]{2}\d{3})$/, MESSAGES.VALID.IDENTIFIER),
    firstPlayerId: number()
      .required(MESSAGES.REQUIRED.PLAYER_BLUE_ID)
      .moreThan(0, MESSAGES.REQUIRED.PLAYER_BLUE_ID)
      .notOneOf([ref('secondPlayerId')], MESSAGES.REQUIRED.PLAYERS_DIFFERENT),
    secondPlayerId: number()
      .required(MESSAGES.REQUIRED.PLAYER_RED_ID)
      .moreThan(0, MESSAGES.REQUIRED.PLAYER_RED_ID)
      .notOneOf([ref('firstPlayerId')], MESSAGES.REQUIRED.PLAYERS_DIFFERENT),
    powerSelectionMethod: mixed<PowerSelectionMethod>().required(
      MESSAGES.REQUIRED.POWER_SELECTION_METHOD
    ),
    secondPlayerPower: mixed<Power>().when('powerSelectionMethod', {
      is: PowerSelectionMethod.Fixed,
      then: schema =>
        schema
          .required()
          .notOneOf(
            [ref('firstPlayerPower')],
            MESSAGES.REQUIRED.POWERS_DIFFERENT
          ),
      otherwise: schema => schema.optional().nullable().oneOf([undefined, null])
    }),
    firstPlayerPower: mixed<Power>().when('powerSelectionMethod', {
      is: PowerSelectionMethod.Fixed,
      then: schema =>
        schema
          .required()
          .notOneOf(
            [ref('secondPlayerPower')],
            MESSAGES.REQUIRED.POWERS_DIFFERENT
          ),
      otherwise: schema => schema.optional().nullable().oneOf([undefined, null])
    })
  }
);
