import GameResultInfoShort from '../models/gameResult/gameResultInfoShort';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import Flag from './Flag';
import Power from '../enums/power';

interface GameResultCardProps {
  result: GameResultInfoShort;
}

const GameResultCard = (props: GameResultCardProps) => {
  const {
    id,
    playerBlueCountry,
    playerBlueName,
    playerRedCountry,
    playerRedName,
    tournamentName,
    date,
    winningPower,
    identifier
  } = props.result;

  return (
    <Box p={2} borderWidth={1} borderRadius={8} boxShadow='lg'>
      <Flex
        justifyContent={'space-between'}
        fontSize='0.6rem'
        textColor={'gray.400'}
      >
        <Text>Game #{id}</Text>
        <Text>
          {tournamentName} : {identifier}
        </Text>
        <Text>{new Date(date).toLocaleDateString()}</Text>
      </Flex>
      <Flex>
        <HStack>
          <Flag code={playerBlueCountry} size='sm' />
          <Text fontWeight={winningPower === Power.USA ? 'bold' : 'normal'}>
            {playerBlueName}
          </Text>
        </HStack>
        <Text ml={3} mr='3'>
          vs
        </Text>
        <HStack>
          <Flag code={playerRedCountry} size='sm' />
          <Text fontWeight={winningPower === Power.USSR ? 'bold' : 'normal'}>
            {playerRedName}
          </Text>
        </HStack>
      </Flex>
    </Box>
  );
};

export default GameResultCard;
