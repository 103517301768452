import { Box, Flex, Text, useColorMode } from '@chakra-ui/react';
import { DatumValue } from '@nivo/line';

interface LinearChartTooltipProps {
  id: DatumValue;
}

const LinearChartTooltip = ({ id }: LinearChartTooltipProps) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      p='5px 9px'
      borderRadius='2px'
      boxShadow='0 1px 2px rgba(0, 0, 0, 0.25)'
      fontSize='inherit'
      bgColor={colorMode === 'light' ? 'white' : 'black'}
    >
      <Flex align='center'>
        <Text>
          Game #
          <Text as='strong'>{id.toString()}</Text>
        </Text>
      </Flex>
    </Box>
  );
};

export default LinearChartTooltip;