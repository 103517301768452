import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Textarea,
  useToast
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import useFullPageLoader from '../hooks/useFullPageLoader';
import { CreateTournamentValidationSchema } from '../validation/createTournamentValidationSchema';
import DateInput from '../components/dateInput/DateInput';
import Error from '../components/Error';
import { Field, Form, Formik } from 'formik';
import SelectInput from '../components/selectInput/SelectInput';
import SelectOption from '../models/utils/selectOption';
import {
  getSelectOptions,
  getTournamentDefinitions
} from '../services/lookupService';
import { useNavigate } from 'react-router';
import styleConstants from '../constants/styleConstants';
import tournamentDefinitionSelectType from '../enums/tournamentDefinitionSelectType';
import { addTournament } from '../services/tournamentService';
import { ROUTES } from '../constants';
import { formatDateString } from '../utils/dateUtils';
import CreateTournamentFormValue from './../models/forms/createTournamentFormValue';
import { LOOKUP_TYPES } from '../constants/lookupTypes';

const CreateTournamentView = () => {
  const initialValues: CreateTournamentFormValue = {
    tournamentDefinitionId: 0,
    edition: '',
    startDate: new Date(),
    endDate: new Date(),
    tournamentAdmins: [],
    description: '',
    linkToResults: '',
    tournamentFormatId: 0,
    standardGameDurationId: 0
  };
  const [
    tournamentDefinitionSelectOptions,
    setTournamentDefinitionSelectOptions
  ] = useState<SelectOption[]>();

  const [playersSelectOptions, setPlayersSelectOptions] =
    useState<SelectOption[]>();

  const [tournamentFormatSelectOptions, setTournamentFormatSelectOptions] =
    useState<SelectOption[]>();

  const [gameDurationSelectOptions, setGameDurationSelectOptions] =
    useState<SelectOption[]>();

  const [errorMessage, setErrorMessage] = useState('');
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    (async () => {
      const tournamentDefinitions = await getTournamentDefinitions(
        tournamentDefinitionSelectType.Creatable
      );

      const players = await getSelectOptions(LOOKUP_TYPES.PLAYERS);
      const tournamentFormats = await getSelectOptions(
        LOOKUP_TYPES.TOURNAMENT_FORMATS
      );
      const durations = await getSelectOptions(LOOKUP_TYPES.GAME_DURATIONS);

      setTournamentDefinitionSelectOptions(tournamentDefinitions);
      setTournamentFormatSelectOptions(tournamentFormats);
      setGameDurationSelectOptions(durations);
      setPlayersSelectOptions(players);
    })();
  }, []);

  const handleSubmit = async (values: CreateTournamentFormValue) => {
    showLoader();

    try {
      await addTournament({
        ...values,
        startDate: formatDateString(values.startDate),
        endDate: formatDateString(values.endDate)
      });
      hideLoader();
      toast({
        title: 'Tournament created',
        description: 'Tournament was created successfully',
        status: 'success'
      });

      navigate(ROUTES.HOME);
    } catch (error: any) {
      hideLoader();
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Create tournament</title>
      </Helmet>
      <Container maxW={'container.lg'} my={styleConstants.viewMY}>
        <Flex width='full' align='center' justifyContent='center'>
          <Box
            p={12}
            maxWidth='700px'
            borderWidth={1}
            borderRadius={8}
            boxShadow='lg'
          >
            <Box textAlign='center'>
              <Heading mb={4} size='lg'>
                Create a new tournament
              </Heading>
            </Box>
            <Box mt={4} textAlign='left'>
              <Formik
                initialValues={initialValues}
                validationSchema={CreateTournamentValidationSchema}
                onSubmit={values => handleSubmit(values)}
              >
                {(props: any) => (
                  <Form noValidate>
                    <Field name='tournamentDefinitionId'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          isInvalid={
                            form.errors?.tournamentDefinitionId &&
                            form.touched?.tournamentDefinitionId
                          }
                        >
                          <FormLabel>Select tournament type:</FormLabel>
                          <SelectInput
                            value={field.value}
                            onChange={value =>
                              form.setFieldValue(field.name, value)
                            }
                            isSearchable={false}
                            options={tournamentDefinitionSelectOptions}
                            placeholder='Choose tournament type'
                            isInvalid={
                              form.errors?.tournamentDefinitionId &&
                              form.touched?.tournamentDefinitionId
                            }
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors?.tournamentDefinitionId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='edition'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          isInvalid={
                            form.errors?.edition && form.touched?.edition
                          }
                          mt={6}
                        >
                          <FormLabel>Edition:</FormLabel>
                          <Input
                            type='text'
                            placeholder='Enter edition'
                            {...field}
                          />
                          <FormErrorMessage>
                            {form.errors?.edition}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='startDate'>
                      {({ form, field }: any) => (
                        <FormControl
                          mt={6}
                          isRequired
                          isInvalid={
                            form.errors?.startDate && form.touched?.startDate
                          }
                        >
                          <FormLabel>Start date:</FormLabel>
                          <DateInput
                            value={field.value}
                            minDate={new Date()}
                            onChange={date => {
                              form.setFieldValue(field.name, date);
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors?.startDate}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='endDate'>
                      {({ form, field }: any) => (
                        <FormControl
                          mt={6}
                          isRequired
                          isInvalid={
                            form.errors?.endDate && form.touched?.endDate
                          }
                        >
                          <FormLabel>End date:</FormLabel>
                          <DateInput
                            value={field.value}
                            minDate={form.values.startDate}
                            onChange={date =>
                              form.setFieldValue(field.name, date)
                            }
                          />
                          <FormErrorMessage>
                            {form.errors?.endDate}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='description'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          isInvalid={
                            form.errors?.description &&
                            form.touched?.description
                          }
                          mt={6}
                        >
                          <FormLabel>Description:</FormLabel>
                          <Textarea
                            type='text'
                            placeholder='Enter description'
                            {...field}
                          />
                          <FormErrorMessage>
                            {form.errors?.description}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='tournamentFormatId'>
                      {({ form, field }: any) => (
                        <FormControl
                          mt={6}
                          isRequired
                          isInvalid={
                            form.errors?.tournamentFormatId &&
                            form.touched?.tournamentFormatId
                          }
                        >
                          <FormLabel>Select tournament format:</FormLabel>
                          <SelectInput
                            value={field.value}
                            onChange={value =>
                              form.setFieldValue(field.name, value)
                            }
                            isSearchable={false}
                            options={tournamentFormatSelectOptions}
                            placeholder='Choose tournament type'
                            isInvalid={
                              form.errors?.tournamentFormatId &&
                              form.touched?.tournamentFormatId
                            }
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors?.tournamentFormatId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='standardGameDurationId'>
                      {({ form, field }: any) => (
                        <FormControl
                          mt={6}
                          isRequired
                          isInvalid={
                            form.errors?.standardGameDurationId &&
                            form.touched?.standardGameDurationId
                          }
                        >
                          <FormLabel>Select standard game duration:</FormLabel>
                          <SelectInput
                            value={field.value}
                            onChange={value =>
                              form.setFieldValue(field.name, value)
                            }
                            isSearchable={false}
                            options={gameDurationSelectOptions}
                            placeholder='Choose standard game duration'
                            isInvalid={
                              form.errors?.standardGameDurationId &&
                              form.touched?.standardGameDurationId
                            }
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors?.standardGameDurationId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='linkToResults'>
                      {({ form, field }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors?.linkToResults &&
                            form.touched?.linkToResults
                          }
                          mt={6}
                        >
                          <FormLabel>Link to results:</FormLabel>
                          <Input
                            type='text'
                            placeholder='Enter link to results'
                            {...field}
                          />
                          <FormErrorMessage>
                            {form.errors?.linkToResults}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='tournamentAdmins'>
                      {({ form, field }: any) => (
                        <FormControl
                          mt={6}
                          isRequired
                          isInvalid={
                            form.errors?.tournamentAdmins &&
                            form.touched?.tournamentAdmins
                          }
                        >
                          <FormLabel>Select tournament admin(s):</FormLabel>
                          <SelectInput
                            value={field.value}
                            onChange={value =>
                              form.setFieldValue(field.name, value)
                            }
                            isSearchable
                            isMulti
                            options={playersSelectOptions}
                            placeholder='Choose tournament admin(s)'
                            isInvalid={
                              form.errors?.tournamentAdmins &&
                              form.touched?.tournamentAdmins
                            }
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors?.tournamentAdmins}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    {errorMessage && <Error error={errorMessage}></Error>}
                    <Box textAlign={'center'}>
                      <Button
                        colorScheme='teal'
                        variant='outline'
                        width='36'
                        textAlign={'center'}
                        mt={6}
                        type='submit'
                      >
                        Submit
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Flex>
      </Container>
      <>{loader}</>
    </>
  );
};

export default CreateTournamentView;
