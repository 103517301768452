import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  Container,
  Grid,
  HStack,
  Text,
  Link as ChakraLink
} from '@chakra-ui/react';
import Flag from '../components/Flag';
import { Link } from 'react-router-dom';
import { ROUTES } from '../constants';
import BackNavigationButton from '../components/BackNavigationButton';
import { replaceUrlParams } from '../utils/routerUtils';
import { Helmet } from 'react-helmet';
import styleConstants from '../constants/styleConstants';
import { getGameInfo } from '../services/gameService';
import GameInfo from '../models/game/gameInfo';
import ComponentWithHeading from '../components/ComponentWithHeading';

const GameInfoView = () => {
  let { id } = useParams();

  const [gameInfo, setGameInfo] = useState({} as GameInfo);

  useEffect(() => {
    (async () => {
      const result = await getGameInfo(+id!);
      setGameInfo(result);
    })();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Game</title>
      </Helmet>
      <Container
        maxW='3xl'
        my={{ base: styleConstants.viewMobileMY, sm: styleConstants.viewMY }}
        paddingX={{ base: 2, sm: 6 }}
        paddingY={{ base: 0, sm: 6 }}
        borderWidth={{ base: 0, sm: 1 }}
        borderRadius={8}
        boxShadow={{ base: 'none', sm: 'lg' }}
      >
        <HStack justifyContent='space-between'>
          <BackNavigationButton />
        </HStack>
        <Grid
          templateColumns={{ base: '1fr', sm: '1fr 1fr' }}
          mt='1rem'
          gap={1}
        >
          <ComponentWithHeading heading='Tournament'>
            <ChakraLink
              as={Link}
              to={replaceUrlParams(ROUTES.TOURNAMENT_INFO, {
                id: gameInfo.tournamentId
              })}
            >
              <Text fontSize='0.8rem'>{gameInfo.tournamentName}</Text>
            </ChakraLink>
          </ComponentWithHeading>
          <ComponentWithHeading heading='Identifier'>
            <Text fontSize='0.8rem'>{gameInfo.identifier}</Text>
          </ComponentWithHeading>

          <ComponentWithHeading heading='Sides'>
            <Text fontSize='0.8rem'>{gameInfo.powerSelectionMethod}</Text>
          </ComponentWithHeading>
          <ComponentWithHeading heading='Due date'>
            <Text fontSize='0.8rem'>
              {new Date(gameInfo.dueDate).toLocaleDateString()}
            </Text>
          </ComponentWithHeading>

          <ComponentWithHeading heading='First player'>
            <HStack>
              {gameInfo.id && (
                <Flag code={gameInfo.firstPlayerCountry} size='sm' />
              )}
              <ChakraLink
                as={Link}
                to={replaceUrlParams(ROUTES.PLAYER_PROFILE, {
                  id: gameInfo.firstPlayerId
                })}
              >
                <Text fontSize='0.8rem'>{gameInfo.firstPlayerName}</Text>
              </ChakraLink>
            </HStack>
          </ComponentWithHeading>
          <ComponentWithHeading heading='Second player'>
            <HStack>
              {gameInfo.id && (
                <Flag code={gameInfo.secondPlayerCountry} size='sm' />
              )}
              <ChakraLink
                as={Link}
                to={replaceUrlParams(ROUTES.PLAYER_PROFILE, {
                  id: gameInfo.secondPlayerId
                })}
              >
                <Text fontSize='0.8rem'>{gameInfo.secondPlayerName}</Text>
              </ChakraLink>
            </HStack>
          </ComponentWithHeading>

          <ComponentWithHeading heading='First player side'>
            <Text fontSize='0.8rem'>{gameInfo.firstPlayerPower ?? '-'}</Text>
          </ComponentWithHeading>
          <ComponentWithHeading heading='Second player side'>
            <Text fontSize='0.8rem'>{gameInfo.secondPlayerPower ?? '-'}</Text>
          </ComponentWithHeading>
        </Grid>
      </Container>
    </>
  );
};

export default GameInfoView;
