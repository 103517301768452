import { Text, Grid, Link as ChakraLink, HStack } from '@chakra-ui/react';
import Flag from '../Flag';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';
import PlayerProfile from '../../models/player/playerProfile';
import { useAppSelector } from '../../hooks/hooks';
import Mask from '../Mask';
import {
  getPlayerEmail,
  getPlayerPhoneNumber
} from '../../services/playerService';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { selectUserLogged } from '../../store/userSlice';
import ComponentWithHeading from '../ComponentWithHeading';
import { replaceUrlParams } from './../../utils/routerUtils';
import { PERMISSIONS } from '../../constants/permissions';
import PermissionGuard from '../guards/PermissionGuard';
import ScopeLevel from '../../models/authentication/scopeLevel';

interface PlayerInfoProps {
  playerProfile: PlayerProfile | null;
}

const ProfileInfo = ({ playerProfile }: PlayerInfoProps) => {
  const userLoggedIn = useAppSelector(selectUserLogged);

  if (!playerProfile) return <></>;

  return (
    <>
      <Grid templateColumns={{ base: '1fr 1fr', sm: '1fr 2fr' }} gap={1}>
        <ComponentWithHeading heading='Rank'>
          <Text mt='-0.3rem' fontSize='0.8rem'>
            {playerProfile.rank ?? '-'}
          </Text>
        </ComponentWithHeading>
        <ComponentWithHeading heading='Rating'>
          <Text mt='-0.3rem' fontSize='0.8rem'>
            {playerProfile.rating ? playerProfile.rating : '-'}
          </Text>
        </ComponentWithHeading>
        <ComponentWithHeading heading='First name'>
          <Text mt='-0.3rem' fontSize='0.8rem'>
            {playerProfile.firstName ? playerProfile.firstName : '-'}
          </Text>
        </ComponentWithHeading>
        <ComponentWithHeading heading='Last name'>
          <Text mt='-0.3rem' fontSize='0.8rem'>
            {playerProfile.lastName ? playerProfile.lastName : '-'}
          </Text>
        </ComponentWithHeading>
        <ComponentWithHeading heading='Country'>
          {playerProfile.countryId ? (
            <HStack>
              <Flag code={playerProfile.countryId} size='sm' />
              <Text fontSize='0.8rem' lineHeight='3'>
                {playerProfile.countryName}
              </Text>
            </HStack>
          ) : (
            <Text mt='-0.3rem' fontSize='0.8rem'>
              -
            </Text>
          )}
        </ComponentWithHeading>
        <ComponentWithHeading heading='Regional federation'>
          {playerProfile.regionalFederationId ? (
            <HStack>
              <Flag
                code={playerProfile.regionalFederationId}
                isRegional
                size='sm'
              />
              <Text fontSize='0.8rem'>
                {playerProfile.regionalFederationName}
              </Text>
            </HStack>
          ) : (
            <Text mt='-0.3rem' fontSize='0.8rem'>
              -
            </Text>
          )}
        </ComponentWithHeading>
        <ComponentWithHeading heading='Playdek'>
          <Text mt='-0.3rem' fontSize='0.8rem'>
            {playerProfile.nickname ? playerProfile.nickname : '-'}
          </Text>
        </ComponentWithHeading>
        <ComponentWithHeading heading='Last activity date'>
          <Text mt='-0.3rem' fontSize='0.8rem'>
            {playerProfile.lastActivityDate
              ? new Date(playerProfile.lastActivityDate).toLocaleDateString()
              : '-'}
          </Text>
        </ComponentWithHeading>
        <ComponentWithHeading heading='Location'>
          <Text mt='-0.3rem' fontSize='0.8rem'>
            {playerProfile.location ?? '-'}
            {playerProfile.location && (
              <ChakraLink href={playerProfile.linkToLocation} isExternal>
                <ExternalLinkIcon ml='2px' mb='2px' />
              </ChakraLink>
            )}
          </Text>
        </ComponentWithHeading>
        <ComponentWithHeading heading='Time zone'>
          <Text mt='-0.3rem' fontSize='0.8rem'>
            {playerProfile.timeZone ?? '-'}
            {playerProfile.timeZone && (
              <ChakraLink
                as={Link}
                to={replaceUrlParams(ROUTES.PLAYER_TIME_DIFFERENCE, {
                  id: playerProfile.id
                })}
              >
                <ExternalLinkIcon ml='2px' mb='2px' />
              </ChakraLink>
            )}
          </Text>
        </ComponentWithHeading>
        <ComponentWithHeading heading='Preferred gaming platform'>
          <Text mt='-0.3rem' fontSize='0.8rem'>
            {playerProfile.preferredGamingPlatformName ?? '-'}
          </Text>
        </ComponentWithHeading>
        <ComponentWithHeading heading='Preferred game duration'>
          <Text mt='-0.3rem' fontSize='0.8rem'>
            {playerProfile.preferredGameDurationName ?? '-'}
          </Text>
        </ComponentWithHeading>
        <ComponentWithHeading heading='Email'>
          {userLoggedIn ? (
            <Mask
              key={playerProfile.id}
              name='Email'
              getValue={() => getPlayerEmail(playerProfile.id)}
            />
          ) : (
            <Text mt='-0.3rem' fontSize='0.8rem'>
              <Link
                to={ROUTES.LOGIN}
                style={{
                  fontWeight: 'bold',
                  textDecoration: 'underline'
                }}
              >
                Log in
              </Link>
              {' to view'}
            </Text>
          )}
        </ComponentWithHeading>
        <PermissionGuard
          requiredPermission={PERMISSIONS.PLAYER.VIEW_PHONE_NUMBER}
          requiredScope={ScopeLevel.Player}
          requiredResourceId={playerProfile.id}
          allowedScopes={[ScopeLevel.Tournament, ScopeLevel.System]}
        >
          <ComponentWithHeading heading='Phone number'>
            <Mask
              key={playerProfile.id}
              name='Phone number'
              getValue={() => getPlayerPhoneNumber(playerProfile.id)}
            />
          </ComponentWithHeading>
        </PermissionGuard>
      </Grid>
    </>
  );
};

export default ProfileInfo;
