import { Flex, Text, ListItem, OrderedList } from '@chakra-ui/react';

const GameImportGuide = () => {
  return (
    <Flex flexDir='column' gap='1rem'>
      <Text>
        The purpose of this tool is to enable you to import a game schedule that
        you've created in Excel into the system. It performs the same function
        as the regular form, with the key difference being that you won't have
        to manually enter 100 games. To make it work, please follow this guide:
      </Text>
      <Text>
        First, create an Excel file with 7 columns with the following headers:
      </Text>
      <OrderedList>
        <ListItem>
          <b>"FirstPlayerId"</b> - This field is mandatory and should contain a
          numerical value. Please enter the ID of the first opponent. You can
          locate the ID in the participants list exported from the tournament
          page, or by opening the player profile and finding the ID in the URL:
          https://twilight-struggle.com/player/
          <u>1099</u>
        </ListItem>
        <ListItem>
          <b>"FirstPlayerPower"</b> - This field is mandatory. If the game
          requires fixed sides, please enter either "USA" or "USSR"; otherwise,
          leave it blank
        </ListItem>
        <ListItem>
          <b>"SecondPlayerId"</b> - This field is mandatory and should contain a
          numerical value. It represents the ID of the second opponent. Refer to
          point 1 for guidance on where to find the ID
        </ListItem>
        <ListItem>
          <b>"SecondPlayerPower"</b> - This field is mandatory. If the game
          requires fixed sides, please enter either "USA" or "USSR"; otherwise,
          leave it blank.
        </ListItem>
        <ListItem>
          <b>"PowerSelectionMethod"</b> - This field is mandatory, and the
          allowed values are "Fixed", "Random," and "HigherSeed." Choose "Fixed"
          for games with fixed sides, "Random" for games with randomly assigned
          sides, and "HigherSeed" if the side must be chosen by a player with a
          higher seed. For "Fixed", both "FirstPlayerPower" and
          "SecondPlayerPower" must be filled; otherwise, they should be left
          blank
        </ListItem>
        <ListItem>
          <b>"Identifier"</b> - This field is mandatory, and accepted formats
          include 1-2 letters followed by 3 digits, for example, NK123 or N123.
          It will help your players to find a right game. If you don't want to
          use an identifier, put 0000 instead.
        </ListItem>
        <ListItem>
          <b>"DueDate"</b> - This field is mandatory and should contain a date.
          Every game must have a specified deadline. <b>Important!</b> Date
          should be in <b>MM-DD-YYYY</b> format. F.e. March 10, 2024 should be
          written as 03-10-2024 in your Excel document
        </ListItem>
      </OrderedList>
      <Text>
        You can add additional columns to the document or arrange the columns in
        any order you prefer. But ensure that <b>all seven</b> columns are
        included, even if some of them are blank.
      </Text>
      <Text>
        Once you have created your Excel file and populated it with the
        necessary data, save it as csv and proceed to the second step
      </Text>
    </Flex>
  );
};

export default GameImportGuide;
