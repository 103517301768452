import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Skeleton,
  useToast
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import SelectInput from '../components/selectInput/SelectInput';
import useFullPageLoader from '../hooks/useFullPageLoader';
import EditPlayerFormValue from '../models/forms/editPlayerFormValue';
import SelectOption from '../models/utils/selectOption';
import { getCities, getSelectOptions } from '../services/lookupService';
import { editPlayer, getPlayerEdit } from '../services/playerService';
import { PlayerEditValidationSchema } from '../validation/playerEditValidationSchema';
import Error from '../components/Error';
import styleConstants from '../constants/styleConstants';
import FORM_HELPER_MESSAGES from '../constants/formHelperMessages';
import AsyncSelectInput from '../components/selectInput/AsyncSelectInput';
import { LOOKUP_TYPES } from '../constants/lookupTypes';

const PlayerEditView = () => {
  const { id } = useParams();

  const [loaded, setLoaded] = useState(false);

  const [citiesSelectOptions, setCitiesSelectOptions] = useState<
    SelectOption[]
  >([]);

  const [gamingPlatformSelectOptions, setGamingPlatformSelectOptions] =
    useState<SelectOption[]>([]);

  const [gameDurationSelectOptions, setGameDurationSelectOptions] = useState<
    SelectOption[]
  >([]);

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const toast = useToast();

  const [errorMessage, setErrorMessage] = useState('');

  const [initialFormValue, setInitialFormValue] = useState({
    nickname: '',
    phoneNumber: '',
    cityId: 0,
    preferredGamingPlatformId: 0,
    preferredGameDurationId: 0
  } as EditPlayerFormValue);

  useEffect(() => {
    (async () => {
      const playerEdit = await getPlayerEdit(+id!);

      setInitialFormValue({
        ...playerEdit,
        nickname: playerEdit.nickname ?? '',
        phoneNumber: playerEdit.phoneNumber ?? ''
      });

      const cities = await getCities('', +id!);
      const gamingPlatforms = await getSelectOptions(
        LOOKUP_TYPES.GAMING_PLATFORMS
      );
      const gameDurations = await getSelectOptions(LOOKUP_TYPES.GAME_DURATIONS);

      setCitiesSelectOptions(cities);
      setGamingPlatformSelectOptions(gamingPlatforms);
      setGameDurationSelectOptions(gameDurations);

      setLoaded(true);
    })();
  }, [id]);

  const handleSubmit = async (formValue: EditPlayerFormValue) => {
    try {
      setErrorMessage('');
      showLoader();
      await editPlayer({ ...formValue, id: +id! });
      toast({
        title: 'Changes saved',
        description: 'Your info was updated updated',
        status: 'success'
      });
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    hideLoader();
  };

  return (
    <>
      <Helmet>
        <title>Edit Player</title>
      </Helmet>
      <Container maxW={'container.md'} my={styleConstants.viewMY}>
        <Flex width='full' align='center' justifyContent='center'>
          <Box p={12} borderWidth={1} borderRadius={8} boxShadow='lg'>
            <Box textAlign='center'>
              <Heading size='lg'>Edit your profile information</Heading>
            </Box>
            <Box mb={4} textAlign='left'>
              <Formik
                initialValues={initialFormValue}
                validationSchema={PlayerEditValidationSchema}
                onSubmit={(values, actions) => {
                  handleSubmit(values);
                }}
                enableReinitialize
              >
                {(props: FormikProps<EditPlayerFormValue>) => (
                  <Form>
                    <Field name='nickname'>
                      {({ form, field }: any) => (
                        <FormControl
                          mt='1rem'
                          isInvalid={
                            form.errors?.nickname && form.touched?.nickname
                          }
                        >
                          <FormLabel>Playdek Name:</FormLabel>
                          <Skeleton isLoaded={loaded} borderRadius={8}>
                            <Input
                              type='text'
                              placeholder='Enter Playdek Name'
                              {...field}
                            />
                          </Skeleton>
                          <FormErrorMessage>
                            {form.errors?.nickname}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='phoneNumber'>
                      {({ form, field }: any) => (
                        <FormControl
                          mt='1rem'
                          isInvalid={
                            form.errors?.phoneNumber &&
                            form.touched?.phoneNumber
                          }
                        >
                          <FormLabel>Phone number:</FormLabel>
                          <Skeleton isLoaded={loaded} borderRadius={8}>
                            <Input
                              type='text'
                              placeholder='Enter phone number (example: +123456789)'
                              {...field}
                            />
                          </Skeleton>
                          <FormErrorMessage>
                            {form.errors?.phoneNumber}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='cityId'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          mt='1rem'
                          isInvalid={
                            form.errors?.cityId && form.touched?.cityId
                          }
                        >
                          <Skeleton isLoaded={loaded} borderRadius={8}>
                            <Field name='cityId'>
                              {({ form, field }: any) => (
                                <FormControl
                                  isRequired
                                  mt='1rem'
                                  isInvalid={
                                    form.errors?.cityId && form.touched?.cityId
                                  }
                                >
                                  <FormLabel>City:</FormLabel>
                                  <FormHelperText mb='.3rem'>
                                    {FORM_HELPER_MESSAGES.CITY}
                                  </FormHelperText>
                                  <FormHelperText mb='.3rem'>
                                    {FORM_HELPER_MESSAGES.CITY_NOTE}
                                  </FormHelperText>
                                  <AsyncSelectInput
                                    value={field.value}
                                    onChange={value =>
                                      form.setFieldValue(field.name, value)
                                    }
                                    isSearchable
                                    placeholder='Choose a city'
                                    isInvalid={
                                      form.errors?.cityId &&
                                      form.touched?.cityId
                                    }
                                    onBlur={() => {
                                      form.setFieldTouched(field.name, true);
                                    }}
                                    defaultOptions={citiesSelectOptions}
                                    getOptions={s => getCities(s)}
                                  />
                                  <FormErrorMessage>
                                    {form.errors?.cityId}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                          </Skeleton>
                          <FormErrorMessage>
                            {form.errors?.cityId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='preferredGamingPlatformId'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          mt='1rem'
                          isInvalid={
                            form.errors?.preferredGamingPlatformId &&
                            form.touched?.preferredGamingPlatformId
                          }
                        >
                          <FormLabel>Preferred gaming platform:</FormLabel>
                          <Skeleton isLoaded={loaded} borderRadius={8}>
                            <SelectInput
                              value={field.value}
                              onChange={value =>
                                form.setFieldValue(field.name, value)
                              }
                              options={gamingPlatformSelectOptions}
                              isSearchable={false}
                              placeholder='Choose a gaming platform'
                              isInvalid={
                                form.errors?.preferredGamingPlatformId &&
                                form.touched?.preferredGamingPlatformId
                              }
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                            />
                          </Skeleton>
                          <FormErrorMessage>
                            {form.errors?.preferredGamingPlatformId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='preferredGameDurationId'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          mt='1rem'
                          isInvalid={
                            form.errors?.preferredGameDurationId &&
                            form.touched?.preferredGameDurationId
                          }
                        >
                          <FormLabel>Preferred game duration:</FormLabel>
                          <Skeleton isLoaded={loaded} borderRadius={8}>
                            <SelectInput
                              value={field.value}
                              onChange={value =>
                                form.setFieldValue(field.name, value)
                              }
                              options={gameDurationSelectOptions}
                              isSearchable={false}
                              placeholder='Choose game duration'
                              isInvalid={
                                form.errors?.preferredGameDurationId &&
                                form.touched?.preferredGameDurationId
                              }
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                            />
                          </Skeleton>
                          <FormErrorMessage>
                            {form.errors?.preferredGameDurationId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    {errorMessage && <Error error={errorMessage}></Error>}
                    <Box textAlign={'center'}>
                      <Button
                        colorScheme='teal'
                        variant='outline'
                        width='36'
                        textAlign={'center'}
                        mt='1rem'
                        type='submit'
                      >
                        Save
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Flex>
      </Container>
      <>{loader}</>
    </>
  );
};

export default PlayerEditView;
