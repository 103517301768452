import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Switch,
  Text,
  useToast
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import styleConstants from '../constants/styleConstants';
import useFullPageLoader from '../hooks/useFullPageLoader';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createTournamentDefinition } from '../services/tournamentDefinitionService';
import CreateTournamentDefinitionFormValue from '../models/forms/createTournamentDefinitionFormValue';
import CreateTournamentDefinitionSchema from '../validation/createTournamentDefinitionSchema';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants';
import useErrorMessage from '../hooks/useErrorMessage';

const CreateTournamentDefinitionView = () => {
  const [errorMessage, setErrorMessage, clearErrorMessage] = useErrorMessage();
  const toast = useToast();
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<CreateTournamentDefinitionFormValue>({
    defaultValues: {
      isRegistrable: true
    },
    resolver: yupResolver(CreateTournamentDefinitionSchema),
    mode: 'onTouched',
    shouldFocusError: false
  });

  const onSubmit: SubmitHandler<
    CreateTournamentDefinitionFormValue
  > = async data => {
    showLoader();

    clearErrorMessage();

    try {
      await createTournamentDefinition({ ...data });
      toast({
        title: 'Changes saved',
        description: 'New tournament definition created',
        status: 'success'
      });

      hideLoader();
      navigate(ROUTES.CREATE_TOURNAMENT);
    } catch (error: any) {
      hideLoader();
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Create tournament</title>
      </Helmet>
      <Container maxW={'container.lg'} my={styleConstants.viewMY}>
        <Flex width='full' align='center' justifyContent='center'>
          <Box
            p={12}
            maxWidth='700px'
            borderWidth={1}
            borderRadius={8}
            boxShadow='lg'
          >
            <Box textAlign='center'>
              <Heading mb={4} size='lg'>
                Create a new tournament definition
              </Heading>
            </Box>
            <Box mt={4} textAlign='left'>
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Flex flexDir='column' gap='6'>
                  <FormControl isRequired isInvalid={!!errors?.name}>
                    <FormLabel>Name:</FormLabel>
                    <Input
                      placeholder='Enter tournament name'
                      {...register('name')}
                    />
                    <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired isInvalid={!!errors?.shortName}>
                    <FormLabel>Short name:</FormLabel>
                    <Input
                      placeholder='Enter tournament short name'
                      {...register('shortName')}
                    />
                    <FormErrorMessage>
                      {errors?.shortName?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired isInvalid={!!errors?.isRegistrable}>
                    <FormLabel>Registration format</FormLabel>
                    <HStack>
                      <Switch
                        colorScheme='orange'
                        {...register('isRegistrable')}
                      />
                      <Text>
                        {watch('isRegistrable')
                          ? 'Open for everyone'
                          : 'Invitational'}
                      </Text>
                    </HStack>
                    <FormErrorMessage>
                      {errors?.isRegistrable?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                {errorMessage}
                <Box textAlign={'center'}>
                  <Button
                    colorScheme='teal'
                    variant='outline'
                    width='36'
                    textAlign={'center'}
                    mt={6}
                    type='submit'
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Flex>
      </Container>
      <>{loader}</>
    </>
  );
};

export default CreateTournamentDefinitionView;
