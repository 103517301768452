import {
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Power from '../enums/power';
import SelectOption from '../models/utils/selectOption';
import { getSelectOptions } from '../services/lookupService';
import { SubmitGameResultValidationSchema } from '../validation/submitGameResultValidationSchema';
import { useSelector } from 'react-redux';
import { selectUserId } from '../store/userSlice';
import { submitGameResult } from '../services/gameResultService';
import useFullPageLoader from '../hooks/useFullPageLoader';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants';
import DateInput from '../components/dateInput/DateInput';
import { formatDateString } from '../utils/dateUtils';
import SubmitFormValue from '../models/forms/submitFormValue';
import SelectInput from '../components/selectInput/SelectInput';
import { Helmet } from 'react-helmet';
import styleConstants from '../constants/styleConstants';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SubmittableGame from '../models/game/submittableGame';
import { getSubmittableGames } from '../services/gameService';
import PowerSelectionMethod from '../enums/powerSelectionMethod';
import SubmitGameResult from '../models/gameResult/submitGameResult';
import TournamentSubmissionInfo from '../models/tournament/tournamentSubmissionInfo';
import { getTournamentSubmissionInfos } from '../services/tournamentService';
import useErrorMessage from '../hooks/useErrorMessage';
import { LOOKUP_TYPES } from '../constants/lookupTypes';

const SubmitForm = () => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors }
  } = useForm<SubmitFormValue>({
    resolver: yupResolver(SubmitGameResultValidationSchema),
    mode: 'onTouched',
    shouldFocusError: false,
    defaultValues: {
      allowUnboundSubmission: false,
      powerSelectionMethod: PowerSelectionMethod.Fixed
    }
  });

  const [errorMessage, setErrorMessage, clearErrorMessage] = useErrorMessage();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [playersSelectOptions, setPlayersSelectOptions] = useState(
    new Array<SelectOption>()
  );

  const [tournaments, setTournaments] = useState(
    [] as TournamentSubmissionInfo[]
  );

  const [gameEndTurnSelectOptions, setGameEndTurnSelectOptions] = useState(
    new Array<SelectOption>()
  );

  const [gameEndTypeSelectOptions, setGameEndTypeSelectOptions] = useState(
    new Array<SelectOption>()
  );

  const [submittableGames, setSubmittableGames] = useState(
    [] as SubmittableGame[]
  );

  const playerId = useSelector(selectUserId);
  const navigate = useNavigate();

  const fetchSubmittableGames = async (tournamentId: number) => {
    const selected = tournaments.find(t => t.id === tournamentId);

    if (!selected?.allowUnboundSubmission) {
      const submittableGames = await getSubmittableGames(tournamentId);
      setSubmittableGames(submittableGames);
    } else {
      setSubmittableGames([]);
    }

    reset({
      tournamentId,
      allowUnboundSubmission: selected?.allowUnboundSubmission,
      powerSelectionMethod: PowerSelectionMethod.Fixed,
      identifier: '',
      linkToVideo: '',
      outcome: '',
      power: ''
    });
  };

  const handleGameSelected = (gameId: number) => {
    const {
      identifier,
      submittingPlayerPower,
      opponentId,
      powerSelectionMethod,
      matchId
    } = submittableGames.find(g => g.id === gameId)!!;

    reset({
      tournamentId: getValues('tournamentId'),
      allowUnboundSubmission: getValues('allowUnboundSubmission'),
      powerSelectionMethod,
      gameId,
      matchId,
      identifier,
      power: submittingPlayerPower,
      opposingPlayer: opponentId,
      outcome: '',
      linkToVideo: ''
    });
  };

  const onSubmit: SubmitHandler<SubmitFormValue> = async values => {
    let winningPower = undefined;

    switch (values.outcome) {
      case 'won':
        winningPower = values.power === Power.USA ? Power.USA : Power.USSR;
        break;
      case 'lost':
        winningPower = values.power === Power.USA ? Power.USSR : Power.USA;
        break;
    }

    const request: SubmitGameResult = {
      ...values,
      winningPower,
      playerBlueId:
        values.power === Power.USA ? playerId!! : values.opposingPlayer,
      playerRedId:
        values.power === Power.USA ? values.opposingPlayer : playerId!!,
      linkToVideo: values.linkToVideo === '' ? undefined : values.linkToVideo,
      date: formatDateString(values.date)
    };

    clearErrorMessage();
    showLoader();

    try {
      await submitGameResult(request);
      navigate(ROUTES.GAME_RESULTS);
    } catch (error: any) {
      setErrorMessage(error.message);
    }

    hideLoader();
  };

  useEffect(() => {
    (async () => {
      const players: SelectOption[] = await getSelectOptions(
        LOOKUP_TYPES.PLAYERS
      );

      const tournaments = await getTournamentSubmissionInfos();

      const gameEndTurns: SelectOption[] = await getSelectOptions(
        LOOKUP_TYPES.GAME_END_TURNS
      );

      const gameEndTypes: SelectOption[] = await getSelectOptions(
        LOOKUP_TYPES.GAME_END_TYPES
      );

      setPlayersSelectOptions(players);
      setTournaments(tournaments);
      setGameEndTurnSelectOptions(gameEndTurns);
      setGameEndTypeSelectOptions(gameEndTypes);
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>Submit game result</title>
      </Helmet>
      <Container
        maxW={'container.md'}
        my={styleConstants.viewMY}
        p={4}
        borderWidth={1}
        borderRadius={8}
        boxShadow='lg'
      >
        <Heading mb={4} size='lg' textAlign='center'>
          Submit your game result
        </Heading>

        <Text textAlign='center' mb={8}>
          Please use this form to report the result of a game. You can report
          results for games only, where you have participated at.
        </Text>

        <form
          onSubmit={handleSubmit(onSubmit, err => console.log(err, 'errors'))}
          noValidate
        >
          <Flex flexDir='column' gap='2'>
            <FormControl isRequired isInvalid={!!errors?.tournamentId}>
              <FormLabel>Choose tournament:</FormLabel>
              <Controller
                name='tournamentId'
                control={control}
                render={({ field, fieldState }) => (
                  <SelectInput
                    value={field.value}
                    onChange={value => {
                      fetchSubmittableGames(value as number);
                      field.onChange(value as number);
                    }}
                    isSearchable={false}
                    options={tournaments.map(
                      t => ({ id: t.id, value: t.name } as SelectOption)
                    )}
                    placeholder='Choose tournament'
                    isInvalid={fieldState.invalid}
                    onBlur={field.onBlur}
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.tournamentId?.message}
              </FormErrorMessage>
            </FormControl>

            {!watch('allowUnboundSubmission') && watch('tournamentId') && (
              <FormControl isRequired isInvalid={!!errors?.gameId}>
                <FormLabel>Choose game:</FormLabel>
                <Controller
                  name='gameId'
                  control={control}
                  render={({ field, fieldState }) => (
                    <SelectInput
                      value={field.value}
                      onChange={value => {
                        field.onChange(value);
                        handleGameSelected(value as number);
                      }}
                      isSearchable
                      options={submittableGames.map(
                        sg =>
                          ({
                            id: sg.id,
                            value: `${sg.identifier} ${
                              sg.submittingPlayerPower
                                ? `(${sg.submittingPlayerPower})`
                                : ''
                            } vs ${sg.opponentName}`
                          } as SelectOption)
                      )}
                      placeholder='Choose game'
                      isInvalid={fieldState.invalid}
                      onBlur={field.onBlur}
                    />
                  )}
                />
                <FormErrorMessage>{errors?.gameId?.message}</FormErrorMessage>
              </FormControl>
            )}

            <FormControl isRequired isInvalid={!!errors?.identifier}>
              <FormLabel>
                Check ID (only in leagues where it is in the schedule). In any
                other case use 0000
              </FormLabel>
              <Input
                readOnly={!watch('allowUnboundSubmission')}
                placeholder='Game identifier'
                type='text'
                {...register('identifier')}
              />
              <FormErrorMessage>{errors?.identifier?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.power}>
              <FormLabel>You played as</FormLabel>
              <Controller
                control={control}
                name='power'
                render={({ field: { onChange, name, value } }) => (
                  <RadioGroup onChange={onChange} value={value}>
                    <Stack spacing={5} direction='row'>
                      <Radio
                        name={name}
                        colorScheme='blue'
                        value={Power.USA}
                        isReadOnly={
                          !watch('allowUnboundSubmission') &&
                          (!watch('gameId') ||
                            watch('powerSelectionMethod') ===
                              PowerSelectionMethod.Fixed)
                        }
                      >
                        USA
                      </Radio>
                      <Radio
                        name={name}
                        colorScheme='red'
                        value={Power.USSR}
                        isReadOnly={
                          !watch('allowUnboundSubmission') &&
                          (!watch('gameId') ||
                            watch('powerSelectionMethod') ===
                              PowerSelectionMethod.Fixed)
                        }
                      >
                        USSR
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>{errors.power?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors?.opposingPlayer}>
              <FormLabel>Your opponent was:</FormLabel>
              <Controller
                name='opposingPlayer'
                control={control}
                render={({ field, fieldState }) => (
                  <SelectInput
                    value={field.value}
                    onChange={field.onChange}
                    isSearchable
                    options={playersSelectOptions}
                    placeholder='Choose your opponent'
                    isInvalid={fieldState.invalid}
                    onBlur={field.onBlur}
                    isDisabled={!watch('allowUnboundSubmission')}
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.opposingPlayer?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.outcome}>
              <FormLabel>You</FormLabel>
              <Controller
                control={control}
                name='outcome'
                render={({ field: { onChange, name, value } }) => (
                  <RadioGroup onChange={onChange} value={value}>
                    <Stack spacing={5} direction='row'>
                      <Radio name={name} colorScheme='gray' value='won'>
                        Won
                      </Radio>
                      <Radio name={name} colorScheme='gray' value='lost'>
                        Lost
                      </Radio>
                      <Radio name={name} colorScheme='gray' value='tied'>
                        Tied
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>{errors.outcome?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors?.gameEndTurnId}>
              <FormLabel>When did the game end?</FormLabel>
              <Controller
                name='gameEndTurnId'
                control={control}
                render={({ field, fieldState }) => (
                  <SelectInput
                    value={field.value}
                    onChange={field.onChange}
                    isSearchable
                    options={gameEndTurnSelectOptions}
                    placeholder='Choose game end turn'
                    isInvalid={fieldState.invalid}
                    onBlur={field.onBlur}
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.gameEndTurnId?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors?.gameEndTypeId}>
              <FormLabel>How did the game end?</FormLabel>
              <Controller
                name='gameEndTypeId'
                control={control}
                render={({ field, fieldState }) => (
                  <SelectInput
                    value={field.value}
                    onChange={field.onChange}
                    isSearchable
                    options={gameEndTypeSelectOptions}
                    placeholder='Choose game end type'
                    isInvalid={fieldState.invalid}
                    onBlur={field.onBlur}
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.gameEndTypeId?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors?.date}>
              <FormLabel>When did you play this game?</FormLabel>
              <Controller
                control={control}
                name='date'
                render={({ field: { onChange, value, onBlur } }) => (
                  <DateInput
                    value={value}
                    isInvalid={!!errors.date}
                    maxDate={new Date()}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
              <FormErrorMessage>{errors?.date?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors?.linkToVideo}>
              <FormLabel>Link to Video</FormLabel>
              <Input
                placeholder='Link to video'
                type='text'
                {...register('linkToVideo')}
              />
              <FormErrorMessage>
                {errors?.linkToVideo?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>
          {errorMessage}
          <Flex justifyContent='center' mt={4}>
            <Button
              colorScheme='teal'
              variant='outline'
              width='36'
              textAlign={'center'}
              type='submit'
            >
              Submit
            </Button>
          </Flex>
        </form>
      </Container>
      <>{loader}</>
    </>
  );
};

export default SubmitForm;
