import httpClient from './httpClient';
import GeneralStatisticsData from './../models/stats/generalStatisticsData';
import CompetitionsStatisticsData from '../models/stats/competitionsStatisticsData';
import USAStatisticsData from '../models/stats/usaStatisticsData';
import USSRStatisticsData from '../models/stats/ussrStatisticsData';

export async function getGeneralStatisticsData(): Promise<GeneralStatisticsData> {
    const url = '/stats/general';

    const response = await httpClient.get(url);
    return response.data;
}

export async function getCompetitionsStatisticsData(): Promise<CompetitionsStatisticsData> {
    const url = '/stats/competitions';

    const response = await httpClient.get(url);
    return response.data;
}

export async function getUSAStatisticsData(): Promise<USAStatisticsData> {
    const url = '/stats/USA';

    const response = await httpClient.get(url);
    return response.data;
}

export async function getUSSRStatisticsData(): Promise<USSRStatisticsData> {
    const url = '/stats/USSR';

    const response = await httpClient.get(url);
    return response.data;
}