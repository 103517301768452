import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  HStack
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import SelectOption from '../models/utils/selectOption';
import { editGameResult, getGameResult } from '../services/gameResultService';
import { getSelectOptions, getTournaments } from '../services/lookupService';
import Error from '../components/Error';
import useFullPageLoader from '../hooks/useFullPageLoader';
import { isEqual } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import BackNavigationButton from '../components/BackNavigationButton';
import { ROUTES } from '../constants';
import DateInput from '../components/dateInput/DateInput';
import { formatDateString } from '../utils/dateUtils';
import { replaceUrlParams } from '../utils/routerUtils';
import { Helmet } from 'react-helmet';
import { EditGameResultValidationSchema } from '../validation/editGameResultValidationSchema';
import styleConstants from '../constants/styleConstants';
import EditGameResult from '../models/gameResult/editGameResult';
import TournamentSelectType from '../enums/tournamentsSelectType';
import { LOOKUP_TYPES } from '../constants/lookupTypes';

const EditGameResultView = () => {
  const [gameEndTurnSelectOptions, setGameEndTurnSelectOptions] = useState(
    new Array<SelectOption>()
  );

  const [gameEndTypeSelectOptions, setGameEndTypeSelectOptions] = useState(
    new Array<SelectOption>()
  );

  const [tournamentsSelectOptions, setTournamentsSelectOptions] = useState(
    new Array<SelectOption>()
  );

  const [result, setResult] = useState({} as EditGameResult);

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const navigate = useNavigate();

  let { id } = useParams();

  useEffect(() => {
    (async () => {
      showLoader();

      const gameEndTurns = await getSelectOptions(LOOKUP_TYPES.GAME_END_TURNS);

      const gameEndTypes = await getSelectOptions(LOOKUP_TYPES.GAME_END_TYPES);

      const tournaments = await getTournaments(
        TournamentSelectType.ExceptUpcoming
      );

      const result = await getGameResult(+id!);

      setGameEndTurnSelectOptions(gameEndTurns);
      setGameEndTypeSelectOptions(gameEndTypes);
      setTournamentsSelectOptions(tournaments);
      setResult({
        ...result,
        linkToVideo: result.linkToVideo ? result.linkToVideo : ''
      });

      hideLoader();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (values: any) => {
    const request: EditGameResult = {
      ...values,
      date: formatDateString(values.date)
    };

    showLoader();

    try {
      await editGameResult(request);
      navigate(replaceUrlParams(ROUTES.GAME_RESULT_INFO, { id: id! }));
    } catch (error: any) {
      setErrorMessage(error.message);
    }

    hideLoader();
  };

  return (
    <>
      <Helmet>
        <title>Edit Game Result</title>
      </Helmet>
      {result.id && (
        <Container maxW={'container.lg'} my={styleConstants.viewMY}>
          <Flex width='full' align='center' justifyContent='center'>
            <Box
              p={12}
              maxWidth='700px'
              borderWidth={1}
              borderRadius={8}
              boxShadow='lg'
            >
              <HStack justifyContent='space-between'>
                <BackNavigationButton />
              </HStack>

              <Heading textAlign='center' mb={4} size='lg'>
                Edit game result
              </Heading>

              <Text mb={8}>
                In case of a mistake made during submission, for example,
                selecting the wrong participant or incorrectly identifying the
                winning power, please remove the game and let the player submit
                it again with the correct details
              </Text>

              <Box mt={4} textAlign='left'>
                <Formik
                  initialValues={result}
                  validationSchema={EditGameResultValidationSchema}
                  onSubmit={(values, actions) => handleSubmit(values)}
                >
                  {(props: any) => (
                    <Form>
                      <Field name='tournamentId'>
                        {({ form, field }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors?.tournamentId &&
                              form.touched?.tournamentId
                            }
                          >
                            <FormLabel>Select type of the game:</FormLabel>
                            <Select placeholder='Choose tournament' {...field}>
                              {tournamentsSelectOptions.map((option, index) => (
                                <option key={index} value={option.id}>
                                  {option.value}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {form.errors?.tournamentId}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='identifier'>
                        {({ form, field }: any) => (
                          <FormControl
                            mt={6}
                            isInvalid={
                              form.errors?.identifier &&
                              form.touched?.identifier
                            }
                          >
                            <FormLabel>Check ID:</FormLabel>
                            <Input
                              placeholder='Game identifier'
                              type='text'
                              {...field}
                            />
                            <FormErrorMessage>
                              {form.errors?.identifier}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='gameEndTurnId'>
                        {({ form, field }: any) => (
                          <FormControl
                            mt={6}
                            isInvalid={
                              form.errors?.gameEndTurnId &&
                              form.touched?.gameEndTurnId
                            }
                          >
                            <FormLabel>When did the game end?</FormLabel>
                            <Select
                              placeholder='Choose game end turn'
                              {...field}
                            >
                              {gameEndTurnSelectOptions.map((option, index) => (
                                <option key={index} value={option.id}>
                                  {option.value}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {form.errors?.gameEndTurnId}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='gameEndTypeId'>
                        {({ form, field }: any) => (
                          <FormControl
                            mt={6}
                            isInvalid={
                              form.errors?.gameEndTypeId &&
                              form.touched?.gameEndTypeId
                            }
                          >
                            <FormLabel>How did the game end?</FormLabel>
                            <Select
                              placeholder='Choose game end type'
                              {...field}
                            >
                              {gameEndTypeSelectOptions.map((option, index) => (
                                <option key={index} value={option.id}>
                                  {option.value}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {form.errors?.gameEndTypeId}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='date'>
                        {({ form, field }: any) => (
                          <FormControl
                            mt={6}
                            isInvalid={form.errors?.date && form.touched?.date}
                          >
                            <FormLabel>When was the game played?</FormLabel>
                            <DateInput
                              value={new Date(field.value)}
                              onChange={date =>
                                form.setFieldValue(field.name, date)
                              }
                            />
                            <FormErrorMessage>
                              {form.errors?.date}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='linkToVideo'>
                        {({ form, field }: any) => (
                          <FormControl
                            mt={6}
                            isInvalid={
                              form.errors?.linkToVideo &&
                              form.touched?.linkToVideo
                            }
                          >
                            <FormLabel>Link to Video</FormLabel>
                            <Input
                              placeholder='Link to video'
                              type='text'
                              {...field}
                            />
                            <FormErrorMessage>
                              {form.errors?.linkToVideo}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      {errorMessage && <Error error={errorMessage}></Error>}
                      <Box textAlign={'center'}>
                        <Button
                          colorScheme='teal'
                          variant='outline'
                          width='36'
                          textAlign={'center'}
                          mt={6}
                          isDisabled={isEqual(
                            props.initialValues,
                            props.values
                          )}
                          type='submit'
                        >
                          Update
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Flex>
        </Container>
      )}
      <>{loader}</>
    </>
  );
};

export default EditGameResultView;
