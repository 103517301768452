import { Flex, HStack, Text, Grid, Badge } from '@chakra-ui/react';
import Flag from './Flag';
import SignUpRequestInfoShort from '../models/signUp/signUpRequestInfoShort';
import SignUpRequestStatus from '../enums/signUpRequestStatus';

interface SignUpRequestCardProps {
  request: SignUpRequestInfoShort;
}

const SignUpRequestCard = (props: SignUpRequestCardProps) => {
  const { name, countryId, submittedOn, status } = props.request;

  const getColorSchemeForBadge = () => {
    return status === SignUpRequestStatus.Pending ? 'gray' : 'red';
  };

  return (
    <Grid
      templateColumns='2fr 1fr 1fr'
      mb='2'
      px={2}
      py={1}
      borderWidth={1}
      borderRadius={6}
      boxShadow='lg'
      gap='1rem'
    >
      <Flex flexDir='column'>
        <Text fontSize='0.6rem' color='gray.400'>
          Name:
        </Text>
        <HStack>
          <Flag code={countryId} size='sm' />
          <Text>{name}</Text>
        </HStack>
      </Flex>
      <Flex flexDir='column'>
        <Text fontSize='0.6rem' color='gray.400'>
          Submitted on:
        </Text>
        <Text>{new Date(submittedOn).toLocaleDateString()}</Text>
      </Flex>
      <Flex flexDir='column' alignItems='flex-end'>
        <Text fontSize='0.6rem' color='gray.400'>
          Status:
        </Text>
        <Text>
          <Badge marginBottom={1} colorScheme={getColorSchemeForBadge()}>
            {status}
          </Badge>
        </Text>
      </Flex>
    </Grid>
  );
};

export default SignUpRequestCard;
