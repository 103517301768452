import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Button, Flex, Grid, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import StageInfoShort from '../../models/stage/stageInfoShort';
import { generateBracket, getStages } from '../../services/tournamentService';
import { sendRequestWithToast } from '../../utils/requestUtils';
import { StageCard } from '../../components/tournament/StageCard';
import useFullPageLoader from '../../hooks/useFullPageLoader';
import SubmitTournamentWinnersPopup from '../../components/SubmitTournamentWinnersPopup';

const TournamentManagementView = () => {
  const { id } = useParams();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [stages, setStages] = useState([] as StageInfoShort[]);

  const [openSubmitWinnersPopup, setOpenSubmitWinnersPopup] = useState(false);

  useEffect(() => {
    const fetchStages = async () => {
      const stages = await getStages(+id!);
      setStages(stages);
    };

    fetchStages();
  }, [id]);

  const handleGenerateBracket = async (stageId: number) => {
    showLoader();

    const success = await sendRequestWithToast(
      generateBracket(+id!, stageId),
      'Bracket has been successfully generated'
    );

    hideLoader();

    if (success) {
      const newStages = [...stages];
      const updatedStage = newStages.find(s => s.id === stageId);
      updatedStage!.isBracketGenerated = true;

      setStages(newStages);
    }
  };

  return (
    <>
      <Helmet>
        <title>Tournament management</title>
      </Helmet>
      <Flex flexDir='column' gap='1rem'>
        <Button
          onClick={() => setOpenSubmitWinnersPopup(true)}
          width='max-content'
        >
          Submit winners
        </Button>
        <Flex flexDir='column' gap='0.5rem'>
          <Text>Stages</Text>
          <Grid
            templateColumns='repeat(auto-fit, minmax(min-content, 1fr))'
            gap='0.5rem'
          >
            {stages.map(s => (
              <StageCard
                key={s.id}
                stage={s}
                handleGenerateBracket={handleGenerateBracket}
              />
            ))}
          </Grid>
        </Flex>
      </Flex>
      {loader}
      {openSubmitWinnersPopup && (
        <SubmitTournamentWinnersPopup
          onClose={() => setOpenSubmitWinnersPopup(false)}
        />
      )}
    </>
  );
};

export default TournamentManagementView;
