import { Container, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

const ForbiddenPage = () => {
  return (
    <>
      <Helmet>
        <title>Forbidden</title>
      </Helmet>
      <Container centerContent>
        <Text fontSize='9xl' fontWeight='thin'>
          403
        </Text>
        <Text textAlign='center' fontSize='xl'>
          You aren't authorized to access this page
        </Text>
      </Container>
    </>
  );
};

export default ForbiddenPage;
