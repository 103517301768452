import Login from '../models/authentication/login';
import SetPassword from '../models/authentication/setPasssord';
import ResetPassword from '../models/authentication/resetPassword';
import httpClient from './httpClient';
import AuthenticationInfo from '../models/authentication/authenticationInfo';

export async function resetPassword(model: ResetPassword) {
  const url = '/account/password/reset';

  await httpClient.post(url, model);
}

export async function setPassword(model: SetPassword) {
  const url = '/account/password/set';

  await httpClient.post(url, model);
}

export async function getLoggedUser() {
  const url = '/account';

  const response = await httpClient.get(url);

  return response.data;
}

export async function login(model: Login): Promise<AuthenticationInfo> {
  const url = '/account/login';

  const response = await httpClient.post<AuthenticationInfo>(url, model);

  return response.data;
}

export async function logout() {
  const url = '/account/logout';

  await httpClient.post(url, {});
}
