import { Link, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import GameResultInfoShort from '../models/gameResult/gameResultInfoShort';
import { Flex, Skeleton } from '@chakra-ui/react';
import GameResultCard from './GameResultCard';
import { getGameResults } from '../services/gameResultService';
import Pagination from './pagination/Pagination';

const GameResultsList = () => {
  const [searchParams] = useSearchParams();
  const [gameResults, setGameResults] = useState<GameResultInfoShort[] | null>(
    null
  );
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    (async () => {
      const results = await getGameResults(searchParams);
      setGameResults(results.items);
      setTotalPages(results.totalPages);
    })();
  }, [searchParams]);

  return (
    <>
      <Flex
        flexDir='column'
        p={{ base: 0, sm: 5 }}
        borderWidth={{ base: 0, sm: 1 }}
        borderRadius={8}
        boxShadow={{ base: 'none', sm: 'lg' }}
        mb={6}
        gap='0.5rem'
      >
        {gameResults ? (
          gameResults.map(result => (
            <Link key={result.id} to={`${result.id}`}>
              <GameResultCard result={result} />
            </Link>
          ))
        ) : (
          <>
            {[...Array(15)].map((_value, index) => (
              <Skeleton
                h={{ base: '5rem', md: '3.5rem' }}
                key={index}
                borderRadius={8}
              />
            ))}
          </>
        )}
      </Flex>
      <Pagination totalPages={totalPages} />
    </>
  );
};

export default GameResultsList;
