import { ObjectSchema, number, object, string } from 'yup';
import AdminEditPlayerFormValue from '../models/forms/adminEditPlayerFormValue';
import { MESSAGES } from '../constants/validationMessages';

export const AdminPlayerEditValidationSchema: ObjectSchema<AdminEditPlayerFormValue> =
  object({
    firstName: string()
      .trim()
      .max(50, MESSAGES.LENGTH)
      .required(MESSAGES.REQUIRED.FIRST_NAME),
    lastName: string()
      .trim()
      .max(50, MESSAGES.LENGTH)
      .required(MESSAGES.REQUIRED.LAST_NAME),
    email: string()
      .trim()
      .email(MESSAGES.VALID.EMAIL)
      .required(MESSAGES.REQUIRED.EMAIL),
    nickname: string().trim().max(50, MESSAGES.LENGTH).optional(),
    countryId: string().required(MESSAGES.REQUIRED.COUNTRY_ID),
    phoneNumber: string()
      .trim()
      .optional()
      .matches(/^\+\d{5,15}$/, MESSAGES.VALID.PHONE_NUMBER),
    cityId: number()
      .moreThan(0, MESSAGES.REQUIRED.CITY_ID)
      .required(MESSAGES.REQUIRED.CITY_ID),
    preferredGamingPlatformId: number()
      .moreThan(0, MESSAGES.REQUIRED.PREFERRED_GAMING_PLATFORM_ID)
      .required(MESSAGES.REQUIRED.PREFERRED_GAMING_PLATFORM_ID),
    preferredGameDurationId: number()
      .moreThan(0, MESSAGES.REQUIRED.PREFERRED_GAME_DURATION_ID)
      .required(MESSAGES.REQUIRED.PREFERRED_GAME_DURATION_ID)
  });
