import { CloseIcon, Search2Icon } from '@chakra-ui/icons';
import {
  ChakraProps,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../../constants';
import './style.css';

interface SearchFilterProps extends ChakraProps {
  paramName: string;
  placeholder?: string;
  onFilterApplied?: () => void;
}

const SearchFilter = ({
  paramName,
  onFilterApplied,
  placeholder,
  ...props
}: SearchFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const param = searchParams.get(paramName);

  const [searchInput, setSearchInput] = useState(param ?? '');
  const [filterApplied, setFilterApplied] = useState(!!param);

  const handleSearchInputChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  const handleKeyDownEvent = (e: any) => {
    if (e.key !== 'Enter') {
      return;
    }

    applyFilter();
    setFilterApplied(true);
  };

  const applyFilter = () => {
    if (onFilterApplied) {
      onFilterApplied();
    }
    searchParams.set(paramName, searchInput);
    searchParams.set(SEARCH_PARAMS.PAGE, '1');

    setSearchParams(searchParams);
  };

  const resetFilter = () => {
    searchParams.delete(paramName);
    searchParams.set(SEARCH_PARAMS.PAGE, '1');

    setSearchParams(searchParams);
    setFilterApplied(false);
    setSearchInput('');
  };

  return (
    <InputGroup {...props}>
      <InputLeftElement
        children={
          <IconButton
            aria-label='search field'
            icon={<Search2Icon />}
            variant='unstyled'
            onClick={() => applyFilter()}
          />
        }
      />
      <Input
        onKeyDown={e => handleKeyDownEvent(e)}
        value={searchInput}
        onChange={e => handleSearchInputChange(e)}
        placeholder={placeholder ?? `Search by ${paramName}`}
      />
      {filterApplied && (
        <InputRightElement
          children={
            <IconButton
              variant='unstyled'
              aria-label='clear search'
              icon={<CloseIcon />}
              size='sm'
              onClick={() => resetFilter()}
            />
          }
        />
      )}
    </InputGroup>
  );
};

export default SearchFilter;
