import { ObjectSchema, date, number, object, string } from 'yup';
import EditGameResultFormValue from '../models/forms/editGameResultFormValue';
import { MESSAGES } from '../constants/validationMessages';

export const EditGameResultValidationSchema: ObjectSchema<EditGameResultFormValue> =
  object({
    tournamentId: number().required(MESSAGES.REQUIRED.TOURNAMENT_ID),
    identifier: string()
      .trim()
      .required(MESSAGES.REQUIRED.IDENTIFIER)
      .matches(/^(0000|[A-Z]\d{3}|[A-Z]{2}\d{3})$/, MESSAGES.VALID.IDENTIFIER),
    gameEndTurnId: number().required(MESSAGES.REQUIRED.GAME_END_TURN),
    gameEndTypeId: number().required(MESSAGES.REQUIRED.GAME_END_TYPE),
    date: date().required(MESSAGES.REQUIRED.DATE),
    linkToVideo: string().trim().url(MESSAGES.VALID.LINK_TO_VIDEO).optional()
  });
