export const ROUTES = {
  HOME: '/',
  SIGNUP: '/account/signup',
  LOGIN: '/account/login',
  ABOUT: '/about',
  CONTACTS: '/contact-us',
  FORBIDDEN: '/forbidden',
  NOT_FOUND: '/not-found',
  PASSWORD_RESET: '/account/password/reset',
  PASSWORD_SET: '/account/password/set',
  RATING: 'player/rating',
  PLAYER_PROFILE: '/player/:id',
  PLAYER_TIME_DIFFERENCE: '/player/:id/time',
  PLAYER_TOURNAMENTS: '/player/:id/tournament',
  PLAYER_SCHEDULE: '/player/:id/schedule',
  PLAYER_EXPORT: `${process.env.REACT_APP_SERVER_URL}/player/export`,
  SUBMIT_GAME_RESULT: '/result/submit',
  GAME_RESULTS: '/result',
  GAME_RESULT_EDIT: '/result/:id/edit',
  GAME_RESULT_INFO: '/result/:id',
  EDIT_PLAYER: '/player/:id/edit',
  ADMIN_EDIT_PLAYER: '/player/edit',
  STATISTICS: '/stats',
  SIGNUP_REQUESTS: '/account/signup/requests',
  SIGNUP_REQUEST_INFO: '/account/signup/requests/:id',
  TOURNAMENTS: '/tournament',
  TOURNAMENT_INFO: '/tournament/:id',
  TOURNAMENT_PARTICIPANTS: '/tournament/:id/participants',
  TOURNAMENT_WAITING_LIST: '/tournament/:id/waiting-list',
  TOURNAMENT_SCHEDULE: '/tournament/:id/schedule',
  TOURNAMENT_REGISTRATION: '/tournament/:id/register',
  TOURNAMENT_STANDINGS: '/tournament/:id/standings',
  TOURNAMENT_MISSING_GAMES: '/tournament/:id/missing-games',
  TOURNAMENT_STAGE_STANDINGS: '/tournament/:id/standings/:stageId',
  TOURNAMENT_EDIT: '/tournament/:id/edit',
  TOURNAMENT_MANAGEMENT: '/tournament/:id/management',
  TOURNAMENT_PARTICIPANT_EXPORT: `${process.env.REACT_APP_SERVER_URL}/tournament/:id/participants/export`,
  CREATE_TOURNAMENT: '/tournament/create',
  CREATE_TOURNAMENT_DEFINITION: '/tournamentDefinition/create',
  GAME_INFO: '/game/:id',
  GAME_IMPORT: '/tournament/:tournamentId/game/import',
  GAME_CREATE: '/tournament/:tournamentId/game/create',
  GAME_EDIT: '/tournament/:tournamentId/game/:id/edit',
  HALL_OF_FAME_BY_PLAYER: '/hall-of-fame',
  HALL_OF_FAME_BY_TOURNAMENT: '/hall-of-fame/tournaments',
  POLL_VIEW: '/polls',
  POLL_CREATE: '/polls/create'
};

export const SEARCH_PARAMS = {
  PAGE: 'page',
  COUNTRY: 'country',
  REGIONAL_FEDERATION: 'regionalFederation',
  WITH: 'with',
  PLAYER: 'player',
  TOURNAMENT: 'tournament',
  IDENTIFIER: 'identifier',
  DATE_FROM: 'datefrom',
  DATE_TO: 'dateto',
  SEARCH: 'search',
  STATUS: 'status',
  SORT_BY: 'sortby',
  SORT_ORDER: 'order'
};
