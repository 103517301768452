import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup
} from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../../constants';

type RadioFilterProps = {
  paramName: string;
  title: string;
  options: string[];
  defaultValue: string;
  onFilterApplied?: () => void;
};

const RadioFilter = ({
  paramName,
  title,
  options,
  defaultValue,
  onFilterApplied
}: RadioFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramValue = searchParams.get(paramName);

  const currentValue =
    paramValue && options.some(o => o === paramValue)
      ? paramValue
      : defaultValue;

  const applyFilter = (value: string) => {
    if (onFilterApplied) {
      onFilterApplied();
    }

    searchParams.set(SEARCH_PARAMS.PAGE, '1');
    searchParams.set(paramName, value);
    setSearchParams(searchParams);
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            variant='outline'
            rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          >
            {title}
          </MenuButton>
          <MenuList>
            <MenuOptionGroup
              value={currentValue}
              onChange={value => applyFilter(value as string)}
              type='radio'
            >
              {options.map(o => (
                <MenuItemOption value={o} key={o}>
                  {o}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default RadioFilter;
