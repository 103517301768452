import { CalendarIcon, CloseIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

interface DatePickerProps {
  value: Date | null;
  onChange(date: Date | null): void;
  onBlur?(): void;
  isInvalid?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  placeholder?: string;
}

const DateInput = ({
  value,
  isClearable,
  minDate,
  maxDate,
  showMonthDropdown,
  showYearDropdown,
  onChange,
  onBlur,
  isInvalid,
  disabled,
  placeholder
}: DatePickerProps) => {
  const CustomInput = forwardRef(({ value, onClick }: any, _ref: any) => {
    const formattedValue = value
      ? new Date(value).toLocaleDateString()
      : undefined;

    return (
      <InputGroup>
        <Input
          onClick={onClick}
          value={formattedValue}
          placeholder={placeholder ? placeholder : 'Select a date'}
          isReadOnly
          isInvalid={isInvalid}
          disabled={disabled}
        />
        <InputLeftElement>
          <CalendarIcon />
        </InputLeftElement>
        <InputRightElement>
          {!!formattedValue && isClearable && (
            <IconButton
              variant='unstyled'
              aria-label='clear date'
              icon={<CloseIcon />}
              size='xs'
              onClick={() => onChange(null)}
            />
          )}
        </InputRightElement>
      </InputGroup>
    );
  });

  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      dropdownMode='select'
      onCalendarClose={onBlur}
      customInput={<CustomInput />}
      onBlur={onBlur}
    />
  );
};

export default DateInput;
