import Chart from '../../models/chart/chart';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { AspectRatio, Flex, Text, useColorMode } from '@chakra-ui/react';
import RadialBarChartItem from '../../models/chart/radialBarChartItem';
import React from 'react';

interface WinRateRadialBarProps {
  winRateChart: Chart<RadialBarChartItem> | null;
}

const WinRateChart = ({ winRateChart }: WinRateRadialBarProps) => {
  const { colorMode } = useColorMode();
  return (
    winRateChart ? (
      <AspectRatio ratio={1}>
        <ResponsiveRadialBar
          data={winRateChart.data}
          maxValue={1}
          valueFormat='>-.0%'
          padAngle={4}
          cornerRadius={2}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          colors={{ scheme: 'pastel2' }}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1]]
          }}
          theme={{
            textColor: '#A0AEC0',
            tooltip: {
              container: {
                backgroundColor: colorMode === 'light' ? 'white' : 'black'
              }
            }
          }}
          enableTracks={false}
          enableRadialGrid={false}
          radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
          circularAxisOuter={null}
          legends={[]}
        />
      </AspectRatio>
    ) : (
      <Flex justifyContent='center' alignItems='center'>
        <Text>Zero games played. You need to play at least one game to see win
          rate chart</Text>
      </Flex>
    )
  );
};

export default WinRateChart;
