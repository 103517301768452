import { Flex, Skeleton, Text } from '@chakra-ui/react';
import GameInfoShort from '../models/game/gameInfoShort';
import GameCard from './GameCard';
import { Link } from 'react-router-dom';
import { ROUTES } from '../constants';
import { replaceUrlParams } from '../utils/routerUtils';

type GamesListProps = {
  games: GameInfoShort[] | null;
};

const GamesList = ({ games }: GamesListProps) => {
  let content = null;

  if (!games) {
    content = [...Array(15)].map((_value, index) => (
      <Skeleton
        h={{ base: '5rem', sm: '3.5rem' }}
        key={index}
        borderRadius={6}
      />
    ));
  }

  if (games?.length) {
    content = games.map(game => (
      <Link
        key={game.id}
        to={replaceUrlParams(ROUTES.GAME_INFO, {
          id: game.id
        })}
      >
        <GameCard game={game} />
      </Link>
    ));
  }

  return (
    <Flex flexDir='column' gap='0.5rem'>
      {content ?? (
        <Flex justifyContent='center'>
          <Text>No schedules games available</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default GamesList;
