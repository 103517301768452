import { useState, useEffect } from 'react';
import {
  Container,
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  FormHelperText,
  Link as ChakraLink,
  useToast
} from '@chakra-ui/react';

import { Formik, FormikProps, Form, Field } from 'formik';

import { AdminPlayerEditValidationSchema } from '../validation/adminPlayerEditValidationSchema';

import SelectOption from '../models/utils/selectOption';
import { getCities, getSelectOptions } from '../services/lookupService';
import AdminPlayerEdit from '../models/player/adminPlayerEdit';
import useFullPageLoader from '../hooks/useFullPageLoader';
import Error from '../components/Error';
import {
  deletePlayer,
  editPlayerByAdmin,
  getAdminPlayerEdit
} from '../services/playerService';
import { Helmet } from 'react-helmet';
import SelectInput from '../components/selectInput/SelectInput';
import styleConstants from '../constants/styleConstants';
import FORM_HELPER_MESSAGES from '../constants/formHelperMessages';
import AsyncSelectInput from '../components/selectInput/AsyncSelectInput';
import PermissionGuard from '../components/guards/PermissionGuard';
import { PERMISSIONS } from '../constants/permissions';
import { ROUTES } from '../constants';
import { LOOKUP_TYPES } from '../constants/lookupTypes';

const AdminPlayerEditView = () => {
  const [playersSelectOptions, setPlayersSelectOptions] = useState(
    new Array<SelectOption>()
  );

  const [countriesSelectOptions, setCountriesSelectOptions] = useState(
    new Array<SelectOption>()
  );

  const [
    regionalFederationsSelectOptions,
    setRegionalFederationsSelectOptions
  ] = useState<SelectOption[]>([]);

  const [citiesSelectOptions, setCitiesSelectOptions] = useState<
    SelectOption[]
  >([]);

  const [gamingPlatformSelectOptions, setGamingPlatformSelectOptions] =
    useState<SelectOption[]>([]);

  const [gameDurationSelectOptions, setGameDurationSelectOptions] = useState<
    SelectOption[]
  >([]);

  const [selectedPlayer, setSelectedPlayer] =
    useState<AdminPlayerEdit | null>();
  const [errorMessage, setErrorMessage] = useState('');

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const toast = useToast();

  const handleSubmit = async (values: AdminPlayerEdit) => {
    showLoader();

    try {
      await editPlayerByAdmin(values);
      toast({
        title: 'Changes saved',
        description: "The player's details updated",
        status: 'success'
      });
      hideLoader();
      setErrorMessage('');
    } catch (error: any) {
      hideLoader();
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    (async () => {
      const players = await getSelectOptions(LOOKUP_TYPES.PLAYERS);
      const countries = await getSelectOptions(LOOKUP_TYPES.COUNTRIES);
      const regionalFederations = await getSelectOptions(
        LOOKUP_TYPES.REGIONAL_FEDERATIONS
      );
      const gamingPlatforms = await getSelectOptions(
        LOOKUP_TYPES.GAMING_PLATFORMS
      );
      const gameDurations = await getSelectOptions(LOOKUP_TYPES.GAME_DURATIONS);

      setGamingPlatformSelectOptions(gamingPlatforms);
      setGameDurationSelectOptions(gameDurations);
      setCountriesSelectOptions(countries);
      setPlayersSelectOptions(players);
      setRegionalFederationsSelectOptions(regionalFederations);
    })();
  }, []);

  const loadPlayer = async (id: number) => {
    showLoader();
    const loadedPlayer = await getAdminPlayerEdit(id);
    const cities = await getCities('', id);
    hideLoader();

    setCitiesSelectOptions(cities);
    setSelectedPlayer({
      ...loadedPlayer,
      email: loadedPlayer.email ? loadedPlayer.email : '',
      phoneNumber: loadedPlayer.phoneNumber ? loadedPlayer.phoneNumber : ''
    });
  };

  const handleDelete = async (id: number) => {
    showLoader();

    try {
      await deletePlayer(id);

      setSelectedPlayer(null);
      setPlayersSelectOptions(playersSelectOptions.filter(o => o.id !== id));

      toast({
        title: 'Changes saved',
        description: 'The player is successfully deleted',
        status: 'success'
      });
      hideLoader();
      setErrorMessage('');
    } catch (error: any) {
      hideLoader();
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit Player</title>
      </Helmet>
      <Container maxW={'container.md'} my={styleConstants.viewMY}>
        <Flex width='full' align='center' justifyContent='center'>
          <Box p={12} borderWidth={1} borderRadius={8} boxShadow='lg'>
            <PermissionGuard requiredPermission={PERMISSIONS.PLAYER.EXPORT}>
              <Button
                as={ChakraLink}
                href={ROUTES.PLAYER_EXPORT}
                colorScheme='orange'
                variant='outline'
              >
                Export players
              </Button>
            </PermissionGuard>
            <Box textAlign='center'>
              <Heading size='lg'>Edit player's profile information</Heading>
            </Box>
            <Box mt={4} mb={4} textAlign='left'>
              <FormLabel>Player to edit:</FormLabel>
              <SelectInput
                options={playersSelectOptions}
                placeholder='Select player'
                onChange={value => loadPlayer(value as number)}
                value={selectedPlayer?.id}
              />
              {selectedPlayer?.hasNoGames && (
                <PermissionGuard requiredPermission={PERMISSIONS.PLAYER.DELETE}>
                  <Button
                    colorScheme='orange'
                    variant='outline'
                    onClick={() => handleDelete(selectedPlayer.id)}
                  >
                    Delete
                  </Button>
                </PermissionGuard>
              )}
              {selectedPlayer && (
                <Formik
                  initialValues={selectedPlayer}
                  validationSchema={AdminPlayerEditValidationSchema}
                  onSubmit={(values, actions) => {
                    handleSubmit(values);
                  }}
                  enableReinitialize={true}
                >
                  {(props: FormikProps<AdminPlayerEdit>) => (
                    <Form>
                      <Field name='firstName'>
                        {({ form, field }: any) => (
                          <FormControl
                            mt='1rem'
                            isInvalid={
                              form.errors?.firstName && form.touched?.firstName
                            }
                          >
                            <FormLabel>Firstname:</FormLabel>
                            <Input type='text' placeholder='' {...field} />
                            <FormErrorMessage>
                              {form.errors?.firstName}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='lastName'>
                        {({ form, field }: any) => (
                          <FormControl
                            mt='1rem'
                            isInvalid={
                              form.errors?.lastName && form.touched?.lastName
                            }
                          >
                            <FormLabel>Lastname:</FormLabel>
                            <Input type='text' placeholder='' {...field} />
                            <FormErrorMessage>
                              {form.errors?.lastName}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='email'>
                        {({ form, field }: any) => (
                          <FormControl
                            mt='1rem'
                            isInvalid={
                              form.errors?.email && form.touched?.email
                            }
                          >
                            <FormLabel>Email:</FormLabel>
                            <Input type='email' {...field} />
                            <FormErrorMessage>
                              {form.errors?.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='countryId'>
                        {({ form, field }: any) => (
                          <FormControl
                            mt='1rem'
                            isInvalid={
                              form.errors?.countryId && form.touched?.countryId
                            }
                          >
                            <FormLabel>Country:</FormLabel>
                            <SelectInput
                              value={field.value}
                              onChange={value =>
                                form.setFieldValue(field.name, value)
                              }
                              options={countriesSelectOptions}
                              isSearchable
                              placeholder='Choose a country'
                              isInvalid={
                                form.errors?.countryId &&
                                form.touched?.countryId
                              }
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                            />
                            <FormErrorMessage>
                              {form.errors?.countryId}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='regionalFederationId'>
                        {({ form, field }: any) => (
                          <FormControl
                            mt='1rem'
                            isInvalid={
                              form.errors?.regionalFederationId &&
                              form.touched?.regionalFederationId
                            }
                          >
                            <FormLabel>Regional Federation:</FormLabel>
                            <SelectInput
                              value={field.value}
                              onChange={value =>
                                form.setFieldValue(field.name, value)
                              }
                              options={regionalFederationsSelectOptions}
                              isSearchable
                              placeholder='Choose a federation'
                              isInvalid={
                                form.errors?.regionalFederationId &&
                                form.touched?.regionalFederationId
                              }
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                            />
                            <FormErrorMessage>
                              {form.errors?.regionalFederationId}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='nickname'>
                        {({ form, field }: any) => (
                          <FormControl
                            mt='1rem'
                            isInvalid={
                              form.errors?.nickname && form.touched?.nickname
                            }
                          >
                            <FormLabel>Playdek Name:</FormLabel>
                            <Input
                              type='text'
                              placeholder='Enter Playdek Name'
                              {...field}
                            />
                            <FormErrorMessage>
                              {form.errors?.nickname}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='phoneNumber'>
                        {({ form, field }: any) => (
                          <FormControl
                            mt='1rem'
                            isInvalid={
                              form.errors?.phoneNumber &&
                              form.touched?.phoneNumber
                            }
                          >
                            <FormLabel>Phone number:</FormLabel>
                            <Input
                              type='text'
                              placeholder='Enter phone number (example: +123456789)'
                              {...field}
                            />
                            <FormErrorMessage>
                              {form.errors?.phoneNumber}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='cityId'>
                        {({ form, field }: any) => (
                          <FormControl
                            isRequired
                            mt='1rem'
                            isInvalid={
                              form.errors?.cityId && form.touched?.cityId
                            }
                          >
                            <FormLabel>City:</FormLabel>
                            <FormHelperText mb='.3rem'>
                              {FORM_HELPER_MESSAGES.CITY}
                            </FormHelperText>
                            <FormHelperText mb='.3rem'>
                              {FORM_HELPER_MESSAGES.CITY_NOTE}
                            </FormHelperText>
                            <AsyncSelectInput
                              value={field.value}
                              onChange={value =>
                                form.setFieldValue(field.name, value)
                              }
                              isSearchable
                              placeholder='Choose a city'
                              isInvalid={
                                form.errors?.cityId && form.touched?.cityId
                              }
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              defaultOptions={citiesSelectOptions}
                              getOptions={s => getCities(s)}
                            />
                            <FormErrorMessage>
                              {form.errors?.cityId}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='preferredGamingPlatformId'>
                        {({ form, field }: any) => (
                          <FormControl
                            isRequired
                            mt='1rem'
                            isInvalid={
                              form.errors?.preferredGamingPlatformId &&
                              form.touched?.preferredGamingPlatformId
                            }
                          >
                            <FormLabel>Preferred gaming platform:</FormLabel>
                            <SelectInput
                              value={field.value}
                              onChange={value =>
                                form.setFieldValue(field.name, value)
                              }
                              options={gamingPlatformSelectOptions}
                              isSearchable={false}
                              placeholder='Choose a gaming platform'
                              isInvalid={
                                form.errors?.preferredGamingPlatformId &&
                                form.touched?.preferredGamingPlatformId
                              }
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                            />
                            <FormErrorMessage>
                              {form.errors?.preferredGamingPlatformId}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name='preferredGameDurationId'>
                        {({ form, field }: any) => (
                          <FormControl
                            isRequired
                            mt='1rem'
                            isInvalid={
                              form.errors?.preferredGameDurationId &&
                              form.touched?.preferredGameDurationId
                            }
                          >
                            <FormLabel>Preferred game duration:</FormLabel>
                            <SelectInput
                              value={field.value}
                              onChange={value =>
                                form.setFieldValue(field.name, value)
                              }
                              options={gameDurationSelectOptions}
                              isSearchable={false}
                              placeholder='Choose game duration'
                              isInvalid={
                                form.errors?.preferredGameDurationId &&
                                form.touched?.preferredGameDurationId
                              }
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                            />
                            <FormErrorMessage>
                              {form.errors?.preferredGameDurationId}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      {errorMessage && <Error error={errorMessage}></Error>}
                      <Box textAlign={'center'}>
                        <Button
                          colorScheme='teal'
                          variant='outline'
                          width='36'
                          textAlign={'center'}
                          mt={6}
                          type='submit'
                        >
                          Submit
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              )}
            </Box>
          </Box>
        </Flex>
      </Container>
      <>{loader}</>
    </>
  );
};

export default AdminPlayerEditView;
