import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getTournamentParticipants,
  removePlayerFromTournament
} from '../../services/tournamentService';
import TournamentParticipants from '../../models/tournament/tournamentParticipants';
import {
  Badge,
  Circle,
  Flex,
  Grid,
  Skeleton,
  Text,
  useToast
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import useFullPageLoader from '../../hooks/useFullPageLoader';
import TournamentParticipantStatus from '../../enums/tournamentParticipantStatus';
import WaitingPlayerCard from '../../components/waitingList/WaitingPlayerCard';

const TournamentWaitingListView = () => {
  const { id } = useParams();
  const [model, setModel] = useState<TournamentParticipants | undefined>();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const toast = useToast();

  useEffect(() => {
    (async () => {
      const res = await getTournamentParticipants(+id!, [
        TournamentParticipantStatus.Waiting
      ]);
      setModel(res);
    })();
  }, [id]);

  const handleParticipantRemoved = async (playerId: number) => {
    showLoader();
    try {
      await removePlayerFromTournament(+id!, playerId);

      const newParticipants = {
        ...model!!,
        participants: model!!.participants.filter(p => p.id !== playerId)
      };

      setModel(newParticipants);

      toast({
        title: 'Player removal',
        description: 'Player was successfully removed from tournament',
        status: 'success'
      });
    } catch (error: any) {
      toast({
        title: 'An error occurred',
        description: 'An unexpected error occurred',
        status: 'error'
      });
    }
    hideLoader();
  };

  const skeleton = useMemo(
    () =>
      [...Array(15)].map((_value, index) => (
        <Skeleton
          h={{ base: '5rem', md: '3.5rem' }}
          key={index}
          borderRadius={8}
          boxShadow='lg'
        />
      )),
    []
  );

  const content = model?.participants.map(wp => (
    <WaitingPlayerCard
      key={wp.id}
      tournamentId={0}
      tournamentParticipant={wp}
      onParticipantRemoved={handleParticipantRemoved}
    />
  ));

  return (
    <>
      <Helmet>
        <title>Tournament participants</title>
      </Helmet>

      <Flex alignItems='center' gap='.2rem' marginBottom={2}>
        <Text fontWeight='bold'>
          {model?.participants.length === 0
            ? 'No waiting players yet'
            : 'Waiting players'}
        </Text>
        {!!model?.participants.length && (
          <Circle colorScheme='orange' variant='solid' as={Badge}>
            {model?.participants.length}
          </Circle>
        )}
      </Flex>
      <Grid
        templateColumns={{ base: '1fr', sm: 'repeat(2, 1fr)' }}
        gap='0.5rem'
      >
        {model ? content : skeleton}
      </Grid>
      {loader}
    </>
  );
};

export default TournamentWaitingListView;
