import { Image } from '@chakra-ui/react';

interface FlagProps {
  code: string;
  size: 'sm' | 'md' | 'lg';
  isRegional?: boolean;
}

const Flag = ({ code, size, isRegional }: FlagProps) => {
  const sizes = {
    sm: '1.5rem',
    md: '2rem',
    lg: '2.5rem'
  };

  const source = isRegional
    ? require(`../assets/flags/regional/${code}.svg`)
    : require(`../assets/flags/${code}.svg`);

  return (
    <Image
      boxShadow={'0 0 0 1px rgba(0, 0, 0, 0.1)'}
      width={sizes[size]}
      src={source}
    />
  );
};

export default Flag;
