import { ObjectSchema, boolean, object, string } from 'yup';
import Login from '../models/authentication/login';
import { MESSAGES } from '../constants/validationMessages';

export const LogInValidationSchema: ObjectSchema<Login> = object({
  email: string()
    .trim()
    .email(MESSAGES.VALID.EMAIL)
    .required(MESSAGES.REQUIRED.EMAIL),
  password: string().trim().required(MESSAGES.REQUIRED.PASSWORD),
  rememberMe: boolean().required()
});
