import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useDisclosure
} from '@chakra-ui/react';
import { CiFilter } from 'react-icons/ci';
import { ROUTES, SEARCH_PARAMS } from '../../constants';
import { SmallAddIcon } from '@chakra-ui/icons';
import { MdOutlineFileUpload } from 'react-icons/md';
import { PERMISSIONS } from '../../constants/permissions';
import GameStatus from '../../enums/gameStatus';
import OrderDirection from '../../enums/orderDirection';
import ScopeLevel from '../../models/authentication/scopeLevel';
import { replaceUrlParams } from '../../utils/routerUtils';
import RadioFilter from '../filters/RadioFilter';
import PermissionGuard from '../guards/PermissionGuard';
import SingleSort from '../sorters/SingleSort';
import { Link } from 'react-router-dom';

interface TournamentScheduleFilterDrawerProps {
  tournamentId: number;
}

const TournamentScheduleFilterDrawer = ({
  tournamentId
}: TournamentScheduleFilterDrawerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        onClick={onOpen}
        variant='outline'
        leftIcon={<CiFilter size='1rem' />}
        w='100%'
      >
        Filters and actions panel
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose} placement='left'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader py={2}>Actions</DrawerHeader>
          <DrawerBody pl={2} pt={1}>
            <Flex flexDir='column' gap='.5rem' mb='.5rem' w='100%'>
              <RadioFilter
                paramName={SEARCH_PARAMS.STATUS}
                title='Show'
                options={
                  [
                    GameStatus.Unplayed,
                    GameStatus.Played,
                    GameStatus.All
                  ] as string[]
                }
                defaultValue={GameStatus.Unplayed}
                onFilterApplied={onClose}
              />
              <SingleSort
                options={[
                  { id: 'dueDate', value: 'Due date' },
                  { id: 'identifier', value: 'Identifier' }
                ]}
                defaultValue='dueDate'
                defaultOrderDirection={OrderDirection.Ascending}
                onFilterApplied={onClose}
                w='100%'
              />
              <PermissionGuard
                requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
                requiredScope={ScopeLevel.Tournament}
                requiredResourceId={tournamentId}
              >
                <Button
                  leftIcon={<SmallAddIcon />}
                  as={Link}
                  to={replaceUrlParams(ROUTES.GAME_CREATE, { tournamentId })}
                >
                  Create game
                </Button>
                <Button
                  leftIcon={<MdOutlineFileUpload />}
                  as={Link}
                  to={replaceUrlParams(ROUTES.GAME_IMPORT, { tournamentId })}
                >
                  Import games
                </Button>
              </PermissionGuard>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default TournamentScheduleFilterDrawer;
