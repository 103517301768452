import { useEffect, useState } from 'react';
import TournamentMedals from '../../components/tournament/TournamentMedals';
import TournamentMedalsInfo from '../../models/tournamentWinners/tournamentMedalsInfo';
import { getTournamentWinnersByTournament } from '../../services/tournamentWinnerService';
import { Flex, Link as ChakraLink, Grid } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { replaceUrlParams } from '../../utils/routerUtils';
import { ROUTES } from '../../constants';

const HallOfFameByTournamentView = () => {
  const [tournaments, setTournaments] = useState([] as TournamentMedalsInfo[]);

  useEffect(() => {
    const getTournamentMedals = async () => {
      const data = await getTournamentWinnersByTournament();
      setTournaments(data);
    };

    getTournamentMedals();
  }, []);

  return (
    <>
      {tournaments.map(t => (
        <Flex flexDir='column' gap='0.5rem'>
          <Flex flexDir='column'>
            <ChakraLink
              fontSize='smaller'
              as={Link}
              to={replaceUrlParams(ROUTES.TOURNAMENT_INFO, {
                id: t.tournamentId
              })}
            >
              {t.tournamentName}
            </ChakraLink>
            <Grid gridTemplateColumns='repeat(3, 1fr)' gap={1}>
              <TournamentMedals tournamentWinners={t.tournamentWinners} />
            </Grid>
          </Flex>
        </Flex>
      ))}
    </>
  );
};

export default HallOfFameByTournamentView;
