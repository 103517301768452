import { useEffect, useState } from 'react';
import CheckboxFilter from '../filters/CheckboxFilter';
import SelectOption from '../../models/utils/selectOption';
import { Box, Flex, Skeleton, Wrap } from '@chakra-ui/react';
import { SEARCH_PARAMS } from '../../constants';
import SearchFilter from '../filters/SearchFilter';
import PlayersRatingFilterDrawer from './PlayersRatingFilterDrawer';
import { getSelectOptions } from '../../services/lookupService';
import { LOOKUP_TYPES } from '../../constants/lookupTypes';
import ToggleFilter from '../filters/ToggleFilter';

const PlayersRatingFiltersPanel = () => {
  const [countries, setCountries] = useState([] as SelectOption[]);
  const [regionalFederations, setRegionalFederations] = useState(
    [] as SelectOption[]
  );
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      const countries = await getSelectOptions(LOOKUP_TYPES.COUNTRIES);
      const regionalFederations = await getSelectOptions(
        LOOKUP_TYPES.REGIONAL_FEDERATIONS
      );

      setCountries(countries);
      setRegionalFederations(regionalFederations);
      setLoaded(true);
    })();
  }, []);

  return (
    <Box
      pt={2}
      pb={2}
      pl={5}
      pr={5}
      borderWidth={1}
      borderRadius={8}
      boxShadow='lg'
      mb={2}
    >
      <Flex
        justifyContent='space-between'
        display={{ base: 'none', md: 'flex' }}
      >
        <Wrap gap='0.2rem'>
          <Skeleton isLoaded={isLoaded} borderRadius={8}>
            <CheckboxFilter
              height='2.5rem'
              paramName={SEARCH_PARAMS.COUNTRY}
              options={countries}
            />
          </Skeleton>
          <Skeleton isLoaded={isLoaded} borderRadius={8}>
            <CheckboxFilter
              height='2.5rem'
              paramName={SEARCH_PARAMS.REGIONAL_FEDERATION}
              title='regional federation'
              options={regionalFederations}
            />
          </Skeleton>
          <Skeleton isLoaded={isLoaded} borderRadius={8}>
            <ToggleFilter
              paramName={SEARCH_PARAMS.WITH}
              title='unranked'
              value='all'
              h='2.5rem'
            />
          </Skeleton>
        </Wrap>
        <Skeleton isLoaded={isLoaded} borderRadius={8}>
          <SearchFilter
            paramName={SEARCH_PARAMS.SEARCH}
            placeholder='Name or Playdek'
          />
        </Skeleton>
      </Flex>
      <Flex display={{ base: 'flex', md: 'none' }}>
        <PlayersRatingFilterDrawer
          countries={countries}
          regionalFederations={regionalFederations}
        />
      </Flex>
    </Box>
  );
};

export default PlayersRatingFiltersPanel;
