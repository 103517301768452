import { PropsWithChildren } from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface ComponentWithHeadingProps {
  heading: string;
}

const ComponentWithHeading = ({
  children,
  heading
}: PropsWithChildren<ComponentWithHeadingProps>) => (
  <Flex flexDir='column'>
    <Text fontSize='0.6rem' color='gray.400'>
      {heading}
    </Text>
    {children}
  </Flex>
);

export default ComponentWithHeading;
