import { Flex, Skeleton, Tag, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { getStages } from '../../services/tournamentService';
import StageInfoShort from '../../models/stage/stageInfoShort';
import { replaceUrlParams } from '../../utils/routerUtils';
import { ROUTES } from '../../constants';

const TournamentStandingsView = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [stages, setStages] = useState([] as StageInfoShort[]);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const fetchStages = async () => {
      const stages = await getStages(+id!);
      setStages(stages);

      if (stages.length) {
        navigate(
          replaceUrlParams(ROUTES.TOURNAMENT_STAGE_STANDINGS, {
            id: +id!,
            stageId: stages[0].id
          })
        );
      }
      setInitialized(true);
    };

    fetchStages();
  }, [id]);

  const skeleton = [...Array(3)].map((_value, index) => (
    <Skeleton key={index}>
      <Tag>Main stage</Tag>
    </Skeleton>
  ));

  const content = stages.length ? (
    stages.map(s => (
      <Tag
        key={s.id}
        variant='outline'
        as={Link}
        to={replaceUrlParams(ROUTES.TOURNAMENT_STAGE_STANDINGS, {
          id,
          stageId: s.id
        })}
      >
        {s.name}
      </Tag>
    ))
  ) : (
    <Text>No standings available yet</Text>
  );

  return (
    <>
      <Helmet>
        <title>Tournament standings</title>
      </Helmet>
      <Flex gap='0.2rem'>{initialized ? content : skeleton}</Flex>
      <Outlet />
    </>
  );
};

export default TournamentStandingsView;
