import {
  Badge,
  Flex,
  Grid,
  HStack,
  IconButton,
  LinkBox,
  LinkOverlay,
  Text,
  Link as ChakraLink
} from '@chakra-ui/react';
import GameInfoShort from '../../models/game/gameInfoShort';
import Flag from '../Flag';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { PERMISSIONS } from '../../constants/permissions';
import ScopeLevel from '../../models/authentication/scopeLevel';
import PermissionGuard from '../guards/PermissionGuard';
import { Link } from 'react-router-dom';
import { replaceUrlParams } from '../../utils/routerUtils';
import { ROUTES } from '../../constants';
import ComponentWithHeading from '../ComponentWithHeading';

type TournamentGameCardProps = {
  game: GameInfoShort;
  onGameDelete: (id: number) => void;
};

const TournamentGameCard = ({
  game,
  onGameDelete
}: TournamentGameCardProps) => {
  return (
    <LinkBox>
      <LinkOverlay
        as={Link}
        to={replaceUrlParams(ROUTES.GAME_INFO, {
          id: game.id
        })}
      ></LinkOverlay>
      <Grid
        templateColumns={{
          base: '1fr 1fr',
          md: 'repeat(6, 1fr)'
        }}
        px={2}
        py={1}
        borderWidth={1}
        borderRadius={6}
        boxShadow='lg'
        gap={{ base: '0.25rem', sm: '1rem' }}
      >
        <ComponentWithHeading heading='First player:'>
          <HStack>
            <Flag code={game.firstPlayerCountry} size='sm' />
            <Text fontSize='0.9rem' lineHeight='5'>
              {game.firstPlayerName}
            </Text>
          </HStack>
        </ComponentWithHeading>

        <ComponentWithHeading heading='Second player:'>
          <HStack>
            <Flag code={game.secondPlayerCountry} size='sm' />
            <Text fontSize='0.9rem' lineHeight='5'>
              {game.secondPlayerName}
            </Text>
          </HStack>
        </ComponentWithHeading>

        <ComponentWithHeading heading='Sides:'>
          <Text fontSize='0.9rem'>{game.powerSelectionMethod}</Text>
        </ComponentWithHeading>

        <ComponentWithHeading heading='Due date:'>
          <Text>
            <Badge variant='outline'>
              {new Date(game.dueDate).toLocaleDateString()}
            </Badge>
          </Text>
        </ComponentWithHeading>

        <ComponentWithHeading heading='Identifier'>
          <Text fontSize='0.9rem'>{game.identifier ?? '-'}</Text>
        </ComponentWithHeading>

        {game.isPlayed ? (
          <PermissionGuard
            requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
            requiredScope={ScopeLevel.Tournament}
            requiredResourceId={game.tournamentId}
          >
            <Flex justifyContent={{ base: 'flex-end', sm: 'flex-start' }}>
              <IconButton
                as={Link}
                icon={<EditIcon />}
                variant='ghost'
                aria-label='Edit game'
                to={replaceUrlParams(ROUTES.GAME_EDIT, {
                  id: game.id,
                  tournamentId: game.tournamentId
                })}
              ></IconButton>
              <IconButton
                icon={<DeleteIcon />}
                variant='ghost'
                aria-label='Delete game'
                onClick={() => onGameDelete(game.id)}
              ></IconButton>
            </Flex>
          </PermissionGuard>
        ) : (
          <ComponentWithHeading heading='Result'>
            <ChakraLink
              as={Link}
              to={replaceUrlParams(ROUTES.GAME_RESULT_INFO, {
                id: game.gameResultId
              })}
              fontSize='0.9rem'
            >
              {game.winningPlayerName
                ? `Won by ${game.winningPlayerName}`
                : 'Tie'}
            </ChakraLink>
          </ComponentWithHeading>
        )}
      </Grid>
    </LinkBox>
  );
};

export default TournamentGameCard;
