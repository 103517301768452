import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getTournamentParticipants,
  removePlayerFromTournament
} from '../../services/tournamentService';
import TournamentParticipants from '../../models/tournament/tournamentParticipants';
import {
  Badge,
  Circle,
  Flex,
  Grid,
  Skeleton,
  Text,
  useToast
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import TournamentParticipantCard from '../../components/tournament/TournamentParticipantCard';
import useFullPageLoader from '../../hooks/useFullPageLoader';
import ForfeitedGamesPopup from '../../components/ForfeitedGamesPopup';
import TournamentParticipantStatus from '../../enums/tournamentParticipantStatus';
import _ from 'lodash';

const TournamentParticipantsView = () => {
  const { id } = useParams();
  const [model, setModel] = useState<TournamentParticipants | undefined>();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const toast = useToast();

  const [popUp, setPopup] = useState(<></>);

  useEffect(() => {
    (async () => {
      const res = await getTournamentParticipants(+id!, [
        TournamentParticipantStatus.Active,
        TournamentParticipantStatus.Forfeited
      ]);
      setModel(res);
    })();
  }, [id]);

  const handleParticipantRemoved = async (playerId: number) => {
    showLoader();
    try {
      await removePlayerFromTournament(+id!, playerId);

      const newParticipants = {
        ...model!!,
        participants: model!!.participants.filter(p => p.id !== playerId)
      };

      setModel(newParticipants);

      toast({
        title: 'Player removal',
        description: 'Player was successfully removed from tournament',
        status: 'success'
      });
    } catch (error: any) {
      toast({
        title: 'An error occurred',
        description: 'An unexpected error occurred',
        status: 'error'
      });
    }
    hideLoader();
  };

  const openForfeitedGamesPopup = async (playerId: number) => {
    const modal = (
      <ForfeitedGamesPopup
        onClose={() => {
          setPopup(<></>);
        }}
        onContinue={id => {
          setPopup(<></>);
          handleParticipantForfeited(id);
        }}
        playerId={playerId}
        tournamentId={+id!}
      />
    );

    setPopup(modal);
  };

  const handleParticipantForfeited = async (playerId: number) => {
    const participant = {
      ...model?.participants.find(p => p.id === playerId)!!,
      status: TournamentParticipantStatus.Forfeited
    };

    const newParticipants = {
      ...model!!,
      participants: _.orderBy(
        [...model!!.participants.filter(p => p.id !== playerId), participant],
        ['status, rating'],
        ['asc', 'desc']
      )
    };

    setModel(newParticipants);
  };

  const skeleton = useMemo(
    () =>
      [...Array(15)].map((_value, index) => (
        <Skeleton
          h={{ base: '5rem', md: '3.5rem' }}
          key={index}
          borderRadius={8}
          boxShadow='lg'
        />
      )),
    []
  );

  const content = useMemo(
    () =>
      model?.participants.map(participant => (
        <TournamentParticipantCard
          key={participant.id}
          tournamentId={+id!}
          tournamentStatus={model.tournamentStatus}
          tournamentParticipant={participant}
          onParticipantRemoved={handleParticipantRemoved}
          onParticipantForfeited={openForfeitedGamesPopup}
        />
      )),
    [model?.participants]
  );

  return (
    <>
      <Helmet>
        <title>Tournament participants</title>
      </Helmet>
      <Flex alignItems='center' gap='.2rem' marginBottom={2}>
        <Text fontWeight='bold'>
          {model?.participants.length === 0
            ? 'No participants yet'
            : 'Participants'}
        </Text>
        {!!model?.participants.length && (
          <Circle colorScheme='orange' variant='solid' as={Badge}>
            {model.participants.length}
          </Circle>
        )}
      </Flex>
      <Grid
        templateColumns={{ base: '1fr', sm: 'repeat(2, 1fr)' }}
        gap='0.5rem'
      >
        {model ? content : skeleton}
      </Grid>
      {loader}
      {popUp}
    </>
  );
};

export default TournamentParticipantsView;
