export const PERMISSIONS = {
  TOURNAMENT: {
    CREATE: 'tournament:create',
    MANAGE: 'tournament:manage',
    REGISTER: 'tournament:register'
  },
  SIGN_UP: {
    VIEW: 'sign_up:view',
    CHANGE_STATUS: 'sign_up:change_status'
  },
  GAME_RESULT: {
    SUBMIT: 'game_result:submit',
    EDIT: 'game_result:edit',
    DELETE: 'game_result:delete',
    VIEW_WHATSAPP_MESSAGE: 'game_result:view_whatsapp_message'
  },
  PLAYER: {
    EDIT: 'player:edit',
    DELETE: 'player:delete',
    VIEW_EMAIL: 'player:view_email',
    VIEW_PHONE_NUMBER: 'player:view_phone_number',
    EXPORT: 'player:export'
  },
  TOURNAMENT_DEFINITION: {
    CREATE: 'tournamentDefinition:create'
  },
  POLL: {
    CREATE: 'poll:create'
  }
};
