import PollStatus from '../../enums/pollStatus';
import { Container, Flex } from '@chakra-ui/react';
import styleConstants from '../../constants/styleConstants';
import PollsList from '../../components/poll/PollsList';

const PollsView = () => {
  return (
    <Container maxW='container.lg' mt={styleConstants.viewMY}>
      <Flex flexDir='column' gap='1rem'>
        <PollsList status={PollStatus.All} />
      </Flex>
    </Container>
  );
};

export default PollsView;
