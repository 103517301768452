import { Grid, Flex, HStack, Badge, Text } from '@chakra-ui/react';
import TournamentInfoShort from '../../models/tournament/tournamentInfoShort';
import RegistrationStatus from '../../enums/registrationStatus';
import TournamentStatus from '../../enums/tournamentStatus';

interface TournamentCardProps {
  tournament: TournamentInfoShort;
}

const TournamentCard = ({ tournament }: TournamentCardProps) => {
  const getRegistrationBadgeColor = () => {
    switch (tournament.registration) {
      case RegistrationStatus.Open:
      case RegistrationStatus.WaitingList:
        return 'green';

      case RegistrationStatus.Closed:
        return 'gray';
    }
  };

  const getStatusBadgeColor = () => {
    switch (tournament.status) {
      case TournamentStatus.Upcoming:
        return 'orange';
      case TournamentStatus.Ongoing:
        return 'green';
      case TournamentStatus.Ended:
        return 'gray';
    }
  };

  return (
    <Grid
      templateColumns={{ base: '1fr 1fr', md: '2fr 1fr 1fr 1fr' }}
      px={2}
      py={1}
      borderWidth={1}
      borderRadius={6}
      boxShadow='lg'
      gap={{ base: '0.5rem', md: '1rem' }}
    >
      <Flex flexDir='column'>
        <Text fontSize='0.6rem' color='gray.400'>
          Tournament:
        </Text>
        <HStack>
          <Text lineHeight={4}>
            {tournament.name} {tournament.edition}
          </Text>
        </HStack>
      </Flex>
      <Flex flexDir='column'>
        <Text fontSize='0.6rem' color='gray.400'>
          Dates:
        </Text>
        <Text fontSize='0.8rem'>
          <Badge variant='outline'>
            {new Date(tournament.startDate).toLocaleDateString()} -{' '}
            {new Date(tournament.endDate).toLocaleDateString()}
          </Badge>
        </Text>
      </Flex>
      <Flex flexDir='column'>
        <Text fontSize='0.6rem' color='gray.400'>
          Registration:
        </Text>
        <Text>
          <Badge colorScheme={getRegistrationBadgeColor()} marginBottom={1}>
            {tournament.registration}
          </Badge>
        </Text>
      </Flex>
      <Flex
        flexDir='column'
        alignItems={{ base: 'flex-start', md: 'flex-end' }}
      >
        <Text fontSize='0.6rem' color='gray.400'>
          Status:
        </Text>
        <Text>
          <Badge marginBottom={1} colorScheme={getStatusBadgeColor()}>
            {tournament.status}
          </Badge>
        </Text>
      </Flex>
    </Grid>
  );
};

export default TournamentCard;
