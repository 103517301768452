import TournamentStatus from '../enums/tournamentStatus';
import ChangeTournamentStatus from '../models/tournament/changeTournamentStatus';
import CreateTournament from '../models/tournament/createTournament';
import TournamentInfo from '../models/tournament/tournamentInfo';
import TournamentInfoShort from '../models/tournament/tournamentInfoShort';
import TournamentParticipant from '../models/tournament/tournamentParticipant';
import httpClient from './httpClient';
import EditTournament from './../models/tournament/editTournament';
import Tournament from '../models/tournament/tournament';
import TournamentParticipants from '../models/tournament/tournamentParticipants';
import TournamentRegistrationInfo from '../models/tournament/tournamentRegistrationInfo';
import TournamentSubmissionInfo from '../models/tournament/tournamentSubmissionInfo';
import TournamentParticipantStatus from '../enums/tournamentParticipantStatus';
import MissingGameInfoShort from '../models/tournament/missingGameInfoShort';
import StageInfoShort from '../models/stage/stageInfoShort';
import Standings from '../models/stage/standings';
import PagedResult from '../models/utils/pagedResult';

export async function getTournaments(
  params: URLSearchParams
): Promise<PagedResult<TournamentInfoShort>> {
  
  const url = `/tournament`;
  const response = await httpClient.get(url, { params });
  return response?.data;
}

export async function getTournamentSubmissionInfos() {
  const url = '/tournament/info/submission';
  const response = await httpClient.get<TournamentSubmissionInfo[]>(url);
  return response.data;
}

export async function getTournament(id: number): Promise<Tournament> {
  const url = `/tournament/${id}`;
  const response = await httpClient.get(url);
  return response.data;
}

export async function getTournamentInfo(id: number): Promise<TournamentInfo> {
  const url = `/tournament/${id}/info`;
  const response = await httpClient.get(url);
  return response.data;
}

export async function getTournamentParticipants(
  id: number,
  withStatus: TournamentParticipantStatus[]
): Promise<TournamentParticipants> {
  const params = new URLSearchParams();

  withStatus.forEach(s => params.append('withStatus', s));

  const url = `/tournament/${id}/participants`;
  const response = await httpClient.get(url, { params });
  return response.data;
}

export async function addTournament(model: CreateTournament) {
  const url = '/tournament';
  await httpClient.post(url, model);
}

export async function editTournament(id: number, tournament: EditTournament) {
  const url = `/tournament/${id}`;
  await httpClient.patch(url, tournament);
}

export async function updateTournamentRegistration(id: number) {
  const url = `/tournament/${id}/registration/status`;
  await httpClient.patch(url);
}

export async function updateTournamentStatus(
  id: number,
  status: TournamentStatus
) {
  const url = `/tournament/${id}/status`;
  await httpClient.patch(url, { status } as ChangeTournamentStatus);
}

export async function getRegistrationInfo(
  id: number
): Promise<TournamentRegistrationInfo> {
  const url = `/tournament/${id}/registration`;
  const response = await httpClient.get(url);
  return response.data;
}

export async function registerUserInTournament(
  id: number
): Promise<TournamentParticipant> {
  const url = `/tournament/${id}/registration`;
  const participant = await httpClient.post(url);
  return participant.data;
}

export async function unregisterUserInTournament(id: number): Promise<void> {
  const url = `/tournament/${id}/registration`;
  await httpClient.delete(url);
}

export async function removePlayerFromTournament(
  id: number,
  playerId: number
): Promise<void> {
  const url = `/tournament/${id}/participants/${playerId}`;
  await httpClient.delete(url);
}

export async function getMissingGames(id: number) {
  const url = `/tournament/${id}/missing-games`;
  const response = await httpClient.get<MissingGameInfoShort[]>(url);

  return response.data;
}

export async function getStages(id: number) {
  const url = `/tournament/${id}/stages`;

  const response = await httpClient.get<StageInfoShort[]>(url);
  return response.data;
}

export async function getStandings(tournamentId: number, stageId: number) {
  const url = `/tournament/${tournamentId}/stages/${stageId}/standings`;

  const response = await httpClient.get<Standings>(url);
  return response.data;
}

export async function generateBracket(tournamentId: number, stageId: number) {
  const url = `/tournament/${tournamentId}/stages/${stageId}/bracket`;

  await httpClient.post(url);
}
