import { ObjectSchema, object, string, number } from 'yup';
import { MESSAGES } from '../constants/validationMessages';
import EditPlayerFormValue from '../models/forms/editPlayerFormValue';

export const PlayerEditValidationSchema: ObjectSchema<EditPlayerFormValue> =
  object({
    nickname: string().trim().optional().max(50, MESSAGES.LENGTH),
    phoneNumber: string()
      .trim()
      .optional()
      .matches(/^\+\d{5,15}$/, MESSAGES.VALID.PHONE_NUMBER),
    cityId: number()
      .moreThan(0, MESSAGES.REQUIRED.CITY_ID)
      .required(MESSAGES.REQUIRED.CITY_ID),
    preferredGamingPlatformId: number()
      .moreThan(0, MESSAGES.REQUIRED.PREFERRED_GAMING_PLATFORM_ID)
      .required(MESSAGES.REQUIRED.PREFERRED_GAMING_PLATFORM_ID),
    preferredGameDurationId: number()
      .moreThan(0, MESSAGES.REQUIRED.PREFERRED_GAME_DURATION_ID)
      .required(MESSAGES.REQUIRED.PREFERRED_GAME_DURATION_ID)
  });
