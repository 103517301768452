import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getPlayerTimeDifference } from '../services/playerService';
import PlayerTimeDifference from './../models/player/playerTimeDifference';
import {
  Box,
  Center,
  Container,
  Flex,
  Grid,
  Skeleton,
  Text
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import TimeTransitionCard from '../components/timeDifference/TimeTransitionCard';
import TimeCard from '../components/timeDifference/TimeCard';
import HoursDifferenceMessage from '../components/timeDifference/HoursDifferenceMessage';

const PlayerTimeDifferenceView = () => {
  const { id } = useParams();

  const [timeDifference, setTimeDifference] = useState<PlayerTimeDifference>();
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      const difference = await getPlayerTimeDifference(+id!);
      setTimeDifference(difference);
      setLoaded(true);
    })();
  }, [id]);

  useEffect(() => {
    const clock = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(clock);
    };
  }, []);

  return (
    <>
      <Helmet>Time Difference</Helmet>
      <Container maxW={'container.lg'} my={6} p={{ base: '.5rem', md: 5 }}>
        <Box
          borderWidth={{ base: 0, sm: 1 }}
          borderRadius={8}
          boxShadow={{ base: 'initial', sm: 'lg' }}
          p={{ base: '.5rem', md: '1.5rem' }}
        >
          {loaded ? (
            <>
              <Center>
                <HoursDifferenceMessage
                  hoursDifference={timeDifference?.hoursDifference}
                />
              </Center>
              <Grid
                templateColumns={{ base: '1fr', sm: '1fr 1fr' }}
                gap={{ base: '1rem', sm: '2rem' }}
                mt='1rem'
              >
                <Flex flexDir='column' alignItems='flex-end'>
                  <Text>We determined your time zone as</Text>
                  <TimeCard
                    date={currentTime}
                    timeZone={timeDifference?.callerTimeZoneId}
                    timeZoneUTCName={timeDifference?.callerTimeZoneUTCName}
                    alignItems='flex-end'
                    orientation='left'
                  />
                  <TimeTransitionCard
                    mt='2rem'
                    transitionDate={
                      timeDifference?.callerNextTimeTransitionDate
                    }
                    transitionMessage={
                      timeDifference?.callerNextTimeTransitionMessage
                    }
                    orientation='left'
                  />
                </Flex>
                <Box>
                  <Text>
                    {timeDifference?.hoursDifference
                      ? `${timeDifference?.playerName} time zone is`
                      : `Unfortunately time zone of ${timeDifference?.playerName} is unknown`}
                  </Text>
                  <TimeCard
                    date={currentTime}
                    timeZone={timeDifference?.playerTimeZoneId}
                    timeZoneUTCName={timeDifference?.playerTimeZoneUTCName}
                    orientation='right'
                  />
                  <TimeTransitionCard
                    mt='2rem'
                    transitionDate={
                      timeDifference?.playerNextTimeTransitionDate
                    }
                    transitionMessage={
                      timeDifference?.playerNextTimeTransitionMessage
                    }
                    orientation='right'
                  />
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Skeleton borderRadius={8} h='2rem'></Skeleton>
              <Grid
                templateColumns={{ base: '1fr', sm: '1fr 1fr' }}
                gap={{ base: '1rem', sm: '2rem' }}
                mt='1rem'
              >
                <Skeleton borderRadius={8} h='20rem'></Skeleton>
                <Skeleton borderRadius={8} h='20rem'></Skeleton>
              </Grid>
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default PlayerTimeDifferenceView;
