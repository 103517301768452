import {
  Avatar,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  StyleProps,
  Text
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import { logUserOut, selectUserName, selectUserId } from '../store/userSlice';
import { replaceUrlParams } from '../utils/routerUtils';
import PermissionGuard from './guards/PermissionGuard';
import { PERMISSIONS } from '../constants/permissions';

const UserMenu = (props: StyleProps) => {
  const userName = useAppSelector(selectUserName);
  const userId = useAppSelector(selectUserId);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Menu>
      <MenuButton {...props}>
        <Avatar name={userName} size='sm'></Avatar>
      </MenuButton>
      <MenuList>
        <Text pl={3}>
          Signed in as <strong>{userName}</strong>
        </Text>
        <MenuDivider />
        <MenuItem
          onClick={() =>
            navigate(replaceUrlParams(ROUTES.PLAYER_PROFILE, { id: userId }))
          }
        >
          My profile
        </MenuItem>
        <MenuItem
          onClick={() =>
            navigate(
              replaceUrlParams(ROUTES.PLAYER_TOURNAMENTS, { id: userId })
            )
          }
        >
          My tournaments
        </MenuItem>
        <MenuItem
          onClick={() =>
            navigate(replaceUrlParams(ROUTES.PLAYER_SCHEDULE, { id: userId }))
          }
        >
          My schedule
        </MenuItem>
        <MenuItem
          onClick={() =>
            navigate(replaceUrlParams(ROUTES.EDIT_PLAYER, { id: userId }))
          }
        >
          Edit profile
        </MenuItem>
        <MenuDivider />
        <PermissionGuard requiredPermission={PERMISSIONS.PLAYER.EDIT}>
          <MenuItem onClick={() => navigate(ROUTES.ADMIN_EDIT_PLAYER)}>
            Edit player
          </MenuItem>
          <MenuDivider />
        </PermissionGuard>
        <PermissionGuard requiredPermission={PERMISSIONS.SIGN_UP.VIEW}>
          <MenuItem onClick={() => navigate(ROUTES.SIGNUP_REQUESTS)}>
            Sign up requests
          </MenuItem>
          <MenuDivider />
        </PermissionGuard>
        <PermissionGuard requiredPermission={PERMISSIONS.TOURNAMENT.CREATE}>
          <MenuItem onClick={() => navigate(ROUTES.CREATE_TOURNAMENT)}>
            Create tournament
          </MenuItem>
        </PermissionGuard>
        <PermissionGuard
          requiredPermission={PERMISSIONS.TOURNAMENT_DEFINITION.CREATE}
        >
          <MenuItem
            onClick={() => navigate(ROUTES.CREATE_TOURNAMENT_DEFINITION)}
          >
            Create tournament definition
          </MenuItem>
          <MenuDivider />
        </PermissionGuard>
        <PermissionGuard requiredPermission={PERMISSIONS.POLL.CREATE}>
          <MenuItem onClick={() => navigate(ROUTES.POLL_CREATE)}>
            Create poll
          </MenuItem>
          <MenuDivider />
        </PermissionGuard>
        <MenuItem onClick={() => dispatch(logUserOut())}>Sign out</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default UserMenu;
