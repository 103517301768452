import { FC, memo } from 'react';
import { Box, Button, IconButton } from '@chakra-ui/react';
import _ from 'lodash';

import './style.css';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../../constants';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

interface PaginationProperties {
  totalPages: number;
}

const Pagination: FC<PaginationProperties> = ({ totalPages }) => {
  const DOTS = '...';
  const maxPages = 5;

  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get('page');
  const currentPage = pageParam && +pageParam > 0 ? +pageParam : 1;

  const getPageNumbers = () => {
    if (totalPages <= maxPages) {
      return _.range(1, totalPages + 1);
    }

    if (currentPage <= 4) {
      return [..._.range(1, maxPages + 1), DOTS, totalPages];
    }

    if (currentPage > 4 && currentPage <= totalPages - 4) {
      return [
        1,
        DOTS,
        ..._.range(currentPage - 2, currentPage + 3),
        DOTS,
        totalPages
      ];
    }

    if (currentPage > totalPages - 4) {
      return [1, DOTS, ..._.range(totalPages - 4, totalPages + 1)];
    }
  };

  const goToPage = (page: number) => {
    searchParams.set(SEARCH_PARAMS.PAGE, page.toString());
    setSearchParams(searchParams);
  };

  const pageNumbers = getPageNumbers() ?? [];

  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  return totalPages <= 1 ? (
    <></>
  ) : (
    <Box>
      <div className='pagination'>
        {currentPage !== 1 && (
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === 1 ? true : false}
            aria-label='prev page'
          >
            Prev
          </IconButton>
        )}
        {pageNumbers.map((number, index) =>
          number === DOTS ? (
            <Box display={{ base: 'none', sm: 'initial' }} key={index}>
              {number}
            </Box>
          ) : (
            <Button
              key={index}
              onClick={() => goToPage(number as number)}
              display={{
                base:
                  (number as number) <= currentPage + 1 &&
                  (number as number) >= currentPage - 1
                    ? 'initial'
                    : 'none',
                sm: 'initial'
              }}
              className={number === currentPage ? 'active' : ''}
            >
              {number}
            </Button>
          )
        )}
        {currentPage !== totalPages && (
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={() => goToPage(currentPage + 1)}
            aria-label='next page'
          >
            Next
          </IconButton>
        )}
      </div>
    </Box>
  );
};

export default memo(Pagination);
