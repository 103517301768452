import { createStandaloneToast } from '@chakra-ui/react';

export async function sendRequestWithToast(
  action: Promise<void>,
  successMessage: string
) {
  const { toast } = createStandaloneToast();

  try {
    await action;

    toast({
      title: 'Success',
      description: successMessage,
      status: 'success',
      isClosable: true,
      position: 'top-right',
      duration: 5000
    });

    return true;
  } catch (error: any) {
    toast({
      title: 'Error',
      description: `${error.message}`,
      status: 'error',
      isClosable: true,
      position: 'top-right',
      duration: 5000
    });

    return false;
  }
}
