import { Flex, Skeleton, Text } from '@chakra-ui/react';
import GameInfoShort from '../../models/game/gameInfoShort';
import TournamentGameCard from './TournamentGameCard';

type TournamentGamesListProps = {
  games: GameInfoShort[] | null;
  onGameDelete: (id: number) => void;
};

const TournamentGamesList = ({
  games,
  onGameDelete
}: TournamentGamesListProps) => {
  let content = null;

  if (!games) {
    content = [...Array(15)].map((_value, index) => (
      <Skeleton
        h={{ base: '5rem', sm: '3.5rem' }}
        key={index}
        borderRadius={6}
      />
    ));
  }

  if (games?.length) {
    content = games.map(game => (
      <TournamentGameCard
        key={game.id}
        game={game}
        onGameDelete={onGameDelete}
      />
    ));
  }

  return (
    <Flex flexDir='column' gap='0.5rem'>
      {content ?? (
        <Flex justifyContent='center'>
          <Text>No schedules games available</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default TournamentGamesList;
