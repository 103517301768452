import { useEffect, useState } from 'react';
import GameInfoShort from '../../models/game/gameInfoShort';
import { useParams, useSearchParams } from 'react-router-dom';
import { deleteGame, getGames } from '../../services/gameService';
import { SEARCH_PARAMS } from '../../constants';
import Pagination from '../../components/pagination/Pagination';
import { Helmet } from 'react-helmet';
import TournamentGamesList from '../../components/tournament/TournamentGamesList';
import { Flex, useToast } from '@chakra-ui/react';
import useFullPageLoader from '../../hooks/useFullPageLoader';
import styleConstants from '../../constants/styleConstants';
import TournamentScheduleFiltersPanel from '../../components/filterPanels/TournamentScheduleFiltersPanel';

const TournamentScheduleView = () => {
  const [games, setGames] = useState<GameInfoShort[] | null>(null);
  const [totalPages, setTotalPages] = useState(0);

  const { id } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      setGames(null);
      searchParams.set(SEARCH_PARAMS.TOURNAMENT, id!);

      const result = await getGames(searchParams);
      setGames(result.items);
      setTotalPages(result.totalPages);
    })();
  }, [id, searchParams]);

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const toast = useToast();

  const handleDelete = async (id: number) => {
    showLoader();

    try {
      await deleteGame(id);

      const filtered = games!!.filter(g => g.id !== id);
      setGames(filtered);

      toast({
        title: 'Game removal',
        description: 'Game was successfully removed',
        status: 'success'
      });
    } catch (error: any) {
      toast({
        title: 'An error occurred',
        description: 'An unexpected error occurred',
        status: 'error'
      });
    }

    hideLoader();
  };

  return (
    <>
      <Helmet>
        <title>Tournament schedule</title>
      </Helmet>
      <Flex
        flexDir='column'
        gap={{ base: 1, sm: 2 }}
        mb={styleConstants.viewMY}
      >
        <TournamentScheduleFiltersPanel tournamentId={+id!} />
        <TournamentGamesList games={games} onGameDelete={handleDelete} />
      </Flex>
      {loader}
      <Pagination totalPages={totalPages} />
    </>
  );
};

export default TournamentScheduleView;
