import { PropsWithChildren } from 'react';
import { useAppSelector } from '../../hooks/hooks';
import {
  selectUserHasPermission,
  selectUserHasScope
} from '../../store/userSlice';
import ScopeLevel from '../../models/authentication/scopeLevel';

interface PermissionGuardProps {
  requiredPermission: string;
  requiredScope?: ScopeLevel;
  allowedScopes?: ScopeLevel[];
  requiredResourceId?: number;
}

const PermissionGuard = ({
  children,
  requiredPermission,
  requiredScope,
  allowedScopes = [ScopeLevel.System],
  requiredResourceId
}: PropsWithChildren<PermissionGuardProps>) => {
  const hasPermission = useAppSelector(state =>
    selectUserHasPermission(state, requiredPermission)
  );

  const hasScope = useAppSelector(
    state =>
      !requiredScope ||
      selectUserHasScope(state, requiredScope, requiredResourceId) ||
      allowedScopes.some(s => selectUserHasScope(state, s))
  );

  return hasPermission && hasScope ? <>{children}</> : <></>;
};

export default PermissionGuard;
