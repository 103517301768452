import { Flex, Link as ChakraLink, Grid } from '@chakra-ui/react';
import Medal from '../../models/tournamentWinners/medal';
import MedalIcon from '../Medal';
import { Link } from 'react-router-dom';
import { replaceUrlParams } from '../../utils/routerUtils';
import { ROUTES } from '../../constants';
import _ from 'lodash';
import TournamentWinnerPlace from '../../enums/tournamentWinnerPlace';

type PlayerMedalsProps = {
  medals?: Medal[];
};

const PlayerMedals = ({ medals }: PlayerMedalsProps) => {
  if (!medals?.length) return <></>;

  const grouped = _.groupBy(medals, m => m.place);

  return (
    <>
      <Grid
        gridTemplateColumns={{
          base: '1fr',
          sm: 'repeat(3, 1fr)'
        }}
      >
        {grouped[TournamentWinnerPlace.Gold] && (
          <Flex flexDir='column'>
            {grouped[TournamentWinnerPlace.Gold].map(m => (
              <Flex key={m.tournamentId} alignItems='center'>
                <MedalIcon place={m.place} size='sm' />
                <ChakraLink
                  as={Link}
                  to={replaceUrlParams(ROUTES.TOURNAMENT_INFO, {
                    id: m.tournamentId
                  })}
                  fontSize='smaller'
                  lineHeight='5'
                  color='gray.500'
                >
                  {m.tournamentName}
                </ChakraLink>
              </Flex>
            ))}
          </Flex>
        )}
        {grouped[TournamentWinnerPlace.Silver] && (
          <Flex flexDir='column'>
            {grouped[TournamentWinnerPlace.Silver].map(m => (
              <Flex key={m.tournamentId} alignItems='center'>
                <MedalIcon place={m.place} size='sm' />
                <ChakraLink
                  as={Link}
                  to={replaceUrlParams(ROUTES.TOURNAMENT_INFO, {
                    id: m.tournamentId
                  })}
                  fontSize='smaller'
                  lineHeight='5'
                  color='gray.500'
                >
                  {m.tournamentName}
                </ChakraLink>
              </Flex>
            ))}
          </Flex>
        )}
        {grouped[TournamentWinnerPlace.Bronze] && (
          <Flex flexDir='column'>
            {grouped[TournamentWinnerPlace.Bronze].map(m => (
              <Flex key={m.tournamentId} alignItems='center'>
                <MedalIcon place={m.place} size='sm' />
                <ChakraLink
                  as={Link}
                  to={replaceUrlParams(ROUTES.TOURNAMENT_INFO, {
                    id: m.tournamentId
                  })}
                  fontSize='smaller'
                  lineHeight='5'
                  color='gray.500'
                >
                  {m.tournamentName}
                </ChakraLink>
              </Flex>
            ))}
          </Flex>
        )}
      </Grid>
    </>
  );
};

export default PlayerMedals;
