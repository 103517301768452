import { FC, useCallback, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  ChakraProps,
  Checkbox,
  Circle,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  Search2Icon
} from '@chakra-ui/icons';
import SelectOption from '../../models/utils/selectOption';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import './style.css';
import { ViewportList } from 'react-viewport-list';
import { SEARCH_PARAMS } from '../../constants';

interface CheckboxFilterProperties extends ChakraProps {
  paramName: string;
  title?: string;
  options: SelectOption[];
  sortBy?: keyof SelectOption;
  onFilterApplied?: () => void;
}

const CheckboxFilter: FC<CheckboxFilterProperties> = ({
  paramName,
  title,
  options,
  sortBy = 'value',
  onFilterApplied,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayOptions, setDisplayOptions] = useState(options);

  const ref = useRef<HTMLDivElement | null>(null);

  const params = searchParams.getAll(paramName);

  const [selectedOptions, selectedOptionsCount] = useMemo(() => {
    const optionsSet = new Set(options.map(o => o.id.toString()));
    const selectedOptions = new Set(params.filter(x => optionsSet.has(x)));
    const selectedOptionsCount = selectedOptions.size;

    return [selectedOptions, selectedOptionsCount];
  }, [params]);

  const [preSelectedOptions, setPreSelectedOptions] = useState(selectedOptions);

  const sortDisplayOptions = (options: SelectOption[]) => {
    return _.sortBy(
      options,
      o => (selectedOptions.has(o.id.toString()) ? 0 : 1),
      o => o[sortBy]
    );
  };

  const filterDisplayedOptions = (value: string) => {
    setSearchInput(value);
    debouncedFilterDisplayedOptions(value);
  };

  const debouncedFilterDisplayedOptions = useCallback(
    _.debounce((value: string) => {
      const filteredOptions = options.filter(o =>
        o.value.toLowerCase().includes(value.toLowerCase())
      );

      setDisplayOptions(filteredOptions);
    }, 500),
    []
  );

  const applyFilter = () => {
    if (onFilterApplied) {
      onFilterApplied();
    }
    searchParams.delete(paramName);
    searchParams.set(SEARCH_PARAMS.PAGE, '1');
    preSelectedOptions.forEach(value =>
      searchParams.append(paramName, value.toString())
    );
    setSearchParams(searchParams);
    toggleMenu();
  };

  const resetFilter = () => {
    if (onFilterApplied) {
      onFilterApplied();
    }
    searchParams.delete(paramName);
    searchParams.set(SEARCH_PARAMS.PAGE, '1');

    setPreSelectedOptions(new Set());
    setSearchParams(searchParams);
  };

  const handleFilterChange = (id: string) => {
    const newValues = new Set(preSelectedOptions);

    if (newValues.has(id)) {
      newValues.delete(id);
    } else {
      newValues.add(id);
    }

    setPreSelectedOptions(newValues);
  };

  const getIcon = () => {
    if (selectedOptionsCount) {
      return <CloseIcon height='0.8rem' />;
    }

    return open ? <ChevronUpIcon /> : <ChevronDownIcon />;
  };

  const toggleMenu = () => {
    if (open) {
      setSearchInput('');
      setDisplayOptions(options);
      ref.current?.scroll({ top: 0 });
    }

    setOpen(!open);
  };

  const handleIconClick = () => {
    if (selectedOptionsCount) {
      resetFilter();
    } else {
      toggleMenu();
    }
  };

  const sortedOptions = useMemo(
    () => sortDisplayOptions(displayOptions),
    [displayOptions, selectedOptions]
  );

  return (
    <Menu isOpen={open}>
      <ButtonGroup isAttached>
        <MenuButton
          as={Button}
          variant={selectedOptionsCount ? 'solid' : 'outline'}
          onClick={() => toggleMenu()}
          {...props}
        >
          <HStack>
            <span>By {title ?? paramName} </span>
            {selectedOptionsCount && (
              <Circle size='1.5rem' bg='orange.300'>
                {selectedOptionsCount}
              </Circle>
            )}
          </HStack>
        </MenuButton>
        <IconButton
          onClick={() => handleIconClick()}
          icon={getIcon()}
          variant='outline'
          aria-label='menu toggle/reset'
        />
      </ButtonGroup>
      <MenuList
        maxW={'container.lg'}
        p={{ base: '0.5rem', md: '1rem' }}
        width={{ base: '22rem', md: '25rem' }}
      >
        <InputGroup mb='1rem'>
          <Input
            type='text'
            placeholder={`Start to type name of ${title ?? paramName} here`}
            value={searchInput}
            onChange={e => filterDisplayedOptions(e.target.value)}
          />
          <InputLeftElement
            children={
              <IconButton
                aria-label='search field'
                icon={<Search2Icon />}
                variant='unstyled'
              />
            }
          />
        </InputGroup>
        <Box
          pl='2px'
          overflow='auto'
          height={{ base: '14rem', md: '20rem' }}
          ref={ref}
        >
          <ViewportList viewportRef={ref} items={sortedOptions}>
            {option => (
              <Box key={option.id}>
                <Checkbox
                  isChecked={preSelectedOptions.has(option.id.toString())}
                  onChange={() => handleFilterChange(option.id.toString())}
                  value={option.id}
                >
                  {option.value}
                </Checkbox>
              </Box>
            )}
          </ViewportList>
        </Box>
        <MenuDivider />
        <Box>
          <Button width='100%' onClick={applyFilter}>
            Apply
          </Button>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default CheckboxFilter;
