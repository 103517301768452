import { Box, Flex, Text, Link as ChakraLink } from '@chakra-ui/react';
import MatchInfo from '../../models/stage/matchInfo';
import { Handle, NodeProps, Position } from 'reactflow';
import { Link } from 'react-router-dom';
import { replaceUrlParams } from '../../utils/routerUtils';
import { ROUTES } from '../../constants';

type SingleEliminationMatchCardProps = {
  match: MatchInfo;
};

const SingleEliminationMatchCard = (
  props: NodeProps<SingleEliminationMatchCardProps>
) => {
  const { match } = props.data;
  return (
    <>
      <Handle
        type='target'
        style={{ visibility: 'hidden' }}
        position={Position.Top}
      />
      <Box width='12.5rem' style={{ cursor: 'auto' }}>
        <Text fontSize='0.75rem'>{match.roundName}</Text>
        <Box borderWidth={1} p={1} borderRadius={8}>
          <Flex gap='0.2rem' alignItems='center' height='1.25rem'>
            <Text fontSize='0.75rem' width='1.5rem'>
              {match.firstPlayerSeed}
            </Text>
            <ChakraLink
              fontWeight={
                match.firstPlayerId === match.winningPlayerId ? 'bold' : ''
              }
              as={Link}
              to={replaceUrlParams(ROUTES.PLAYER_PROFILE, {
                id: match.firstPlayerId
              })}
            >
              {match.firstPlayerName}
            </ChakraLink>
          </Flex>
          <Flex
            borderTopWidth={1}
            gap='0.2rem'
            alignItems='center'
            height='1.25rem'
          >
            <Text fontSize='0.75rem' width='1.5rem'>
              {match.secondPlayerSeed}
            </Text>
            <ChakraLink
              fontWeight={
                match.secondPlayerId === match.winningPlayerId ? 'bold' : ''
              }
              as={Link}
              to={replaceUrlParams(ROUTES.PLAYER_PROFILE, {
                id: match.secondPlayerId
              })}
            >
              {match.secondPlayerName}
            </ChakraLink>
          </Flex>
        </Box>
      </Box>
      <Handle
        type='source'
        style={{ visibility: 'hidden' }}
        position={Position.Bottom}
      />
    </>
  );
};

export default SingleEliminationMatchCard;
