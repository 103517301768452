export function replaceUrlParams(url: string, params: Record<string, any>): string {
    let replacedUrl = url;
  
    Object.keys(params).forEach((param) => {
      const paramValue = params[param]?.toString();
      const paramSegment = `:${param}`;
  
      replacedUrl = replacedUrl.replace(paramSegment, paramValue);
    });
  
    return replacedUrl;
  }