import { Box, Button, Flex, Heading, Text, Tooltip } from '@chakra-ui/react';
import Poll from '../../models/polls/poll';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { selectUserLogged } from '../../store/userSlice';
import { useAppSelector } from '../../hooks/hooks';

type PollCardProps = {
  poll: Poll;
  onSubmitVote: (pollId: string, optionId: string) => void;
};

const PollCard = ({ poll, onSubmitVote }: PollCardProps) => {
  const userLoggedIn = useAppSelector(selectUserLogged);

  return (
    <Flex flexDir='column' gap={1} borderRadius={8} borderWidth={1} p={3}>
      <Text whiteSpace='pre-line'>{poll.description}</Text>
      <Heading size='s'>{poll.question}</Heading>

      {poll.options.map(o => {
        const percentage = poll.votesCount
          ? Math.round((o.votesCount / poll.votesCount) * 100)
          : 0;

        return (userLoggedIn && poll.currentUserVote) || !poll.active ? (
          <Flex key={o.id} height='var(--chakra-sizes-8)' gap='1rem'>
            <Flex width='100%' position='relative'>
              <Box
                width={`${percentage > 0 ? percentage : 1}%`}
                backgroundColor='gray.200'
                borderRadius={4}
              ></Box>
              <Flex
                position='absolute'
                ml={2}
                alignItems='center'
                gap='0.5rem'
                height='100%'
              >
                <Text>{o.value}</Text>
                {poll.currentUserVote?.optionId === o.id && (
                  <CheckCircleIcon color='gray' />
                )}
              </Flex>
            </Flex>
            <Text>{percentage}%</Text>
          </Flex>
        ) : (
          <Tooltip
            key={o.id}
            label='Please login to vote'
            isDisabled={userLoggedIn}
          >
            <Button
              variant='outline'
              size='sm'
              isDisabled={!userLoggedIn}
              onClick={() => onSubmitVote(poll.id, o.id)}
            >
              {o.value}
            </Button>
          </Tooltip>
        );
      })}
      <Flex fontSize='small' justifyContent='space-between'>
        {poll.active ? (
          <Text>
            {poll.daysLeft} day{poll.daysLeft === 1 ? '' : 's'} left
          </Text>
        ) : (
          <Text>Ended</Text>
        )}
        <Text>
          {poll.votesCount} vote{poll.votesCount === 1 ? '' : 's'}
        </Text>
      </Flex>
    </Flex>
  );
};

export default PollCard;
