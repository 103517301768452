import { Box, Flex, Skeleton, Wrap } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { SEARCH_PARAMS } from '../../constants';
import SelectOption from '../../models/utils/selectOption';
import {
  getSelectOptions,
  getTournamentDefinitions
} from '../../services/lookupService';
import CheckboxFilter from '../filters/CheckboxFilter';
import SearchFilter from '../filters/SearchFilter';
import ToggleFilter from '../filters/ToggleFilter';
import DateFilter from '../filters/DateFilter';
import GameResultsFilterDrawer from './GameResultsFilterDrawer';
import TournamentDefinitionSelectType from '../../enums/tournamentDefinitionSelectType';
import { LOOKUP_TYPES } from '../../constants/lookupTypes';

const GameResultsFilterPanel = () => {
  const [players, setPlayers] = useState([] as SelectOption[]);
  const [tournaments, setTournaments] = useState([] as SelectOption[]);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      const players = await getSelectOptions(LOOKUP_TYPES.PLAYERS);
      const tournaments = await getTournamentDefinitions(
        TournamentDefinitionSelectType.All
      );

      setPlayers(players);
      setTournaments(tournaments);
      setLoaded(true);
    })();
  }, []);

  return (
    <Box py={2} px={5} borderWidth={1} borderRadius={8} boxShadow='lg'>
      <Flex
        flexDir={{ base: 'column', xl: 'row' }}
        justifyContent='space-between'
        display={{ base: 'none', md: 'flex' }}
        gap={{ base: '1rem', xl: 0 }}
      >
        <Wrap gap='0.2rem'>
          <Skeleton isLoaded={isLoaded} borderRadius={8}>
            <CheckboxFilter
              paramName={SEARCH_PARAMS.PLAYER}
              options={players}
              h='2.5rem'
            />
          </Skeleton>
          <Skeleton isLoaded={isLoaded} borderRadius={8}>
            <CheckboxFilter
              paramName={SEARCH_PARAMS.TOURNAMENT}
              options={tournaments}
              sortBy='id'
              h='2.5rem'
            />
          </Skeleton>
          <Skeleton isLoaded={isLoaded} borderRadius={8}>
            <DateFilter showMonthDropdown showYearDropdown h='2.5rem' />
          </Skeleton>
          <Skeleton isLoaded={isLoaded} borderRadius={8}>
            <ToggleFilter
              paramName={SEARCH_PARAMS.WITH}
              value='video'
              h='2.5rem'
            />
          </Skeleton>
        </Wrap>
        <Box>
          <Skeleton isLoaded={isLoaded} borderRadius={8} maxW='16rem'>
            <SearchFilter paramName={SEARCH_PARAMS.IDENTIFIER} />
          </Skeleton>
        </Box>
      </Flex>
      <Flex display={{ base: 'flex', md: 'none' }} gap='1rem'>
        <GameResultsFilterDrawer players={players} tournaments={tournaments} />
      </Flex>
    </Box>
  );
};

export default GameResultsFilterPanel;
