import { useToast } from '@chakra-ui/react';

const useFeedbackRequest = (
  action: () => Promise<void>,
  beforeRequest: () => void,
  afterRequest: () => void,
  successMessage: string,
  onSuccess?: () => void
): (() => Promise<void>) => {
  const toast = useToast();

  const request = async () => {
    try {
      beforeRequest();
      await action();
      afterRequest();

      if (onSuccess) onSuccess();

      toast({
        title: 'Success',
        description: successMessage,
        status: 'success'
      });
    } catch (error: any) {
      toast({
        title: 'Error',
        description: `${error.message}`,
        status: 'error'
      });
    }
  };

  return request;
};

export default useFeedbackRequest;
