import { Link } from 'react-router-dom';
import {
  Container,
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  HStack,
  Checkbox,
  Text
} from '@chakra-ui/react';

import { Formik, FormikProps, Form, Field } from 'formik';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { ROUTES } from '../../constants';
import styleConstants from '../../constants/styleConstants';
import { useAppDispatch } from '../../hooks/hooks';
import useFullPageLoader from '../../hooks/useFullPageLoader';
import Login from '../../models/authentication/login';
import { login } from '../../services/accountService';
import { userLoggedIn } from '../../store/userSlice';
import { LogInValidationSchema } from '../../validation/logInValidationSchema';
import Error from '../../components/Error';

export const LogInView = () => {
  const initialValues: Login = {
    email: '',
    password: '',
    rememberMe: false
  };

  const dispatch = useAppDispatch();

  const [errorMessage, setErrorMessage] = useState('');
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const handleSubmit = async (value: Login) => {
    showLoader();

    try {
      setErrorMessage('');
      const user = await login(value);

      dispatch(userLoggedIn({ ...user }));
    } catch (error: any) {
      setErrorMessage(error.message);
    }

    hideLoader();
  };

  return (
    <>
      <Helmet>
        <title>Log In</title>
      </Helmet>
      <Container maxW={'container.md'} my={styleConstants.viewMY}>
        <Flex align='center' justifyContent='center'>
          <Box
            p={12}
            width={'500px'}
            borderWidth={1}
            borderRadius={8}
            boxShadow='lg'
          >
            <Box textAlign='center'>
              <Heading mb={8} size='lg'>
                Log in to your account
              </Heading>
            </Box>
            <HStack spacing='4' justify='center'>
              <Text>Don't have an account?</Text>
              <Button
                as={Link}
                to={ROUTES.SIGNUP}
                variant='link'
                colorScheme='teal'
              >
                Sign up
              </Button>
            </HStack>
            <Box mt={4} textAlign='left'>
              <Formik
                initialValues={initialValues}
                validationSchema={LogInValidationSchema}
                onSubmit={async values => handleSubmit(values)}
              >
                {(props: FormikProps<Login>) => (
                  <Form>
                    <Field name='email'>
                      {({ form, field }: any) => (
                        <FormControl
                          mt={6}
                          isInvalid={form.errors?.email && form.touched?.email}
                        >
                          <FormLabel>Email:</FormLabel>
                          <Input
                            type='email'
                            placeholder='test@test.com'
                            {...field}
                          />
                          <FormErrorMessage>
                            {form.errors?.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='password'>
                      {({ form, field }: any) => (
                        <FormControl
                          mt={6}
                          isInvalid={
                            form.errors?.password && form.touched?.password
                          }
                        >
                          <FormLabel>Password:</FormLabel>
                          <Input
                            type='password'
                            placeholder='*******'
                            {...field}
                          />
                          <FormErrorMessage>
                            {form.errors?.password}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <HStack justify='space-between' pt={4}>
                      <Field name='rememberMe'>
                        {({ form, field }: any) => (
                          <FormControl>
                            <Checkbox {...field}>Remember me</Checkbox>
                          </FormControl>
                        )}
                      </Field>
                      <Button
                        as={Link}
                        variant='link'
                        colorScheme='teal'
                        size='sm'
                        to={ROUTES.PASSWORD_RESET}
                      >
                        Forgot password?
                      </Button>
                    </HStack>
                    {errorMessage && <Error error={errorMessage}></Error>}
                    <Box textAlign={'center'}>
                      <Button
                        colorScheme='teal'
                        variant='outline'
                        width='36'
                        textAlign={'center'}
                        mt={10}
                        type='submit'
                      >
                        Log In
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Flex>
      </Container>
      <>{loader}</>
    </>
  );
};

export default LogInView;
