import { Button, Flex } from '@chakra-ui/react';
import TournamentScheduleFilterDrawer from './TournamentScheduleFilterDrawer';
import { SmallAddIcon } from '@chakra-ui/icons';
import { MdOutlineFileUpload } from 'react-icons/md';
import { SEARCH_PARAMS, ROUTES } from '../../constants';
import { PERMISSIONS } from '../../constants/permissions';
import GameStatus from '../../enums/gameStatus';
import OrderDirection from '../../enums/orderDirection';
import ScopeLevel from '../../models/authentication/scopeLevel';
import { replaceUrlParams } from '../../utils/routerUtils';
import RadioFilter from '../filters/RadioFilter';
import PermissionGuard from '../guards/PermissionGuard';
import SingleSort from '../sorters/SingleSort';
import { Link } from 'react-router-dom';

type TournamentScheduleFiltersPanelProps = {
  tournamentId: number;
};

const TournamentScheduleFiltersPanel = ({
  tournamentId
}: TournamentScheduleFiltersPanelProps) => {
  return (
    <>
      <Flex
        flexWrap='wrap'
        flexDir='row'
        gap='0.5rem'
        display={{ base: 'none', md: 'flex' }}
      >
        <RadioFilter
          paramName={SEARCH_PARAMS.STATUS}
          title='Show'
          options={
            [GameStatus.Unplayed, GameStatus.Played, GameStatus.All] as string[]
          }
          defaultValue={GameStatus.Unplayed}
        />
        <SingleSort
          options={[
            { id: 'dueDate', value: 'Due date' },
            { id: 'identifier', value: 'Identifier' }
          ]}
          defaultValue='dueDate'
          defaultOrderDirection={OrderDirection.Ascending}
        />
        <PermissionGuard
          requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
          requiredScope={ScopeLevel.Tournament}
          requiredResourceId={tournamentId}
        >
          <Button
            leftIcon={<SmallAddIcon />}
            as={Link}
            to={replaceUrlParams(ROUTES.GAME_CREATE, { tournamentId })}
          >
            Create game
          </Button>
          <Button
            leftIcon={<MdOutlineFileUpload />}
            as={Link}
            to={replaceUrlParams(ROUTES.GAME_IMPORT, { tournamentId })}
          >
            Import games
          </Button>
        </PermissionGuard>
      </Flex>
      <Flex display={{ base: 'flex', md: 'none' }}>
        <TournamentScheduleFilterDrawer tournamentId={tournamentId} />
      </Flex>
    </>
    // </Box>
  );
};

export default TournamentScheduleFiltersPanel;
