export const LOOKUP_TYPES = {
  GAME_END_TURNS: 'Game End Turn',
  GAME_END_TYPES: 'Game End Type',
  GAME_DURATIONS: 'Game Duration',
  GAMING_PLATFORMS: 'Gaming Platform',
  TOURNAMENT_FORMATS: 'Tournament Format',
  PLAYERS: 'Players',
  COUNTRIES: 'Countries',
  REGIONAL_FEDERATIONS: 'RegionalFederations'
};
