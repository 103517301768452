import { useEffect, useState } from 'react';
import { Container, Flex, Skeleton } from '@chakra-ui/react';
import { getPlayersRatingInfo } from '../services/playerService';
import { Link, useSearchParams } from 'react-router-dom';
import PlayersRatingFiltersPanel from '../components/filterPanels/PlayersRatingFiltersPanel';
import Pagination from '../components/pagination/Pagination';
import PlayerRatingCard from '../components/PlayerRatingCard';
import { ROUTES } from '../constants';
import { replaceUrlParams } from '../utils/routerUtils';
import { Helmet } from 'react-helmet';
import PlayerRatingInfo from '../models/player/playerRatingInfo';
import styleConstants from '../constants/styleConstants';

const PlayersRatingView = () => {
  const [data, setData] = useState<PlayerRatingInfo[] | null>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams] = useSearchParams();

  const loadPlayers = async (searchParams: URLSearchParams) => {
    const playersData = await getPlayersRatingInfo(searchParams);
    setData(playersData?.items);
    setTotalPages(playersData?.totalPages);
  };

  useEffect(() => {
    loadPlayers(searchParams);
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Rating</title>
      </Helmet>
      <Container
        maxW={'container.lg'}
        my={styleConstants.viewMY}
        p={{ base: 1, sm: 5 }}
      >
        <PlayersRatingFiltersPanel />
        <Flex
          flexDir='column'
          borderWidth={{ base: 0, sm: 1 }}
          p={{ base: 0, sm: 5 }}
          borderRadius={8}
          boxShadow={{ base: 'none', sm: 'lg' }}
          gap='0.5rem'
        >
          {data ? (
            data.map(rating => (
              <Link
                to={replaceUrlParams(ROUTES.PLAYER_PROFILE, { id: rating.id })}
                key={rating.id}
              >
                <PlayerRatingCard rating={rating} />
              </Link>
            ))
          ) : (
            <>
              {[...Array(15)].map((_value, index) => (
                <Skeleton
                  h={{ base: '4rem', md: '3rem' }}
                  key={index}
                  borderRadius={6}
                />
              ))}
            </>
          )}
        </Flex>
      </Container>
      <Pagination totalPages={totalPages} />
    </>
  );
};

export default PlayersRatingView;
