import { CloseIcon } from '@chakra-ui/icons';
import { Button, ButtonGroup, ChakraProps, IconButton } from '@chakra-ui/react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../../constants';

interface ToggleFilterProps extends ChakraProps {
  title?: string;
  paramName: string;
  value: string;
  onFilterApplied?: () => void;
}

const ToggleFilter = ({
  title,
  paramName,
  value,
  onFilterApplied,
  ...props
}: ToggleFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [toggled, setToggled] = useState(searchParams.get(paramName) === value);

  const toggle = () => {
    if (onFilterApplied) {
      onFilterApplied();
    }
    if (toggled) {
      resetFilter();
    } else {
      applyFilter();
    }
    setToggled(!toggled);
  };

  const applyFilter = () => {
    searchParams.set(paramName, value);
    searchParams.set(SEARCH_PARAMS.PAGE, '1');
    setSearchParams(searchParams);
  };

  const resetFilter = () => {
    searchParams.delete(paramName);
    searchParams.set(SEARCH_PARAMS.PAGE, '1');
    setSearchParams(searchParams);
  };

  return (
    <ButtonGroup isAttached>
      <Button
        {...props}
        onClick={toggle}
        variant={toggled ? 'solid' : 'outline'}
        justifyContent='start'
      >
        With {title ?? value}
      </Button>
      {toggled && (
        <IconButton
          icon={<CloseIcon height='0.8rem' />}
          variant='outline'
          aria-label='filter toggle/reset'
          onClick={toggle}
        />
      )}
    </ButtonGroup>
  );
};

export default ToggleFilter;
