import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Flex,
  Box,
  Text,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Textarea,
  FormHelperText,
  useToast
} from '@chakra-ui/react';

import { Formik, Form, Field } from 'formik';

import { SignUpValidationSchema } from '../validation/signUpValidationSchema';
import { ROUTES } from '../constants';
import useFullPageLoader from '../hooks/useFullPageLoader';
import Error from '../components/Error';
import SelectOption from '../models/utils/selectOption';
import {
  getCities,
  getSelectOptions,
  getTournaments
} from '../services/lookupService';
import { Helmet } from 'react-helmet';
import SignUpFormValue from '../models/forms/signUpFormValue';
import SelectInput from '../components/selectInput/SelectInput';
import { submitSignUpRequest } from '../services/signUpRequestService';
import FORM_HELPER_MESSAGES from '../constants/formHelperMessages';
import AsyncSelectInput from '../components/selectInput/AsyncSelectInput';
import styleConstants from '../constants/styleConstants';
import TournamentSelectType from '../enums/tournamentsSelectType';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LOOKUP_TYPES } from '../constants/lookupTypes';

const SignUpView = () => {
  const initialValues: SignUpFormValue = {
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    nickname: '',
    countryId: '',
    regionalFederationId: '',
    phoneNumber: '',
    cityId: 0,
    preferredGamingPlatformId: 0,
    preferredGameDurationId: 0,
    intendedTournamentId: 0
  };

  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const toast = useToast();

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');

  const [countriesSelectOptions, setCountriesSelectOptions] = useState<
    SelectOption[]
  >([]);

  const [citiesSelectOptions, setCitiesSelectOptions] = useState<
    SelectOption[]
  >([]);

  const [gamingPlatformSelectOptions, setGamingPlatformSelectOptions] =
    useState<SelectOption[]>([]);

  const [gameDurationSelectOptions, setGameDurationSelectOptions] = useState<
    SelectOption[]
  >([]);

  const [tournamentSelectOptions, setTournamentSelectOptions] = useState<
    SelectOption[]
  >([]);

  const [
    regionalFederationsSelectOptions,
    setRegionalFederationsSelectOptions
  ] = useState<SelectOption[]>([]);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    (async () => {
      const countries = await getSelectOptions(LOOKUP_TYPES.COUNTRIES);
      const cities = await getCities();
      const gamingPlatforms = await getSelectOptions(
        LOOKUP_TYPES.GAMING_PLATFORMS
      );
      const gameDurations = await getSelectOptions(LOOKUP_TYPES.GAME_DURATIONS);
      const tournamentTypes = await getTournaments(
        TournamentSelectType.Registrable
      );
      const regionalFederations = await getSelectOptions(
        LOOKUP_TYPES.REGIONAL_FEDERATIONS
      );

      setCountriesSelectOptions(countries);
      setCitiesSelectOptions(cities);
      setGamingPlatformSelectOptions(gamingPlatforms);
      setGameDurationSelectOptions(gameDurations);
      setTournamentSelectOptions(tournamentTypes);
      setRegionalFederationsSelectOptions(regionalFederations);
    })();
  }, []);

  const handleSubmit = async (values: SignUpFormValue) => {
    showLoader();

    try {
      let token = '';

      if (executeRecaptcha) {
        token = await executeRecaptcha('SignUp');
      }

      await submitSignUpRequest({ ...values, reCaptchaToken: token! });

      hideLoader();
      toast({
        title: 'Sign up request submitted',
        description:
          'Your request was successfully submitted. You will get an email notification as soon as your request is approved by administrator',
        status: 'success'
      });
      navigate(ROUTES.HOME);
    } catch (error: any) {
      hideLoader();
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <Container maxW={'container.md'} my={styleConstants.viewMY}>
        <Flex width='full' align='center' justifyContent='center'>
          <Box
            p={12}
            maxWidth='700px'
            borderWidth={1}
            borderRadius={8}
            boxShadow='lg'
          >
            <Box textAlign='center'>
              <Heading mb={4} size='lg'>
                Join the game!
              </Heading>
            </Box>
            <Box textAlign='center' mb={8}>
              <Text>
                Create an account to start your amazing journey in tournament
                and playing against people from all over the world!
              </Text>
            </Box>
            <Box mt={4} textAlign='left'>
              <Formik
                initialValues={initialValues}
                validationSchema={SignUpValidationSchema}
                onSubmit={(values, actions) => {
                  handleSubmit(values);
                }}
              >
                {(props: any) => (
                  <Form>
                    <Field name='firstName'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          isInvalid={
                            form.errors?.firstName && form.touched?.firstName
                          }
                        >
                          <FormLabel>First name:</FormLabel>
                          <Input
                            type='text'
                            placeholder='Enter firstname'
                            {...field}
                          />
                          <FormErrorMessage>
                            {form.errors?.firstName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='lastName'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          mt={6}
                          isInvalid={
                            form.errors?.lastName && form.touched?.lastName
                          }
                        >
                          <FormLabel>Last name:</FormLabel>
                          <Input
                            type='text'
                            placeholder='Enter lastname'
                            {...field}
                          />
                          <FormErrorMessage>
                            {form.errors?.lastName}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='email'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          mt={6}
                          isInvalid={form.errors?.email && form.touched?.email}
                        >
                          <FormLabel>Email:</FormLabel>
                          <Input
                            type='email'
                            placeholder='test@test.com'
                            {...field}
                          />
                          <FormErrorMessage>
                            {form.errors?.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='confirmEmail'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          mt={6}
                          isInvalid={
                            form.errors?.confirmEmail &&
                            form.touched?.confirmEmail
                          }
                        >
                          <FormLabel>Confirm email:</FormLabel>
                          <Input
                            type='email'
                            placeholder='test@test.com'
                            {...field}
                            onPaste={event => event.preventDefault()}
                          />
                          <FormErrorMessage>
                            {form.errors?.confirmEmail}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='nickname'>
                      {({ form, field }: any) => (
                        <FormControl
                          mt={6}
                          isInvalid={
                            form.errors?.nickname && form.touched?.nickname
                          }
                        >
                          <FormLabel>Playdek Name:</FormLabel>
                          <Input
                            type='text'
                            placeholder='Enter Playdek Name'
                            {...field}
                          />
                          <FormErrorMessage>
                            {form.errors?.nickname}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='countryId'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          mt={6}
                          isInvalid={
                            form.errors?.countryId && form.touched?.countryId
                          }
                        >
                          <FormLabel>Country:</FormLabel>
                          <SelectInput
                            value={field.value}
                            onChange={value =>
                              form.setFieldValue(field.name, value)
                            }
                            options={countriesSelectOptions}
                            isSearchable
                            placeholder='Choose a country'
                            isInvalid={
                              form.errors?.countryId && form.touched?.countryId
                            }
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors?.countryId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='regionalFederationId'>
                      {({ form, field }: any) => (
                        <FormControl
                          mt={6}
                          isInvalid={
                            form.errors?.regionalFederationId &&
                            form.touched?.regionalFederationId
                          }
                        >
                          <FormLabel>Regional Federation:</FormLabel>
                          <FormHelperText mb='.3rem'>
                            {FORM_HELPER_MESSAGES.REGIONAL_FEDERATION}
                          </FormHelperText>
                          <SelectInput
                            value={field.value}
                            onChange={value =>
                              form.setFieldValue(field.name, value)
                            }
                            options={regionalFederationsSelectOptions}
                            isSearchable
                            placeholder='Choose a regional federation'
                            isInvalid={
                              form.errors?.regionalFederationId &&
                              form.touched?.regionalFederationId
                            }
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors?.regionalFederationId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='phoneNumber'>
                      {({ form, field }: any) => (
                        <FormControl
                          mt={6}
                          isInvalid={
                            form.errors?.phoneNumber &&
                            form.touched?.phoneNumber
                          }
                        >
                          <FormLabel>Phone number:</FormLabel>
                          <FormHelperText mb='.3rem'>
                            {FORM_HELPER_MESSAGES.PHONE_NUMBER}
                          </FormHelperText>
                          <Input
                            type='text'
                            placeholder='Enter phone number (example: +123456789)'
                            {...field}
                          />
                          <FormErrorMessage>
                            {form.errors?.phoneNumber}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='cityId'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          mt={6}
                          isInvalid={
                            form.errors?.cityId && form.touched?.cityId
                          }
                        >
                          <FormLabel>City:</FormLabel>
                          <FormHelperText mb='.3rem'>
                            {FORM_HELPER_MESSAGES.CITY}
                          </FormHelperText>
                          <FormHelperText mb='.3rem'>
                            {FORM_HELPER_MESSAGES.CITY_NOTE}
                          </FormHelperText>
                          <AsyncSelectInput
                            value={field.value}
                            onChange={value =>
                              form.setFieldValue(field.name, value)
                            }
                            isSearchable
                            placeholder='Choose a city'
                            isInvalid={
                              form.errors?.cityId && form.touched?.cityId
                            }
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                            defaultOptions={citiesSelectOptions}
                            getOptions={s => getCities(s)}
                          />
                          <FormErrorMessage>
                            {form.errors?.cityId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='preferredGamingPlatformId'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          mt={6}
                          isInvalid={
                            form.errors?.preferredGamingPlatformId &&
                            form.touched?.preferredGamingPlatformId
                          }
                        >
                          <FormLabel>Preferred gaming platform:</FormLabel>
                          <SelectInput
                            value={field.value}
                            onChange={value =>
                              form.setFieldValue(field.name, value)
                            }
                            options={gamingPlatformSelectOptions}
                            isSearchable={false}
                            placeholder='Choose a gaming platform'
                            isInvalid={
                              form.errors?.preferredGamingPlatformId &&
                              form.touched?.preferredGamingPlatformId
                            }
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors?.preferredGamingPlatformId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='preferredGameDurationId'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          mt={6}
                          isInvalid={
                            form.errors?.preferredGameDurationId &&
                            form.touched?.preferredGameDurationId
                          }
                        >
                          <FormLabel>Preferred game duration:</FormLabel>
                          <SelectInput
                            value={field.value}
                            onChange={value =>
                              form.setFieldValue(field.name, value)
                            }
                            options={gameDurationSelectOptions}
                            isSearchable={false}
                            placeholder='Choose game duration'
                            isInvalid={
                              form.errors?.preferredGameDurationId &&
                              form.touched?.preferredGameDurationId
                            }
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors?.preferredGameDurationId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='intendedTournamentId'>
                      {({ form, field }: any) => (
                        <FormControl
                          isRequired
                          mt={6}
                          isInvalid={
                            form.errors?.intendedTournamentId &&
                            form.touched?.intendedTournamentId
                          }
                        >
                          <FormLabel>Intended tournament:</FormLabel>
                          <SelectInput
                            value={field.value}
                            onChange={value =>
                              form.setFieldValue(field.name, value)
                            }
                            options={tournamentSelectOptions}
                            isSearchable={false}
                            placeholder='Choose a tournament'
                            isInvalid={
                              form.errors?.intendedTournamentId &&
                              form.touched?.intendedTournamentId
                            }
                            onBlur={() => {
                              form.setFieldTouched(field.name, true);
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors?.intendedTournamentId}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='comment'>
                      {({ form, field }: any) => (
                        <FormControl
                          mt={6}
                          isInvalid={
                            form.errors?.comment && form.touched?.comment
                          }
                        >
                          <FormLabel>Comment:</FormLabel>
                          <Textarea
                            placeholder='Write a comment'
                            {...field}
                          ></Textarea>
                          <FormErrorMessage>
                            {form.errors?.comment}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    {errorMessage && <Error error={errorMessage}></Error>}
                    <Box textAlign={'center'}>
                      <Button
                        colorScheme='teal'
                        variant='outline'
                        width='36'
                        textAlign={'center'}
                        mt={6}
                        type='submit'
                      >
                        Sign Up
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Flex>
      </Container>
      <>{loader}</>
    </>
  );
};

export default SignUpView;
