import ReactFlow, {
  useEdgesState,
  useNodesInitialized,
  useNodesState,
  useReactFlow
} from 'reactflow';
import MatchInfo from '../../models/stage/matchInfo';
import SingleEliminationMatchCard from './SingleEliminationMatchCard';
import { Box } from '@chakra-ui/react';
import 'reactflow/dist/style.css';
import { useEffect, useState } from 'react';
import Dagre from '@dagrejs/dagre';

type SingleEliminationBracketProps = {
  matches: MatchInfo[];
};

const nodeTypes = {
  match: SingleEliminationMatchCard
};

const getLayoutedElements = (nodes: any[], edges: any[]) => {
  const graph = new Dagre.graphlib.Graph({
    compound: true
  }).setDefaultEdgeLabel(() => ({}));

  graph.setGraph({
    rankdir: 'TB',
    nodesep: 16,
    ranksep: 16
  });

  edges.forEach(edge => graph.setEdge(edge.source, edge.target));
  nodes.forEach(node => graph.setNode(node.id, node));

  const groupedNodeId = 'groupedNode';
  graph.setNode(groupedNodeId, { height: 0, width: 0 });
  nodes
    .filter(n => !n.nextMatchId)
    .forEach(node => graph.setParent(node.id, groupedNodeId));

  Dagre.layout(graph);

  const positionedNodes = nodes.map(node => {
    const { x, y } = graph.node(node.id);

    return {
      ...node,
      position: { x: x - node.width / 2, y: y - node.height / 2 }
    };
  });

  return {
    positionedNodes,
    positionedEdges: edges
  };
};

const SingleEliminationBracket = ({
  matches
}: SingleEliminationBracketProps) => {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [initialized, setInitialized] = useState(false);

  const { getNodes } = useReactFlow();
  const nodesInitialized = useNodesInitialized();

  useEffect(() => {
    setInitialized(false);
    const edges = [];
    const nodes = [];

    for (const match of matches) {
      nodes.push({
        id: match.id.toString(),
        type: 'match',
        data: { match },
        position: { x: 0, y: 0 }
      });

      if (match.nextMatchId) {
        edges.push({
          id: `${match.id}${match.nextMatchId}`,
          source: match.id.toString(),
          target: match.nextMatchId.toString()
        });
      }
    }

    setNodes(nodes);
    setEdges(edges);
  }, [matches]);

  useEffect(() => {
    if (nodesInitialized) {
      const { positionedNodes, positionedEdges } = getLayoutedElements(
        getNodes(),
        edges
      );

      setNodes(positionedNodes);
      setEdges(positionedEdges);
      setInitialized(true);
    }
  }, [nodesInitialized]);

  return (
    <Box height='75vh' opacity={initialized ? 1 : 0}>
      <ReactFlow
        nodes={nodes}
        nodeTypes={nodeTypes}
        edges={edges}
        edgesFocusable={false}
        edgesUpdatable={false}
        defaultEdgeOptions={{ type: 'step' }}
        proOptions={{ hideAttribution: true }}
      ></ReactFlow>
    </Box>
  );
};

export default SingleEliminationBracket;
