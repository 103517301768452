import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { PiSortAscendingLight, PiSortDescendingLight } from 'react-icons/pi';
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  ButtonGroup,
  IconButton,
  ButtonProps
} from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import OrderDirection from '../../enums/orderDirection';
import { SEARCH_PARAMS } from '../../constants';
import SelectOption from '../../models/utils/selectOption';

type SingleSortProps = {
  options: SelectOption[];
  defaultValue: string;
  defaultOrderDirection: OrderDirection;
  onFilterApplied?: () => void;
} & ButtonProps;

const SingleSort = ({
  options,
  defaultValue,
  defaultOrderDirection,
  onFilterApplied,
  ...props
}: SingleSortProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramValue = searchParams.get(SEARCH_PARAMS.SORT_BY);

  const currentValue =
    paramValue && options.some(o => o.id === paramValue)
      ? paramValue
      : defaultValue;

  const paramOrderDirection = searchParams.get(SEARCH_PARAMS.SORT_ORDER);

  const currentOrderDirection = paramOrderDirection
    ? paramOrderDirection
    : defaultOrderDirection;

  const applyFilter = (value: string) => {
    if (onFilterApplied) {
      onFilterApplied();
    }

    searchParams.set(SEARCH_PARAMS.PAGE, '1');
    searchParams.set(SEARCH_PARAMS.SORT_BY, value);
    setSearchParams(searchParams);
  };

  const changeOrderDirection = () => {
    if (onFilterApplied) {
      onFilterApplied();
    }

    const newSortOrder =
      currentOrderDirection === OrderDirection.Ascending
        ? OrderDirection.Descending
        : OrderDirection.Ascending;

    searchParams.set(SEARCH_PARAMS.PAGE, '1');
    searchParams.set(SEARCH_PARAMS.SORT_ORDER, newSortOrder);
    setSearchParams(searchParams);
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <ButtonGroup isAttached>
            <MenuButton
              {...props}
              as={Button}
              variant='outline'
              rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            >
              Sort by
            </MenuButton>
            <IconButton
              onClick={() => changeOrderDirection()}
              icon={
                currentOrderDirection === OrderDirection.Ascending ? (
                  <PiSortAscendingLight size='1rem' />
                ) : (
                  <PiSortDescendingLight size='1rem' />
                )
              }
              variant='outline'
              aria-label='change sort order'
            />
          </ButtonGroup>
          <MenuList>
            <MenuOptionGroup
              value={currentValue}
              onChange={value => applyFilter(value as string)}
              type='radio'
            >
              {options.map(o => (
                <MenuItemOption value={o.id as string} key={o.id}>
                  {o.value}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default SingleSort;
