import {
  ObjectSchema,
  object,
  number,
  string,
  date,
  boolean,
  mixed
} from 'yup';
import { MESSAGES } from '../constants/validationMessages';
import SubmitFormValue from '../models/forms/submitFormValue';
import PowerSelectionMethod from '../enums/powerSelectionMethod';

export const SubmitGameResultValidationSchema: ObjectSchema<SubmitFormValue> =
  object({
    tournamentId: number()
      .required(MESSAGES.REQUIRED.TOURNAMENT_ID)
      .moreThan(0, MESSAGES.REQUIRED.TOURNAMENT_ID),
    identifier: string()
      .trim()
      .required(MESSAGES.REQUIRED.IDENTIFIER)
      .matches(/^(0000|[A-Z]\d{3}|[A-Z]{2}\d{3})$/, MESSAGES.VALID.IDENTIFIER),
    power: string().required(MESSAGES.REQUIRED.POWER),
    opposingPlayer: number()
      .required(MESSAGES.REQUIRED.OPPOSING_PLAYER)
      .moreThan(0, MESSAGES.REQUIRED.OPPOSING_PLAYER),
    outcome: string().required(MESSAGES.REQUIRED.GAME_OUTCOME),
    gameEndTurnId: number()
      .required(MESSAGES.REQUIRED.GAME_END_TURN)
      .moreThan(0, MESSAGES.REQUIRED.GAME_END_TURN),
    gameEndTypeId: number()
      .required(MESSAGES.REQUIRED.GAME_END_TYPE)
      .moreThan(0, MESSAGES.REQUIRED.GAME_END_TYPE),
    date: date().required(MESSAGES.REQUIRED.DATE),
    linkToVideo: string().url(MESSAGES.VALID.LINK_TO_VIDEO).optional(),
    gameId: number().when('allowUnboundGameSubmission', {
      is: false,
      then: schema => schema.required(),
      otherwise: schema => schema.optional()
    }),
    allowUnboundSubmission: boolean().required(),
    powerSelectionMethod: mixed<PowerSelectionMethod>().required(),
    matchId: number().optional().nullable()
  });
