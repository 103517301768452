import {
  Flex,
  Grid,
  HStack,
  IconButton,
  Text,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react';
import TournamentParticipant from '../../models/tournament/tournamentParticipant';
import Flag from '../Flag';
import { VscKebabVertical } from 'react-icons/vsc';
import { replaceUrlParams } from '../../utils/routerUtils';
import { ROUTES } from '../../constants';
import { Link } from 'react-router-dom';
import PermissionGuard from '../guards/PermissionGuard';
import { PERMISSIONS } from '../../constants/permissions';
import ScopeLevel from '../../models/authentication/scopeLevel';
import { DeleteIcon } from '@chakra-ui/icons';
import TournamentStatus from '../../enums/tournamentStatus';
import { TbUserCancel } from 'react-icons/tb';
import TournamentParticipantStatus from '../../enums/tournamentParticipantStatus';

interface TournamentParticipantCardProps {
  tournamentId: number;
  tournamentStatus: TournamentStatus;
  tournamentParticipant: TournamentParticipant;
  onParticipantRemoved: (id: number) => void;
  onParticipantForfeited: (id: number) => void;
}

const TournamentParticipantCard = ({
  tournamentId,
  tournamentStatus,
  tournamentParticipant,
  onParticipantRemoved,
  onParticipantForfeited
}: TournamentParticipantCardProps) => {
  const { id, rating, firstName, rank, lastName, countryId, status } =
    tournamentParticipant;

  return (
    tournamentParticipant && (
      <Grid
        templateColumns='min-content 1fr 1fr min-content'
        gap='1rem'
        py={1}
        px={2}
        borderWidth={1}
        borderRadius={8}
        boxShadow='lg'
        opacity={status === TournamentParticipantStatus.Forfeited ? 0.75 : 1}
      >
        <Flex flexDir='column'>
          <Text fontSize='0.6rem' color='gray.400'>
            Rank:
          </Text>
          <Text fontWeight='bold'>{rank ?? '-'}</Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontSize='0.6rem' color='gray.400'>
            Player:
          </Text>
          <HStack height='100%'>
            <Flag code={countryId} size='sm' />
            <ChakraLink
              as={Link}
              to={replaceUrlParams(ROUTES.PLAYER_PROFILE, {
                id: tournamentParticipant.id
              })}
            >
              <Text lineHeight='4'>
                {firstName} {lastName}
              </Text>
            </ChakraLink>
          </HStack>
        </Flex>
        {status === TournamentParticipantStatus.Active ? (
          <>
            <Flex flexDir='column' justifySelf='flex-end'>
              <Text fontSize='0.6rem' color='gray.400'>
                Rating:
              </Text>
              <Text fontWeight='bold'>{rating}</Text>
            </Flex>

            <PermissionGuard
              requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
              requiredScope={ScopeLevel.Tournament}
              requiredResourceId={tournamentId}
            >
              <Menu>
                <Flex alignItems='center'>
                  <MenuButton
                    as={IconButton}
                    aria-label='admin menu'
                    variant='ghost'
                    icon={<VscKebabVertical />}
                  ></MenuButton>
                  <MenuList>
                    {tournamentStatus === TournamentStatus.Upcoming && (
                      <MenuItem
                        icon={<DeleteIcon />}
                        onClick={() => onParticipantRemoved(id)}
                      >
                        Remove from tournament
                      </MenuItem>
                    )}
                    {status === TournamentParticipantStatus.Active &&
                      tournamentStatus === TournamentStatus.Ongoing && (
                        <MenuItem
                          icon={<TbUserCancel size='1rem' />}
                          onClick={() => onParticipantForfeited(id)}
                        >
                          Mark as forfeited
                        </MenuItem>
                      )}
                  </MenuList>
                </Flex>
              </Menu>
            </PermissionGuard>
          </>
        ) : (
          <Flex flexDir='column' gridColumn='3 / 4' alignItems='flex-end'>
            <Text fontSize='0.6rem' color='gray.400'>
              Status:
            </Text>
            <Text>{status}</Text>
          </Flex>
        )}
      </Grid>
    )
  );
};

export default TournamentParticipantCard;
