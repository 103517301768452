import { Box, ChakraProvider, Flex, theme } from '@chakra-ui/react';
import { Header } from './components/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ROUTES } from './constants';
import LogInView from './views/auth/LogInView';
import SignUpView from './views/SignUpView';
import MainView from './views/MainView';
import ResetPasswordView from './views/auth/ResetPasswordView';
import SetPasswordView from './views/auth/SetPasswordView';
import PlayersRatingView from './views/PlayersRatingView';
import GameResultsView from './views/GameResultsView';
import SubmitForm from './views/SubmitForm';
import AuthorizedRouteGuard from './components/guards/AuthorizedRouteGuard';
import GameResultInfoView from './views/GameResultInfoView';
import ForbiddenPage from './views/utility/ForbiddenView';
import PermissionRouteGuard from './components/guards/PermissionRouteGuard';
import AdminPlayerEditView from './views/AdminPlayerEditView';
import ErrorHandler from './components/ErrorHandler';
import NotFoundPage from './views/utility/NotFoundView';
import EditGameResultView from './views/EditGameResultView';
import Footer from './components/Footer';
import PlayerProfileView from './views/PlayerProfileView';
import PlayerTimeDifferenceView from './views/PlayerTimeDifferenceView';
import StatisticsView from './views/StatisticsView';
import ReactGA from 'react-ga4';
import UnauthorizedRouteGuard from './components/guards/UnauthorizedRouteGuard';
import './App.css';
import PlayerEditView from './views/PlayerEditView';
import UserSpecificRouteGuard from './components/guards/UserSpecificRouteGuard';
import SignUpRequestInfoView from './views/SignUpRequestInfoView';
import SignUpRequestsView from './views/SignUpRequestsView';
import CreateTournamentView from './views/CreateTournamentView';
import TournamentsView from './views/tournament/TournamentsView';
import TournamentView from './views/tournament/TournamentView';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PlayerTournamentsView from './views/PlayerTournamentsView';
import { PERMISSIONS } from './constants/permissions';
import CreateTournamentDefinitionView from './views/CreateTournamentDefinitionView';
import EditTournamentView from './views/EditTournamentView';
import GameInfoView from './views/GameInfoView';
import PlayerScheduleView from './views/PlayerScheduleView';
import GameImportView from './views/GameImportView';
import TournamentParticipantsView from './views/tournament/TournamentParticipantsView';
import TournamentInfoView from './views/tournament/TournamentInfoView';
import TournamentRegistrationView from './views/tournament/TournamentRegistrationView';
import TournamentScheduleView from './views/tournament/TournamentScheduleView';
import CreateGameForm from './views/CreateGameForm';
import EditGameForm from './views/EditGameForm';
import TournamentWaitingListView from './views/tournament/TournamentWaitingListView';
import TournamentMissingGamesView from './views/tournament/TournamentMissingGamesView';
import TournamentStandingsView from './views/tournament/TournamentStandingsView';
import TournamentStageStandingsView from './views/tournament/TournamentStageStandingsView';
import TournamentManagementView from './views/tournament/TournamentManagementView';
import HallOfFameView from './views/hall-of-fame/HallOfFameView';
import HallOfFameByPlayerView from './views/hall-of-fame/HallOfFameByPlayerView';
import HallOfFameByTournamentView from './views/hall-of-fame/HallOfFameByTournamentView';
import CreatePollForm from './views/CreatePollForm';
import PollsView from './views/polls/PollsView';

if (
  process.env.REACT_APP_GA_MEASUREMENT_ID &&
  process.env.NODE_ENV !== 'development'
) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

export const App = () => {
  return (
    <ChakraProvider
      theme={theme}
      toastOptions={{
        defaultOptions: {
          isClosable: true,
          position: 'top-right',
          duration: 5000
        }
      }}
    >
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
      >
        <Router>
          <Flex flexDir={'column'} minH='100vh'>
            <Header />
            <ErrorHandler />
            <Box flex={1}>
              <Routes>
                <Route path={ROUTES.HOME} element={<MainView />} />
                <Route
                  path={ROUTES.LOGIN}
                  element={<UnauthorizedRouteGuard element={<LogInView />} />}
                />
                <Route
                  path={ROUTES.SIGNUP}
                  element={<UnauthorizedRouteGuard element={<SignUpView />} />}
                />
                <Route
                  path={ROUTES.PASSWORD_RESET}
                  element={<ResetPasswordView />}
                />
                <Route
                  path={ROUTES.PASSWORD_SET}
                  element={<SetPasswordView />}
                />
                <Route path={ROUTES.RATING} element={<PlayersRatingView />} />
                <Route
                  path={ROUTES.PLAYER_PROFILE}
                  element={<PlayerProfileView />}
                />
                <Route
                  path={ROUTES.PLAYER_TIME_DIFFERENCE}
                  element={<PlayerTimeDifferenceView />}
                />
                <Route
                  path={ROUTES.PLAYER_TOURNAMENTS}
                  element={<PlayerTournamentsView />}
                />
                <Route
                  path={ROUTES.PLAYER_SCHEDULE}
                  element={<PlayerScheduleView />}
                />
                <Route
                  path={ROUTES.SUBMIT_GAME_RESULT}
                  element={<AuthorizedRouteGuard element={<SubmitForm />} />}
                />
                <Route
                  path={ROUTES.GAME_RESULT_EDIT}
                  element={
                    <PermissionRouteGuard
                      element={<EditGameResultView />}
                      requiredPermission={PERMISSIONS.GAME_RESULT.EDIT}
                    />
                  }
                />
                <Route
                  path={ROUTES.SIGNUP_REQUESTS}
                  element={
                    <PermissionRouteGuard
                      element={<SignUpRequestsView />}
                      requiredPermission={PERMISSIONS.SIGN_UP.VIEW}
                    />
                  }
                />
                <Route
                  path={ROUTES.SIGNUP_REQUEST_INFO}
                  element={
                    <PermissionRouteGuard
                      element={<SignUpRequestInfoView />}
                      requiredPermission={PERMISSIONS.SIGN_UP.VIEW}
                    />
                  }
                />
                <Route
                  path={ROUTES.GAME_RESULT_INFO}
                  element={<GameResultInfoView />}
                />
                <Route
                  path={ROUTES.GAME_RESULTS}
                  element={<GameResultsView />}
                />
                <Route path={ROUTES.FORBIDDEN} element={<ForbiddenPage />} />
                <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage />} />
                <Route
                  path={ROUTES.EDIT_PLAYER}
                  element={
                    <UserSpecificRouteGuard element={<PlayerEditView />} />
                  }
                />
                <Route
                  path={ROUTES.ADMIN_EDIT_PLAYER}
                  element={
                    <PermissionRouteGuard
                      element={<AdminPlayerEditView />}
                      requiredPermission={PERMISSIONS.PLAYER.EDIT}
                    />
                  }
                />
                <Route
                  path={ROUTES.CREATE_TOURNAMENT}
                  element={
                    <PermissionRouteGuard
                      element={<CreateTournamentView />}
                      requiredPermission={PERMISSIONS.TOURNAMENT.CREATE}
                    />
                  }
                />
                <Route
                  path={ROUTES.TOURNAMENT_EDIT}
                  element={
                    <PermissionRouteGuard
                      element={<EditTournamentView />}
                      requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
                    />
                  }
                />
                <Route
                  path={ROUTES.TOURNAMENTS}
                  element={<TournamentsView />}
                />
                <Route
                  path={ROUTES.TOURNAMENT_INFO}
                  element={<TournamentView />}
                >
                  <Route index element={<TournamentInfoView />}></Route>
                  <Route
                    path='participants'
                    element={<TournamentParticipantsView />}
                  />
                  <Route path='schedule' element={<TournamentScheduleView />} />
                  <Route
                    path='register'
                    element={
                      <AuthorizedRouteGuard
                        element={<TournamentRegistrationView />}
                      />
                    }
                  />
                  <Route
                    path='waiting-list'
                    element={<TournamentWaitingListView />}
                  />
                  <Route
                    path='missing-games'
                    element={<TournamentMissingGamesView />}
                  />
                  <Route path='standings' element={<TournamentStandingsView />}>
                    <Route
                      path=':stageId'
                      element={<TournamentStageStandingsView />}
                    />
                  </Route>
                  <Route
                    path='management'
                    element={
                      <PermissionRouteGuard
                        element={<TournamentManagementView />}
                        requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
                      />
                    }
                  />
                </Route>
                <Route
                  path={ROUTES.TOURNAMENT_INFO}
                  element={<TournamentInfoView />}
                />
                <Route
                  path={ROUTES.GAME_CREATE}
                  element={
                    <PermissionRouteGuard
                      element={<CreateGameForm />}
                      requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
                    />
                  }
                />
                <Route
                  path={ROUTES.GAME_EDIT}
                  element={
                    <PermissionRouteGuard
                      element={<EditGameForm />}
                      requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
                    />
                  }
                />
                <Route
                  path={ROUTES.CREATE_TOURNAMENT_DEFINITION}
                  element={
                    <PermissionRouteGuard
                      element={<CreateTournamentDefinitionView />}
                      requiredPermission={
                        PERMISSIONS.TOURNAMENT_DEFINITION.CREATE
                      }
                    />
                  }
                />
                <Route
                  path={ROUTES.GAME_IMPORT}
                  element={
                    <PermissionRouteGuard
                      element={<GameImportView />}
                      requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
                    ></PermissionRouteGuard>
                  }
                ></Route>
                <Route
                  path={ROUTES.GAME_INFO}
                  element={<GameInfoView />}
                ></Route>
                <Route
                  path={ROUTES.HALL_OF_FAME_BY_PLAYER}
                  element={<HallOfFameView />}
                >
                  <Route index element={<HallOfFameByPlayerView />} />
                  <Route
                    path='tournaments'
                    element={<HallOfFameByTournamentView />}
                  />
                </Route>
                <Route path={ROUTES.STATISTICS} element={<StatisticsView />} />
                <Route path={ROUTES.POLL_VIEW} element={<PollsView />} />
                <Route
                  path={ROUTES.POLL_CREATE}
                  element={
                    <PermissionRouteGuard
                      element={<CreatePollForm />}
                      requiredPermission={PERMISSIONS.POLL.CREATE}
                    />
                  }
                />
                <Route path='*' element={<NotFoundPage />} />
              </Routes>
            </Box>
            <Footer />
          </Flex>
        </Router>
      </GoogleReCaptchaProvider>
    </ChakraProvider>
  );
};
