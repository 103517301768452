import { Navigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { useAppSelector } from '../../hooks/hooks';
import { selectUserHasPermission } from '../../store/userSlice';

interface PermissionRouteGuardProps {
  element: JSX.Element;
  requiredPermission: string;
}

const PermissionRouteGuard = ({
  element,
  requiredPermission
}: PermissionRouteGuardProps) => {
  const hasPermission = useAppSelector(state =>
    selectUserHasPermission(state, requiredPermission)
  );

  if (!hasPermission) {
    return <Navigate to={ROUTES.FORBIDDEN} replace></Navigate>;
  }

  return element;
};

export default PermissionRouteGuard;
