export const MESSAGES = {
  REQUIRED: {
    TOURNAMENT_ID: 'Please choose tournament',
    IDENTIFIER: 'Please add game identifier',
    POWER: 'Please choose your side',
    OPPOSING_PLAYER: 'Please specify your opponent',
    PLAYER_BLUE_ID: 'Please choose USA player',
    PLAYER_RED_ID: 'Please choose USSR player',
    GAME_OUTCOME: 'Please specify the game outcome',
    GAME_END_TURN: 'Please specify game end turn',
    GAME_END_TYPE: 'Please specify game end type',
    DATE: 'Date is required',
    FIRST_NAME: 'First name is required',
    LAST_NAME: 'Last name is required',
    EMAIL: 'Email is required',
    COUNTRY_ID: 'Country is required',
    PASSWORD: 'Password is required',
    CONFIRM_PASSWORD: 'Please confirm your password',
    CONFIRM_EMAIL: 'Please confirm your email',
    CITY_ID: 'City is required',
    PREFERRED_GAMING_PLATFORM_ID: 'Preferred gaming platform is required',
    PREFERRED_GAME_DURATION_ID: 'Preferred game duration is required',
    INTENDED_TOURNAMENT_ID: 'Intended tournament is required',
    TOURNAMENT_DEFINITION_ID: 'Tournament type is required',
    TOURNAMENT_EDITION: 'Edition is required',
    TOURNAMENT_START_DATE: 'Start date is required',
    TOURNAMENT_END_DATE: 'End date is required',
    NAME: 'Name is required',
    SHORT_NAME: 'Short name is required',
    DESCRIPTION: 'Description is required',
    TOURNAMENT_FORMAT: 'Tournament format is required',
    STANDARD_GAME_DURATION: 'Standard game duration is required',
    PLAYERS_DIFFERENT: 'Players must be different',
    POWERS_DIFFERENT: 'Powers must be different',
    POWER_SELECTION_METHOD: 'Power selection method is required',
    GOLD_MEDAL: 'Please specify Gold medal winner',
    SILVER_MEDAL: 'Please specify Silver medal winner',
    BRONZE_MEDAL: 'Please specify Bronze medal winner',
    QUESTION: 'Question is required',
    OPTION: 'Option text is required'
  },
  VALID: {
    IDENTIFIER: `Game identifier must be '0000' or one-two letters and three digits`,
    LINK_TO_VIDEO: 'Only valid URL is allowed',
    LINK_TO_RESULTS: 'Only valid URL is allowed',
    EMAIL: 'Email is invalid',
    PASSWORD: 'Password must be at least 8 characters',
    CONFIRM_PASSWORD: "Passwords don't match!",
    CONFIRM_EMAIL: 'Confirm email is invalid',
    CONFIRM_EMAIL_MATCH: "Emails don't match!",
    PHONE_NUMBER: 'Phone number should start with + and contain 5-15 numbers',
    TOURNAMENT_END_DATE: "End date can't be earlier than start date",
    TOURNAMENT_ADMINS: 'Please select at least one tournament administrator'
  },
  LENGTH: 'Too Long!'
};

export const getErrorMessageForLength = (symbolsCount: number) =>
  `Only ${symbolsCount} symbols are allowed here`;
