import { number, object, ObjectSchema } from 'yup';
import SubmitTournamentWinnersFormValue from '../models/forms/submitTournamentWinnersFormValue';
import { MESSAGES } from '../constants/validationMessages';

export const SubmitTournamentWinnersValidationSchema: ObjectSchema<SubmitTournamentWinnersFormValue> =
  object({
    firstPlacePlayerId: number().required(MESSAGES.REQUIRED.GOLD_MEDAL),
    secondPlacePlayerId: number().required(MESSAGES.REQUIRED.SILVER_MEDAL),
    thirdPlacePlayerId: number().required(MESSAGES.REQUIRED.BRONZE_MEDAL)
  });
