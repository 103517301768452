import { ObjectSchema, object, string } from 'yup';
import ResetPassword from '../models/authentication/resetPassword';
import { MESSAGES } from '../constants/validationMessages';

export const ResetPasswordValidationSchema: ObjectSchema<ResetPassword> =
  object({
    email: string()
      .trim()
      .email(MESSAGES.VALID.EMAIL)
      .required(MESSAGES.REQUIRED.EMAIL)
  });
