import { ObjectSchema, boolean, object, string } from 'yup';
import CreateTournamentDefinitionFormValue from '../models/forms/createTournamentDefinitionFormValue';
import { MESSAGES } from '../constants/validationMessages';

const CreateTournamentDefinitionSchema: ObjectSchema<CreateTournamentDefinitionFormValue> =
  object({
    name: string().trim().required(MESSAGES.REQUIRED.NAME),
    shortName: string().trim().required(MESSAGES.REQUIRED.SHORT_NAME),
    isRegistrable: boolean().required()
  });

export default CreateTournamentDefinitionSchema;
