import { Box, Divider, Flex, Link, Text, useColorMode } from '@chakra-ui/react';

const Footer = () => {
  const { colorMode } = useColorMode();

  return (
    <Box px={4}>
      <Divider />
      <Flex
        flexDir={{ base: 'column', sm: 'row' }}
        alignItems={{ base: 'flex-start', sm: 'center' }}
        justifyContent={{ base: 'baseline', sm: 'space-between' }}
        gap={{ base: '0.5rem', sm: 0 }}
        minH='2rem'
      >
        <Text
          fontSize={11}
          color={colorMode === 'light' ? 'blackAlpha.600' : 'whiteAlpha.400'}
        >
          {`Version: ${process.env.REACT_APP_VERSION}`}
        </Text>
        <Text
          fontSize={11}
          color={colorMode === 'light' ? 'blackAlpha.600' : 'whiteAlpha.400'}
        >
          This site is protected by reCAPTCHA and the Google{' '}
          <Link
            textDecoration='underline'
            isExternal
            href='https://policies.google.com/privacy'
          >
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link
            textDecoration='underline'
            isExternal
            href='https://policies.google.com/terms'
          >
            Terms of Service{' '}
          </Link>
          apply.
        </Text>
      </Flex>
    </Box>
  );
};

export default Footer;
