import { Container, Flex, Skeleton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import TournamentInfoShort from '../models/tournament/tournamentInfoShort';
import { ROUTES } from '../constants';
import TournamentCard from '../components/tournament/TournamentCard';
import { replaceUrlParams } from '../utils/routerUtils';
import styleConstants from '../constants/styleConstants';
import { getPlayerTournaments } from '../services/playerService';

const PlayerTournamentsView = () => {
  const { id } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [tournaments, setTournaments] = useState<TournamentInfoShort[]>([]);

  useEffect(() => {
    (async () => {
      const tournaments = await getPlayerTournaments(+id!);
      setLoaded(true);
      setTournaments(tournaments);
    })();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Player tournaments</title>
      </Helmet>
      <Container
        maxW={'container.lg'}
        my={styleConstants.viewMY}
        p={5}
        borderWidth={1}
        borderRadius={8}
        boxShadow='lg'
      >
        <Flex flexDir='column' gap={2}>
          {loaded ? (
            tournaments.map(tournament => (
              <Link
                to={replaceUrlParams(ROUTES.TOURNAMENT_INFO, {
                  id: tournament.id
                })}
                key={tournament.id}
              >
                <TournamentCard tournament={tournament}></TournamentCard>
              </Link>
            ))
          ) : (
            <>
              {[...Array(15)].map((_value, index) => (
                <Skeleton
                  h={{ base: '5rem', md: '3.5rem' }}
                  key={index}
                  borderRadius={6}
                  mb={2}
                />
              ))}
            </>
          )}
        </Flex>
      </Container>
    </>
  );
};

export default PlayerTournamentsView;
