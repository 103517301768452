import TournamentWinnerPlace from '../enums/tournamentWinnerPlace';
import { LiaMedalSolid } from 'react-icons/lia';

interface MedalProps {
  place: TournamentWinnerPlace;
  size: 'sm' | 'md' | 'lg';
}

const sizes = {
  sm: '1.5rem',
  md: '2rem',
  lg: '2.5rem'
};

const colors = {
  [TournamentWinnerPlace.Gold]: '#FFD700',
  [TournamentWinnerPlace.Silver]: '#C0C0C0',
  [TournamentWinnerPlace.Bronze]: '#CD7F32'
};

const Medal = ({ size, place }: MedalProps) => {
  return <LiaMedalSolid fontSize={sizes[size]} color={colors[place]} />;
};

export default Medal;
