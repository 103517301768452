import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Link as ChakraLink,
  Flex
} from '@chakra-ui/react';
import ForfeitedGame from '../models/game/forfeitedGame';
import { Link } from 'react-router-dom';
import { ROUTES } from '../constants';
import { replaceUrlParams } from '../utils/routerUtils';
import { useEffect, useState } from 'react';
import { getForfeitedGames } from '../services/gameService';
import SelectOption from '../models/utils/selectOption';
import { getTournamentParticipants } from '../services/lookupService';
import TournamentParticipantStatus from '../enums/tournamentParticipantStatus';
import SelectInput from './selectInput/SelectInput';
import useFeedbackRequest from '../hooks/useFeedbackRequest';
import useFullPageLoader from '../hooks/useFullPageLoader';
import { processPlayerForfeit } from '../services/participantsService';

interface ForfeitedGamesPopupProps {
  onClose: () => void;
  onContinue: (id: number) => void;
  playerId: number;
  tournamentId: number;
}

const ForfeitedGamesPopup = ({
  onClose,
  onContinue,
  playerId,
  tournamentId
}: ForfeitedGamesPopupProps) => {
  const [forfeitedGames, setForfeitedGames] = useState([] as ForfeitedGame[]);
  const [replacementPlayerId, setReplacementPlayerId] = useState<
    number | undefined
  >();
  const [replacementPlayers, setReplacementPlayers] = useState(
    [] as SelectOption[]
  );

  useEffect(() => {
    (async () => {
      const games = await getForfeitedGames(tournamentId, playerId);
      setForfeitedGames(games);

      const players = await getTournamentParticipants(tournamentId, [
        TournamentParticipantStatus.Waiting
      ]);
      setReplacementPlayers(players);
    })();
  }, [playerId, tournamentId]);

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const processForfeit = useFeedbackRequest(
    () => processPlayerForfeit(tournamentId, playerId, replacementPlayerId),
    showLoader,
    hideLoader,
    'Player forfeit has been successfully processed',
    () => onContinue(playerId)
  );

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Following games will be affected</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir='column' gap='1rem'>
            <Text lineHeight={4}>
              If you choose the replacement player option, any unplayed games
              (see below in the table) where a player forfeits will have that
              player substituted with a new player from the waiting list. For
              every game that is played and where a player forfeits, a new
              missing game entry will be generated for the replacement player.
            </Text>
            <Text lineHeight={4}>
              If you opt not to select a replacement player, a new missing game
              entry will be generated for each unplayed game, representing the
              absence of the original opponent
            </Text>
            <TableContainer>
              <Table size='sm'>
                <Thead>
                  <Tr>
                    <Th>Identifier</Th>
                    <Th>Opponent</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {forfeitedGames.map(g => (
                    <Tr key={g.id}>
                      <Td>
                        <ChakraLink
                          as={Link}
                          to={replaceUrlParams(ROUTES.GAME_INFO, { id: g.id })}
                        >
                          {g.identifier}
                        </ChakraLink>
                      </Td>
                      <Td>{g.opponentName}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <SelectInput
              options={replacementPlayers}
              onChange={value => setReplacementPlayerId(value as number)}
              value={replacementPlayerId}
              placeholder='Choose replacement player'
            />
          </Flex>
          {loader}
        </ModalBody>

        <ModalFooter>
          <Button onClick={() => processForfeit()}>Continue</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ForfeitedGamesPopup;
