import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ObjectSchema, mixed, object } from 'yup';
import useErrorMessage from '../hooks/useErrorMessage';
import useFullPageLoader from '../hooks/useFullPageLoader';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  Input,
  useToast
} from '@chakra-ui/react';
import ErrorsList from './ErrorsList';
import { useState } from 'react';
import { importGames } from '../services/gameService';
import { useParams } from 'react-router-dom';
import ValidationResult from '../models/utils/validationResult';

type GameImportFormValue = {
  file: FileList;
};

const validationSchema: ObjectSchema<GameImportFormValue> = object({
  file: mixed((p): p is FileList => p instanceof FileList)
    .required()
    .test(
      'File Required',
      'Please select a file',
      value => value && !!value.length
    )
    .test(
      'File Type',
      'Invalid file type',
      value => value!![0].type === 'text/csv'
    )
});

const GameImportForm = () => {
  const { tournamentId } = useParams();

  const toast = useToast();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm<GameImportFormValue>({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    shouldFocusError: false
  });

  const [validationErrors, setValidationErrors] = useState(
    [] as ValidationResult[]
  );
  const [errorMessage, setErrorMessage, clearErrorMessage] = useErrorMessage();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const onSubmit: SubmitHandler<GameImportFormValue> = async data => {
    try {
      clearErrorMessage();
      setValidationErrors([]);
      showLoader();

      const response = await importGames(+tournamentId!, data.file[0]);

      if (response) {
        setValidationErrors(response);
      } else {
        // navigate to the tournaments schedule
        toast({
          title: 'Changes saved',
          description: 'Games have been successfully uploaded',
          status: 'success'
        });
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    }

    hideLoader();
    reset();
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize='0.9rem'>
          Please upload your CSV file here. If any errors are identified, they
          will be listed below. Fix the issues in your file and attempt the
          submission again
        </Text>
        <Flex flexDir={{ base: 'column', sm: 'row' }} gap='0.5rem'>
          <FormControl isRequired isInvalid={!!errors?.file}>
            <Input
              type='file'
              accept='.csv'
              {...register('file')}
              sx={{
                '::file-selector-button': {
                  height: 10,
                  padding: 0,
                  mr: 4,
                  background: 'none',
                  border: 'none',
                  fontWeight: 'bold'
                }
              }}
            />
            <FormErrorMessage>
              {errors?.file?.message as string}
            </FormErrorMessage>
          </FormControl>

          <Button
            colorScheme='teal'
            variant='outline'
            width={{ base: '100%', sm: '15rem' }}
            textAlign='center'
            type='submit'
          >
            Submit
          </Button>
        </Flex>
      </form>
      {errorMessage}
      {!!validationErrors.length && (
        <ErrorsList validationErrors={validationErrors}></ErrorsList>
      )}
      {loader}
    </>
  );
};

export default GameImportForm;
