import { Flex, Text, Heading } from '@chakra-ui/react';

interface CalendarPageProps {
  date: Date;
}

const CalendarPage = ({ date }: CalendarPageProps) => {
  return (
    <Flex
      flexDir='column'
      borderWidth={1}
      borderRadius={8}
      boxShadow='md'
      p='3'
      alignItems='center'
      lineHeight='1'
      maxWidth='fit-content'
      bg='orange.300'
    >
      <Text>{date.toLocaleString('default', { month: 'long' })}</Text>
      <Heading size='2xl'>{date.getDate()}</Heading>
      <Text>{date.toLocaleString('default', { weekday: 'long' })}</Text>
      <Text fontSize='0.8rem'>{date.getFullYear()}</Text>
    </Flex>
  );
};

export default CalendarPage;
