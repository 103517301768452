export function formatDateString(date: string | Date) {
  const dateObject = new Date(date);

  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObject.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function isDateValid(searchParamsValue: string | null) {
  if (searchParamsValue) {
    const date = Date.parse(searchParamsValue);
    return isNaN(date) ? null : new Date(searchParamsValue);
  }
  return null;
}
