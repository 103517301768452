import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Skeleton,
  Textarea,
  useToast
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import DateInput from '../components/dateInput/DateInput';
import SelectInput from '../components/selectInput/SelectInput';
import styleConstants from '../constants/styleConstants';
import useFullPageLoader from '../hooks/useFullPageLoader';
import EditTournamentFormValue from '../models/forms/editTournamentFormValue';
import SelectOption from '../models/utils/selectOption';
import { getSelectOptions } from '../services/lookupService';
import EditTournamentValidationSchema from '../validation/editTournamentValidationSchema';
import Error from '../components/Error';
import { editTournament, getTournament } from '../services/tournamentService';
import PermissionGuard from '../components/guards/PermissionGuard';
import { PERMISSIONS } from './../constants/permissions';
import ScopeLevel from '../models/authentication/scopeLevel';
import { replaceUrlParams } from './../utils/routerUtils';
import { ROUTES } from './../constants/constants';
import { formatDateString } from './../utils/dateUtils';
import { LOOKUP_TYPES } from '../constants/lookupTypes';

const EditTournamentView = () => {
  const { id } = useParams();

  const [errorMessage, setErrorMessage] = useState('');
  const [loaded, setLoaded] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [playersSelectOptions, setPlayersSelectOptions] =
    useState<SelectOption[]>();

  const [tournamentFormatSelectOptions, setTournamentFormatSelectOptions] =
    useState<SelectOption[]>();

  const [gameDurationSelectOptions, setGameDurationSelectOptions] =
    useState<SelectOption[]>();

  useEffect(() => {
    (async () => {
      const players = await getSelectOptions(LOOKUP_TYPES.PLAYERS);
      const tournamentFormats = await getSelectOptions(
        LOOKUP_TYPES.TOURNAMENT_FORMATS
      );
      const durations = await getSelectOptions(LOOKUP_TYPES.GAME_DURATIONS);
      const tournament = await getTournament(+id!);

      setTournamentFormatSelectOptions(tournamentFormats);
      setGameDurationSelectOptions(durations);
      setPlayersSelectOptions(players);
      reset({
        ...tournament,
        startDate: new Date(tournament.startDate),
        endDate: new Date(tournament.endDate)
      });
      setLoaded(true);
    })();
  }, [id]);

  const {
    control,
    register,
    handleSubmit,
    watch,
    trigger,
    reset,
    formState: { errors }
  } = useForm<EditTournamentFormValue>({
    resolver: yupResolver(EditTournamentValidationSchema),
    mode: 'onTouched',
    shouldFocusError: false
  });

  const onSubmit: SubmitHandler<EditTournamentFormValue> = async data => {
    showLoader();

    try {
      await editTournament(+id!, {
        ...data,
        tournamentAdmins: data.tournamentAdmins!,
        startDate: formatDateString(data.startDate),
        endDate: formatDateString(data.endDate)
      });

      toast({
        title: 'Changes saved',
        description: 'Tournament is edited',
        status: 'success'
      });

      hideLoader();
      navigate(replaceUrlParams(ROUTES.TOURNAMENT_INFO, { id: id }));
    } catch (error: any) {
      hideLoader();
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit tournament</title>
      </Helmet>
      <Container
        maxW={'container.md'}
        my={{ base: styleConstants.viewMobileMY, sm: styleConstants.viewMY }}
        paddingX={{ base: 2, sm: 6 }}
        paddingY={{ base: 0, sm: 6 }}
        borderWidth={{ base: 0, sm: 1 }}
        borderRadius={8}
        boxShadow={{ base: 'none', sm: 'lg' }}
      >
        <Heading size='lg' textAlign='center'>
          Edit the tournament
        </Heading>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Flex flexDir='column' gap='2'>
            <FormControl isRequired isInvalid={!!errors?.edition}>
              <FormLabel>Edition:</FormLabel>
              <Skeleton isLoaded={loaded}>
                <Input
                  type='text'
                  placeholder='Enter edition'
                  {...register('edition')}
                />
              </Skeleton>
              <FormErrorMessage>{errors?.edition?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors?.startDate}>
              <FormLabel>Start date:</FormLabel>
              <Skeleton isLoaded={loaded}>
                <Controller
                  name='startDate'
                  control={control}
                  render={({ field, fieldState }) => (
                    <DateInput
                      value={field.value}
                      minDate={new Date()}
                      onChange={value => {
                        field.onChange(value);
                        trigger('endDate');
                      }}
                      onBlur={field.onBlur}
                      isInvalid={fieldState.invalid}
                    />
                  )}
                />
              </Skeleton>
              <FormErrorMessage>{errors?.startDate?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors?.endDate}>
              <FormLabel>End date:</FormLabel>
              <Skeleton isLoaded={loaded}>
                <Controller
                  name='endDate'
                  control={control}
                  render={({ field, fieldState }) => (
                    <DateInput
                      value={field.value}
                      minDate={watch('startDate')}
                      onChange={value => {
                        field.onChange(value);
                        trigger('startDate');
                      }}
                      onBlur={field.onBlur}
                      isInvalid={fieldState.invalid}
                    />
                  )}
                />
              </Skeleton>
              <FormErrorMessage>{errors?.endDate?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors?.description}>
              <FormLabel>Description:</FormLabel>
              <Skeleton isLoaded={loaded}>
                <Textarea
                  placeholder='Enter description'
                  {...register('description')}
                />
              </Skeleton>
              <FormErrorMessage>
                {errors?.description?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors?.tournamentFormatId}>
              <FormLabel>Select tournament format:</FormLabel>
              <Skeleton isLoaded={loaded}>
                <Controller
                  name='tournamentFormatId'
                  control={control}
                  render={({ field, fieldState }) => (
                    <SelectInput
                      value={field.value}
                      onChange={field.onChange}
                      isSearchable={false}
                      options={tournamentFormatSelectOptions}
                      placeholder='Choose tournament type'
                      isInvalid={fieldState.invalid}
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </Skeleton>
              <FormErrorMessage>
                {errors?.tournamentFormatId?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isRequired
              isInvalid={!!errors?.standardGameDurationId}
            >
              <FormLabel>Select standard game duration:</FormLabel>
              <Skeleton isLoaded={loaded}>
                <Controller
                  name='standardGameDurationId'
                  control={control}
                  render={({ field, fieldState }) => (
                    <SelectInput
                      value={field.value}
                      onChange={field.onChange}
                      isSearchable={false}
                      options={gameDurationSelectOptions}
                      placeholder='Choose standard game duration'
                      isInvalid={fieldState.invalid}
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </Skeleton>
              <FormErrorMessage>
                {errors?.standardGameDurationId?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors?.linkToResults}>
              <FormLabel>Link to results:</FormLabel>
              <Skeleton isLoaded={loaded}>
                <Input
                  type='text'
                  placeholder='Enter link to results'
                  {...register('linkToResults')}
                />
              </Skeleton>
              <FormErrorMessage>
                {errors?.linkToResults?.message}
              </FormErrorMessage>
            </FormControl>

            <PermissionGuard
              requiredPermission={PERMISSIONS.TOURNAMENT.MANAGE}
              allowedScopes={[ScopeLevel.System]}
            >
              <FormControl isRequired isInvalid={!!errors?.tournamentAdmins}>
                <FormLabel>Select tournament admin(s):</FormLabel>
                <Skeleton isLoaded={loaded}>
                  <Controller
                    name='tournamentAdmins'
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectInput
                        value={field.value}
                        onChange={field.onChange}
                        isSearchable
                        isMulti
                        options={playersSelectOptions}
                        placeholder='Choose tournament admin(s)'
                        isInvalid={fieldState.invalid}
                        onBlur={field.onBlur}
                      />
                    )}
                  />
                </Skeleton>
                <FormErrorMessage>
                  {errors.tournamentAdmins?.message}
                </FormErrorMessage>
              </FormControl>
            </PermissionGuard>
          </Flex>

          {errorMessage && <Error error={errorMessage}></Error>}
          <Box textAlign={'center'}>
            <Button
              mt={6}
              colorScheme='teal'
              variant='outline'
              width='36'
              textAlign={'center'}
              type='submit'
            >
              Submit
            </Button>
          </Box>
        </form>
      </Container>
      <>{loader}</>
    </>
  );
};

export default EditTournamentView;
