import { useEffect, useState } from 'react';
import PlayerMedalsInfo from '../../models/tournamentWinners/playerMedalsInfo';
import { getTournamentWinnersByPlayer } from '../../services/tournamentWinnerService';
import PlayerMedals from '../../components/playerProfile/PlayerMedals';
import { Flex, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { replaceUrlParams } from '../../utils/routerUtils';
import { ROUTES } from '../../constants';

const HallOfFameByPlayerView = () => {
  const [players, setPlayers] = useState([] as PlayerMedalsInfo[]);

  useEffect(() => {
    const getPlayerMedals = async () => {
      const data = await getTournamentWinnersByPlayer();
      setPlayers(data);
    };

    getPlayerMedals();
  }, []);

  return (
    <Flex flexDir='column' gap='.5rem'>
      {players.map(p => (
        <Flex
          flexDir='column'
          borderWidth={1}
          borderRadius={8}
          p={1}
          gap={1}
          boxShadow='lg'
        >
          <ChakraLink
            fontSize='smaller'
            as={Link}
            to={replaceUrlParams(ROUTES.PLAYER_PROFILE, { id: p.playerId })}
          >
            {p.playerName}
          </ChakraLink>
          <PlayerMedals medals={p.medals} />
        </Flex>
      ))}
    </Flex>
  );
};

export default HallOfFameByPlayerView;
