import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useDisclosure
} from '@chakra-ui/react';
import { CiFilter } from 'react-icons/ci';
import CheckboxFilter from '../filters/CheckboxFilter';
import { SEARCH_PARAMS } from '../../constants';
import SearchFilter from '../filters/SearchFilter';
import SelectOption from '../../models/utils/selectOption';
import ToggleFilter from '../filters/ToggleFilter';

interface PlayersRatingFilterDrawerProps {
  countries: SelectOption[];
  regionalFederations: SelectOption[];
}

const PlayersRatingFilterDrawer = ({
  countries,
  regionalFederations
}: PlayersRatingFilterDrawerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} variant='ghost'>
        <CiFilter />
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose} placement='left'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader py={2}>Filter by</DrawerHeader>
          <DrawerBody pl={2} pt={1}>
            <Flex flexDir='column' gap='.5rem' mb='.5rem' w='100%'>
              <CheckboxFilter
                height='2.5rem'
                paramName={SEARCH_PARAMS.COUNTRY}
                options={countries}
                onFilterApplied={onClose}
                w='100%'
              />
              <CheckboxFilter
                height='2.5rem'
                paramName={SEARCH_PARAMS.REGIONAL_FEDERATION}
                title='regional federation'
                options={regionalFederations}
                onFilterApplied={onClose}
                w='100%'
              />
              <ToggleFilter
                paramName={SEARCH_PARAMS.WITH}
                value='all'
                title='unranked'
                h='2.5rem'
                onFilterApplied={onClose}
                w='100%'
              />
              <SearchFilter
                paramName={SEARCH_PARAMS.SEARCH}
                placeholder='Name or Playdek'
                onFilterApplied={onClose}
                w='100%'
              />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default PlayersRatingFilterDrawer;
