import { ReactFlowProvider } from 'reactflow';
import StageType from '../../enums/stageType';
import Standings from '../../models/stage/standings';
import SingleEliminationBracket from './SingleEliminationBracket';

type BracketProps = {
  standings?: Standings;
};

const Bracket = ({ standings }: BracketProps) => {
  if (!standings) return <></>;

  switch (standings.stageType) {
    case StageType.SingleElimination:
      return (
        <ReactFlowProvider>
          <SingleEliminationBracket matches={standings.matches} />
        </ReactFlowProvider>
      );

    default:
      return <></>;
  }
};

export default Bracket;
