import { Container, Flex } from '@chakra-ui/react';
import GameResultsFilterPanel from '../components/filterPanels/GameResultsFilterPanel';
import { Helmet } from 'react-helmet';
import GameResultsList from '../components/GameResultsList';
import styleConstants from '../constants/styleConstants';

const GameResultsView = () => {
  return (
    <>
      <Helmet>
        <title>Game Results</title>
      </Helmet>
      <Container
        maxW={'container.lg'}
        my={styleConstants.viewMY}
        p={{ base: 1, md: 5 }}
      >
        <Flex flexDir='column' gap='2'>
          <GameResultsFilterPanel />
          <GameResultsList />
        </Flex>
      </Container>
    </>
  );
};

export default GameResultsView;
