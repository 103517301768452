import { Heading, Text, Flex, FlexProps } from '@chakra-ui/react';

interface TimeCardProps extends FlexProps {
  date: Date;
  timeZone?: string;
  timeZoneUTCName?: string;
  orientation: 'left' | 'right';
}

const TimeCard = (props: TimeCardProps) => {
  const { date, timeZone, timeZoneUTCName, orientation, ...cssProps } = props;

  if (!timeZone) return <></>;

  return (
    <Flex
      flexDir='column'
      alignItems={orientation === 'left' ? 'flex-end' : 'flex-start'}
      {...cssProps}
    >
      <Heading fontWeight='thin'>{timeZone}</Heading>
      <Text as='i'>{timeZoneUTCName}</Text>
      <Heading size='md' mt='2rem'>
        {date.toLocaleDateString('default', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
          weekday: 'long',
          timeZone
        })}
      </Heading>
      <Text fontSize='4xl' width='max-content' color='teal'>
        {date.toLocaleTimeString('default', { timeZone })}
      </Text>
    </Flex>
  );
};

export default TimeCard;
