import { Navigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks/hooks';
import { ROUTES } from '../../constants/constants';
import { selectUserId } from './../../store/userSlice';

interface RouteGuardProps {
  element: JSX.Element;
}

const UserSpecificRouteGuard = ({ element }: RouteGuardProps) => {
  const { id } = useParams();
  const userId = useAppSelector(selectUserId);

  if (!userId || userId !== +id!) {
    return <Navigate to={ROUTES.FORBIDDEN} replace></Navigate>;
  }

  return element;
};

export default UserSpecificRouteGuard;
