import { Box, Button, Container, Flex } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { NavLink, Outlet } from 'react-router-dom';
import styleConstants from '../../constants/styleConstants';
import { ROUTES } from '../../constants';

const HallOfFameView = () => {
  return (
    <>
      <Helmet>
        <title>Hall of Fame</title>
      </Helmet>
      <Container
        maxW={'container.lg'}
        my={styleConstants.viewMY}
        p={1}
        minH='100vh'
      >
        <Flex flexDir='column' gap={{ base: 1, sm: 2 }}>
          <Flex gap='.5rem'>
            <Box flex={{ base: 1, sm: 0 }}>
              <NavLink to={ROUTES.HALL_OF_FAME_BY_PLAYER} end>
                {({ isActive }) => (
                  <Button
                    width={{ base: '100%', sm: 'max-content' }}
                    variant={isActive ? 'solid' : 'outline'}
                  >
                    Group by player
                  </Button>
                )}
              </NavLink>
            </Box>
            <Box flex={{ base: 1, sm: 0 }}>
              <NavLink to={ROUTES.HALL_OF_FAME_BY_TOURNAMENT}>
                {({ isActive }) => (
                  <Button
                    width={{ base: '100%', sm: 'max-content' }}
                    variant={isActive ? 'solid' : 'outline'}
                  >
                    Group by tournament
                  </Button>
                )}
              </NavLink>
            </Box>
          </Flex>
          <Outlet />
        </Flex>
      </Container>
    </>
  );
};

export default HallOfFameView;
