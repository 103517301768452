import React from 'react';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import Chart from '../../models/chart/chart';
import { AspectRatio, Flex, Text, useColorMode } from '@chakra-ui/react';
import RadialBarChartItem from '../../models/chart/radialBarChartItem';

interface GameDistributionChartProps {
  gameDistributionBySideAndOutcomeChart: Chart<RadialBarChartItem> | null;
}

const GameDistributionChart = ({
  gameDistributionBySideAndOutcomeChart
}: GameDistributionChartProps) => {
  const { colorMode } = useColorMode();
  return gameDistributionBySideAndOutcomeChart ? (
    <AspectRatio ratio={1}>
      <ResponsiveRadialBar
        data={gameDistributionBySideAndOutcomeChart.data}
        valueFormat='>-.0f'
        padAngle={4}
        cornerRadius={2}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        colors={{ scheme: 'pastel2' }}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1]]
        }}
        enableTracks={false}
        enableRadialGrid={false}
        radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
        circularAxisOuter={null}
        theme={{
          textColor: '#A0AEC0',
          tooltip: {
            container: {
              backgroundColor: colorMode === 'light' ? 'white' : 'black'
            }
          }
        }}
        legends={[
          {
            anchor: 'center',
            direction: 'column',
            justify: false,
            translateX: 30,
            translateY: 0,
            itemsSpacing: 4,
            itemDirection: 'left-to-right',
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#A0AEC0',
            symbolShape: 'square'
          }
        ]}
      />
    </AspectRatio>
  ) : (
    <Flex justifyContent='center' alignItems='center'>
      <Text>
        Zero games played. You need to play at least one game to see game
        distribution chart
      </Text>
    </Flex>
  );
};

export default GameDistributionChart;
