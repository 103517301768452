import { useState } from 'react';
import Error from '../components/Error';

const useErrorMessage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  return [
    errorMessage ? <Error error={errorMessage}></Error> : <></>,
    (message: string) => setErrorMessage(message),
    () => setErrorMessage('')
  ] as const;
};

export default useErrorMessage;
