import { Badge, Flex, Grid, HStack, Text } from '@chakra-ui/react';
import GameInfoShort from '../models/game/gameInfoShort';
import Flag from './Flag';

type GameCardProps = {
  game: GameInfoShort;
};

const GameCard = ({ game }: GameCardProps) => {
  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: '3fr 3fr 2fr'
      }}
      px={2}
      py={1}
      borderWidth={1}
      borderRadius={6}
      boxShadow='lg'
      gap='1rem'
    >
      <Grid templateColumns='1fr 1fr'>
        <Flex flexDir='column'>
          <Text fontSize='0.6rem' color='gray.400'>
            First player:
          </Text>
          <HStack>
            <Flag code={game.firstPlayerCountry} size='sm' />
            <Text fontSize='0.9rem' lineHeight='5'>
              {game.firstPlayerName}
            </Text>
          </HStack>
        </Flex>
        <Flex flexDir='column'>
          <Text fontSize='0.6rem' color='gray.400'>
            Second player:
          </Text>
          <HStack>
            <Flag code={game.secondPlayerCountry} size='sm' />
            <Text fontSize='0.9rem' lineHeight='5'>
              {game.secondPlayerName}
            </Text>
          </HStack>
        </Flex>
      </Grid>

      <Grid templateColumns={{ base: '1fr 1fr', md: '3fr 1fr' }}>
        <Flex flexDir='column'>
          <Text fontSize='0.6rem' color='gray.400'>
            Tournament:
          </Text>
          <Text fontSize='0.9rem' lineHeight='5'>
            {game.tournamentName}
          </Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontSize='0.6rem' color='gray.400'>
            Identifier:
          </Text>
          <Text fontSize='0.9rem'>{game.identifier}</Text>
        </Flex>
      </Grid>

      <Grid templateColumns='1fr 1fr'>
        <Flex flexDir='column'>
          <Text fontSize='0.6rem' color='gray.400'>
            Sides:
          </Text>
          <Text fontSize='0.9rem'>{game.powerSelectionMethod}</Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontSize='0.6rem' color='gray.400'>
            Due date:
          </Text>
          <Text>
            <Badge variant='outline'>
              {new Date(game.dueDate).toLocaleDateString()}
            </Badge>
          </Text>
        </Flex>
      </Grid>
    </Grid>
  );
};

export default GameCard;
